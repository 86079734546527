<template src="./activityCreateAssignments.html"></template>

<script>
import PortalFooter from "../../Components/PortalFooter";
import SubjectsRepository from "../../Repository/Subject";
import SemesterRepository from "../../Repository/Semester";
import NewExamRepository from "../../Repository/NewExam";
import ExamSubjectDetailsAndQuestionsRepository from "../../Repository/ExamSubjectAndQuestion";
import showStatus from '../../NetworkManager/showStatus'
import { v4 } from "uuid"
// import axios from 'axios'
import {
  convertDateTime
} from "../../Services/Utils/DateTimeFormatter";
import AssignmentUserRepository from '../../Repository/AssignmentUser';
import AssignmentRepository from "../../Repository/Assignment";
// import SemesterUserRepository from '../../Repository/SemesterUser';
// import semUser from '../../Repository/SemesterUser'
import semUserTest from '../../Repository/SemesterUser'
import UserRepository from "../../Repository/User";
import UploadToDigitalOceanRepository from '../../Repository/uploadToDigitalOcean'
import NbaRepository from "../../Repository/NBA";
import { createExcelThroughJsonForMultipleSheets, generateJsonFromExcel } from '../../utils/excel';
// import activityExamBasicDetailsTab from "../activityExamBasicDetailsTab/activityExamBasicDetailsTab.vue"

export default {
  name: "activityCreateAssignments",
  components: {
    PortalFooter
  },
  props: [
    'prop_department',
    'prop_course',
    'prop_selectedSubject',
    'prop_typeDivGrpBatch',
    'prop_selectedDivision',
    'prop_selectedBatch',
    'prop_selectedGroup',
    'prop_exam',
    'prop_selectedSemester',
    'prop_selectedFaculty'
  ],

  data() {
    return {
      blue: "#050D92",
      backgroundColor: "#E7E7FFCF",
      orange: "#FF4F1F",
      downArrow: "mdi-chevron-down",
      upArrow: "mdi-arrow-up",
      examsLoaded: true,
      isMobileView: false,
      department: 'Test Department',
      examTypes: ['Assignment'], //'Theory', 'Online'
      examType: '',
      subjectNames: [],
      secondaryTabItems: ['Question Paper'], //, 'Rubrics', 'Model Answers'
      tabs: 0,
      tabs1: 0,
      tabs2: 0,
      search: '',
      selectedTab: 0,
      examName: '',
      descriptionOfExam: '',
      totalMarks: '',
      passingMarks: '',
      thresholdMarks: '',
      dateOfPublication: null,
      dateOfCompletion: null,
      timeOfPublication: null,
      timeOfCompletion: null,
      bufferEndDate: null,
      dopmodel: false,
      docmodel: false,
      topmodel: false,
      tocmodel: false,
      btaedmodel: false,
      showCanvasDialog: false,
      canvasContainer: null,
      showAttachment: false,
      mainCard: null,
      canvas: null,
      remarkForLateSubmission: '',
      createdQuestions: null,
      startDate: "",
      fileType: [],
      allFileTypes: ['Images', 'PDF', 'Audio', 'Video'],
      menu2: false,
      calenderIcon: 'mdi-calendar-month-outline',
      attainmentCheckbox: false,
      questionsCheckbox: false,
      rubricCheckbox: false,
      selectedItem: '',
      isForQuestionsTab: false,
      switch1: false,
      setsToCreate: [],
      createdSets: [{
        no: 1,
        setName: 'set 1'
      }],
      numberOfSets: 0,
      setsObjectForQuestions: {
        'set 1': []
      },
      count: 0,
      uploadQuestionExcelDialog: false,
      uploadQuestionViseMarksViaExcel: false,
      quizQuestionExcelFile: null,
      questionMarksExcelFile: null,
      questionString: '',
      questionMarks: null,
      exam: null,
      examQuestions: [],
      isSetNameEmpty: false,
      activeSet: {
        no: 1,
        setName: 'set 1'
      },
      progressIndicator1: 0,
      loading: false,
      newTempTab: (this.questionsCheckbox || this.rubricCheckbox) ? 'Questions' : 'Model Answers',
      mainTabNames: ['Basic Details', this.newTempTab, 'Student Marks', 'Insights'],
      rules: {
        required: (value) => !!value || "This field is required",
        totalMarks: value => /^\d*$/.test(value) || 'Only numbers are allowed.',
        passingMarks: value => /^\d*$/.test(value) || 'Only numbers are allowed.'
      },
      date: '',
      subjectIds: [],
      questionsArray: [],
      sectionDialog: false,
      sectionType: '',
      createSetDialog: false,
      students: [],
      openQuestionsPreview: false,
      questionDialogue: false,
      orQuestionDialogue: false,
      subQuestionDialogue: false,
      sectionNo: 0,
      questionNo: 0,
      subQuestionNo: 0,
      questionItem: '',
      allQuestionObjectArray: [],
      arrayOfQuestionsToUpdate: [],
      selectedRadio: null,
      questionToGiveMarks: [],
      allQuestions: [],
      questionsValidationSuccess: true,
      seperatedSetQuestions: [],
      allSections: [],
      studentData: {},
      questionWiseMarksDialog: false,
      nbaData: [],
      sectionsOfSelectedSet: [],
      studentMarksArrayToUpdate: [],
      subQnIndex: null,
      questionWiseMarksExcelExtraMarks: [],
      excelErrors: [],
      schema: {},
      uploadOverallMarksViaExcel: false,
      uploadSectionMarksViaExcel: false,
      overallBlooms: [],
      overallCos: [],
      selectedCoType: null,
      selectedBloomType: null,
      overallCosBloomsData: false,
      attachmentFiles: [],
      subjectObjectiveFileLink: [],
      // setNameForQuestionStoring: 'set 1',
      allBlooms: [
        {
          name: "CREATE (C)",
          shortCode: "C",
          description:
            "Produce new or original work (Verbs : Design, assemble, contract, conjecture, develop, formulate, author, investigate)"
        },
        {
          name: "EVALUATE (E)",
          shortCode: "E",
          description:
            "Justify a stand or decision (Verbs : appraise, argue, defend, judge, select, support, value, critique, weigh)"
        },
        {
          name: "ANALYZE (A)",
          shortCode: "A",
          description:
            "Draw connections among ideas (Verbs : differentiate, organise, relate, compare, contrast, distinguish, examine, experiment, question, test)"
        },
        {
          name: "APPLY (Ap)",
          shortCode: "Ap",
          description:
            "Use information in new situations (Verbs : execute, implement, solve, use, demonstrate, interpret, operate, schedule, sketch)"
        },
        {
          name: "UNDERSTAND (U)",
          shortCode: "U",
          description:
            "Explain ideas of concepts (Verbs : classify, describe, discuss, explain, identify, locate, recognise, report, select, translate)"
        },
        {
          name: "REMEMBER (R)",
          shortCode: "R",
          description:
            "Recall facts and basic concepts (Verbs : define, duplicate, list, memorise, repeat, state)"
        }
      ],
      bloomsNames: [],
      coNames: [],
      headers: [
        {
          text: 'Sections',
          value: 'sectionNames',
          sortable: false,
          width: "10%",
          align: "center"
        },
        {
          text: "Qn No",
          value: "questionNumber",
          sortable: false,
          width: "10%",
          align: "center"
        },
        {
          text: 'Sub Qn No',
          value: 'subQnNo',
          sortable: false,
          width: "11%",
          align: "center"
        },
        {
          text: 'Question Statements and Section Names   ',
          value: 'question',
          sortable: false,
          width: "30%",
          align: "center"
        },
        {
          text: "Marks",
          value: "questionMarks",
          width: "9%",
          align: "center"
        },
        {
          text: 'CO’s mapped',
          value: 'cos',
          sortable: false,
          width: "13%",
          align: "center"
        },
        {
          text: 'Blooms Mapped',
          value: 'blooms',
          sortable: false,
          width: "15%",
          align: "left"
        },
        {
          text: '',
          value: 'delete',
          sortable: false,
          width: "5%",
          align: "left"
        }
      ],
      headers1: [
        {
          text: 'Sr No',
          value: 'srNo',
          width: "9%",
          align: "center"
        },
        {
          text: "PRN No",
          value: "PRN_No",
          width: "9%",
          align: "center"
        },
        {
          text: 'Student Name',
          value: 'name',
          width: "20%",
          align: "center"
        },
        {
          text: 'View Submission',
          value: 'file',
          sortable: false,
          width: "20%",
          align: "center"
        },
        {
          text: 'Submission Status',
          value: 'submittedStatus',
          sortable: false,
          width: "14%",
          align: "center"
        },
        {
          text: "Assign Overall Marks",
          value: "totalMarksObtained",
          sortable: false,
          width: "14%",
          align: "center"
        }
      ],
      headers2: [
        {
          text: 'Sr No',
          value: 'srNo',
          width: "auto",
          align: "center"
        },
        {
          text: "PRN No",
          value: "PRN_No",
          width: "auto",
          align: "center"
        },
        {
          text: 'Student Name',
          value: 'name',
          width: "auto",
          align: "center"
        },
        {
          text: 'Set attempted',
          value: 'sets',
          sortable: false,
          width: "10%",
          align: "center"
        },
        {
          text: 'View Submission',
          value: 'file',
          sortable: false,
          width: "auto",
          align: "center"
        },
        {
          text: 'Submission Status',
          value: 'submittedStatus',
          sortable: false,
          width: "auto",
          align: "center"
        },
        {
          text: "Assign Section Wise Marks",
          value: "allSections",
          sortable: false,
          width: "auto",
          align: "center"
          // subheaders: [{ text: "Section Name", value: "sectionName" }]
        },
        {
          text: 'Total Marks',
          value: 'totalMarksObtained',
          sortable: false,
          width: "14%",
          align: "center"
        }
      ],
      headers3: [
        {
          text: 'Sr No',
          value: 'srNo',
          width: "9%",
          align: "center"
        },
        {
          text: "PRN No",
          value: "PRN_No",
          width: "9%",
          align: "center"
        },
        {
          text: 'Student Name',
          value: 'name',
          width: "20%",
          align: "center"
        },
        {
          text: 'Set attempted',
          value: 'sets',
          sortable: false,
          width: "10%",
          align: "center"
        },
        {
          text: 'View Submission',
          value: 'file',
          sortable: false,
          width: "20%",
          align: "center"
        },
        {
          text: 'Submission Status',
          value: 'submittedStatus',
          sortable: false,
          width: "14%",
          align: "center"
        },
        {
          text: "Obtained Marks",
          value: "totalMarksObtained",
          sortable: false,
          width: "14%",
          align: "center"
          // subheaders: [
          //   { text: 'Section 1', value: 'section1Marks', align: 'center' },
          //   { text: 'Section 2', value: 'section2Marks', align: 'center' }
          // ]
        }
      ],
      headers4: [
        {
          text: 'Sections',
          value: 'sectionNames',
          sortable: false,
          width: "11%",
          align: "center"
        },
        {
          text: "Qn No",
          value: "questionNumber",
          sortable: false,
          width: "7%",
          align: "center"
        },
        {
          text: 'Sub Qn No',
          value: 'subQnNo',
          sortable: false,
          width: "8%",
          align: "center"
        },
        {
          text: 'Question Statements and Section Names   ',
          value: 'question',
          sortable: false,
          width: "40%",
          align: "center"
        },
        {
          text: "Marks",
          value: "questionMarks",
          width: "7%",
          align: "center"
        },
        {
          text: 'Assign Marks',
          value: 'assignMarks',
          sortable: false,
          width: "10%",
          align: "left"
        }
      ]
    };
  },
  updated() {
    if (this.count === 0) {
      if (this.questionsCheckbox || this.rubricCheckbox) {
        this.newTempTab = 'Questions'
      } else {
        this.newTempTab = 'Model Answers'
      }
      this.$set(this.mainTabNames, 1, this.newTempTab)
      this.count++
    }
    // else {
    //       this.newTempTab = 'Que'
    //     }
  },
  async created() {
    this.selectedSemester = this.prop_selectedSemester
    console.log('this.prop_selectedSemester', this.selectedSemester)
    for (let i = 0; i < this.allBlooms.length; i++) {
      this.bloomsNames.push(this.allBlooms[i].name)
    }
    this.subjectNames.push(' ' + this.prop_selectedSubject.subjectName);
    this.subjectIds.push(this.prop_selectedSubject.subjectId);
    this.$store.commit("liveData/set_selectedActivityName", `My Exam`);
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.newExamRepositoryInstance = new NewExamRepository(this);
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.ExamSubjectDetailsAndQuestionsRepositoryInstance = new ExamSubjectDetailsAndQuestionsRepository(this);
    this.user = this.$store.getters["user/get_user"];
    this.userData = this.$store.getters["user/get_userData"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    // this.selectedSemester =
    //   this.$store.getters["instituteData/get_selectedSemester"];
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    // this.subjectId = this.userData.subjects.map((item) => item.subjectId);
    // this.departments = [
    //   ...new Set(this.userData.subjects.map((item) => item.department))
    // ];
    this.assignmentRepositoryInstance = new AssignmentRepository(this);
    this.assignmentUserRepositoryInstance = new AssignmentUserRepository(this);

    this.semUserTestInstance = new semUserTest(this)
    this.userRepositoryInstance = new UserRepository(this);
    this.uploadToDigitalOceanInstance = new UploadToDigitalOceanRepository(this)
    this.nbaData = await this.nbaRepositoryInstance.getCos({
      lmsInstituteId: this.selectedInstitute,
      semesterId: this.selectedSemester.semId,
      subjectId: this.prop_selectedSubject.subjectId
    });
    for (let i = 0; i < this.nbaData.data.length; i++) {
      this.coNames.push(this.nbaData.data[i].CONumber)
    }
    if (this.prop_exam) {
      this.attachmentFiles = this.prop_exam.linksOfAttachments
      this.fillDataFromTheProp()
      this.calculateProgress()
      await this.fetchAssignmentQuestions()
    }
    // this.questionsArray = [{
    //   sectionNames: 'section1',
    //   questionNumber: '',
    //   subQnNo: '',
    //   question: 'Section Name here',
    //   questionMarks: 20,
    //   cos: ['co1', 'co2'],
    //   blooms: ['bloom1', 'bloom2']
    // },
    // {
    //   sectionNames: '',
    //   questionNumber: 'Q1',
    //   subQnNo: '',
    //   question: 'Question Name here',
    //   questionMarks: 5,
    //   cos: ['co1', 'co2'],
    //   blooms: ['bloom1', 'bloom2']
    // },
    // {
    //   sectionNames: '',
    //   questionNumber: '',
    //   subQnNo: 'Q1.1',
    //   question: 'SubQuestion Name here',
    //   questionMarks: 5,
    //   cos: ['co1', 'co2'],
    //   blooms: ['bloom1', 'bloom2']
    // }]
  },
  mounted() {
    const self = this;
    self.canvasContainer = document.getElementById("canvasContainer");
    if (self.canvasContainer.addEventListener) {
      self.canvasContainer.addEventListener(
        "contextmenu",
        function (e) {
          e.preventDefault();
        },
        false
      );
    } else {
      self.canvasContainer.attachEvent("oncontextmenu", function () {
        window.event.returnValue = false;
      });
    }

    self.mainCard = document.getElementById("mainCard");
  },
  methods: {
    questionWiseCosBlooms() {
      this.questionsCheckbox = true
      this.overallCosBloomsData = false
      this.overallCos = []
      this.overallBlooms = []
    },
    overallCosBlooms() {
      this.questionsCheckbox = false
      this.overallCosBloomsData = true
    },
    rowClass(item) {
      if (item.sectionNames) {
        return "sectionBackgrond"
      }
    },
    displayedValue(item, data) {
      if (item[data.sectionName]) return item[data.sectionName]
      else if (item && item.answers && data) {
        for (let i = 0; i < item.answers.length; i++) {
          if (item.answers[i].questionId === data.questionId) {
            return item.answers[i]?.obtainedMarks;
          }
        }
      }
    },
    fieldBackgroundColors(item) {
      if (item.questionNumber === 'OR' || item.subQnNo === 'OR' || item.questionNumberAny) {
      } else {
        return "#9205050D"
      }
    },
    downloadQuestionMarksReport() {
      const data = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] });

        for (let k = 0; k < this.students.length; k++) {
          const objToPush = {
            PRN: this.students[k].PRN_No,
            Name: this.students[k].name
          };

          let sectionName = '';
          for (let j = 0; j < this.setsObjectForQuestions[sets[i]].length; j++) {
            let qNo = '';

            if (this.setsObjectForQuestions[sets[i]][j].sectionNames) {
              sectionName = this.setsObjectForQuestions[sets[i]][j].sectionNames;
              const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
              let colValue = '';

              for (let y = 0; y < this.students[k].answers?.length; y++) {
                if (this.students[k].answers[y].questionId === this.setsObjectForQuestions[sets[i]][j].questionId) {
                  colValue = Number(this.students[k].answers[y].obtainedMarks);
                }
              }

              objToPush[`${sectionName}(${questionMark})`] = colValue;
            }

            if (this.setsObjectForQuestions[sets[i]][j].questionNumber && this.setsObjectForQuestions[sets[i]][j].questionNumber !== 'OR') {
              qNo = this.setsObjectForQuestions[sets[i]][j].questionNumber;
              const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
              let colValue = '';

              for (let y = 0; y < this.students[k].answers?.length; y++) {
                if (this.students[k].answers[y].questionId === this.setsObjectForQuestions[sets[i]][j].questionId) {
                  colValue = Number(this.students[k].answers[y].obtainedMarks);
                }
              }

              objToPush[`${sectionName}_${qNo}(${questionMark})`] = colValue;
            }

            if (this.setsObjectForQuestions[sets[i]][j].subQnNo && this.setsObjectForQuestions[sets[i]][j].subQnNo !== 'OR' && (!this.setsObjectForQuestions[sets[i]][j].anyStatement || (this.setsObjectForQuestions[sets[i]][j].anyStatement && this.setsObjectForQuestions[sets[i]][j].question))) {
              const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
              let colValue = '';

              for (let y = 0; y < this.students[k].answers?.length; y++) {
                if (this.students[k].answers[y].questionId === this.setsObjectForQuestions[sets[i]][j].questionId) {
                  colValue = Number(this.students[k].answers[y].obtainedMarks);
                }
              }

              objToPush[`${sectionName}_${this.setsObjectForQuestions[sets[i]][j].subQnNo}(${questionMark})`] = colValue;
            }
          }

          data[i][sets[i]].push(objToPush);
        }
      }
      // console.log('data12', data)
      createExcelThroughJsonForMultipleSheets(data, this.exam.exam.title + '_Marks')
    },
    downloadOverallMarksReport() {
      const data = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] });
        for (let j = 0; j < this.students?.length; j++) {
          const objToPush = {}
          objToPush.PRN = this.students[j]?.PRN_No
          objToPush.Name = this.students[j]?.name
          objToPush[`Overall_Marks(${this.exam.exam.totalMarks})`] = this.students[j]?.totalMarksObtained === 0 ? '' : this.students[j]?.totalMarksObtained
          data[i][sets[i]].push(objToPush);
        }
      }
      createExcelThroughJsonForMultipleSheets(data, this.exam.exam.title + '_Marks')
    },
    downloadSectionlMarksReport() {
      const data = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] });

        for (let k = 0; k < this.students.length; k++) {
          const objToPush = {
            PRN: this.students[k].PRN_No,
            Name: this.students[k].name
          };

          let sectionName = '';
          for (let j = 0; j < this.setsObjectForQuestions[sets[i]].length; j++) {
            if (this.setsObjectForQuestions[sets[i]][j].sectionNames) {
              sectionName = this.setsObjectForQuestions[sets[i]][j].sectionNames;
              const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
              let colValue = '';

              for (let y = 0; y < this.students[k].answers?.length; y++) {
                if (this.students[k].answers[y].questionId === this.setsObjectForQuestions[sets[i]][j].questionId) {
                  colValue = Number(this.students[k].answers[y].obtainedMarks);
                }
              }

              objToPush[`${sectionName}(${questionMark})`] = colValue;
            }
          }

          data[i][sets[i]].push(objToPush);
        }
      }
      // console.log('data12', data)
      createExcelThroughJsonForMultipleSheets(data, this.exam.exam.title + '_Marks')
    },
    calculateProgress() {
      this.progressIndicator1 = 0;

      if (this.examName !== '') this.progressIndicator1 += 6.66
      if (this.descriptionOfExam !== '') this.progressIndicator1 += 6.66
      if (this.totalMarks !== '') this.progressIndicator1 += 6.66
      if (this.passingMarks !== '') this.progressIndicator1 += 6.66
      if (this.thresholdMarks !== '') this.progressIndicator1 += 6.66
      if (this.dateOfPublication !== null) this.progressIndicator1 += 6.66
      if (this.timeOfPublication !== null) this.progressIndicator1 += 6.66
      if (this.dateOfCompletion !== null) this.progressIndicator1 += 6.66
      if (this.timeOfCompletion !== null) this.progressIndicator1 += 6.66
      if (this.bufferEndDate !== null) this.progressIndicator1 += 6.66
      if (this.remarkForLateSubmission !== '') this.progressIndicator1 += 6.66
      if (this.fileType.length > 0) this.progressIndicator1 += 6.66
      if (this.attainmentCheckbox) this.progressIndicator1 += 6.66
      if (this.questionsCheckbox) this.progressIndicator1 += 6.66
      if (this.rubricCheckbox) this.progressIndicator1 += 6.66
    },
    isOrType(type) {
      return ['OrSection', 'OrSubSection', 'OrQuestion', 'OrSubQuestion'].includes(type);
    },
    isSectionType(type) {
      return ['Section', 'OrSection'].includes(type);
    },
    isQuestionType(type) {
      return ['Question', 'OrQuestion'].includes(type);
    },
    isSubSectionType(type) {
      return ['SubSection', 'OrSubSection'].includes(type);
    },
    async fetchAssignmentQuestions() {
      if (this.exam !== null) {
        const assignmentQuestions = await this.ExamSubjectDetailsAndQuestionsRepositoryInstance.getExamSubjectQuestions({
          instituteId: this.selectedInstitute,
          examId: this.exam.exam.examId,
          subjectId: this.prop_selectedSubject.subjectId
        })
        this.createdQuestions = assignmentQuestions.result
        if (this.createdQuestions.overallCOs.length > 0 || this.createdQuestions.overallBlooms.length > 0) {
          this.row = 'radio-1'
          this.overallCosBloomsData = true
        }
        this.overallCos = this.createdQuestions.overallCOs
        this.overallBlooms = this.createdQuestions.overallBlooms
      }
      if (this.createdQuestions?.allQuestions.length > 0) {
        this.getAllSections()
        this.activeSet = {
          no: 1,
          setName: 'set 1'
        }
        this.setActiveSet(this.activeSet)
        this.createdSets = []
        this.setsObjectForQuestions = {}
        for (let i = 0; i < this.createdQuestions.allQuestions.length; i++) {
          if (this.createdQuestions.allQuestions[i].questionType === 'Set') {
            const questionStatement = this.createdQuestions.allQuestions[i].questionStatement;
            this.createdSets.push({
              no: i + 1,
              setName: questionStatement
            })
            if (!Object.prototype.hasOwnProperty.call(this.setsObjectForQuestions, questionStatement)) {
              this.setsObjectForQuestions[questionStatement] = [];
            }
            this.setsObjectForQuestions[questionStatement].push(...this.getQuestionsOfSelectedSet({ setName: questionStatement, forShowingQuestions: true }, null));
          }
        }
        this.questionsArray = this.setsObjectForQuestions['set 1']
      }
    },
    fillDataFromTheProp() {
      this.exam = { exam: this.prop_exam }
      if (this.prop_exam.examType === 'Non rubric') this.examType = 'Assignment'
      else if (this.prop_exam.examType === 'Online Exam') this.examType = 'Online'
      else if (this.prop_exam.examType === 'Offline Exam') this.examType = 'Theory'

      this.examName = this.prop_exam.title ? this.prop_exam.title : ''
      this.dateOfPublication = this.prop_exam.publishDate ? this.prop_exam.publishDate.slice(0, 10) : ''
      this.timeOfPublication = this.prop_exam.publishTime ? this.prop_exam.publishTime : ''
      this.dateOfCompletion = this.prop_exam.dueDate ? this.prop_exam.dueDate.slice(0, 10) : ''
      this.timeOfCompletion = this.prop_exam.dueTime ? this.prop_exam.dueTime : ''
      this.totalMarks = this.prop_exam.totalMarks ? this.prop_exam.totalMarks : ''
      this.passingMarks = this.prop_exam.passingMarks ? this.prop_exam.passingMarks : ''
      this.fileType = this.prop_exam.allowedFileTypes ? this.prop_exam.allowedFileTypes : []
      this.thresholdMarks = this.prop_exam.threshold ? this.prop_exam.threshold : ''
      this.descriptionOfExam = this.prop_exam.description ? this.prop_exam.description : ""
      this.questionsCheckbox = this.prop_exam.settings.areQuestionsAvailable ? this.prop_exam.settings.areQuestionsAvailable : false
      this.attainmentCheckbox = this.prop_exam.settings.isForAttainment ? this.prop_exam.settings.isForAttainment : false
      this.rubricCheckbox = this.prop_exam.settings.isRubricAvailable ? this.prop_exam.settings.isRubricAvailable : false
      this.bufferEndDate = this.prop_exam.bufferEndDate ? this.prop_exam.bufferEndDate.slice(0, 10) : ''
    },
    setActiveSet(set) {
      this.setsObjectForQuestions[this.activeSet.setName] = this.questionsArray
      this.activeSet = set;
      this.questionsArray = this.setsObjectForQuestions[this.activeSet.setName]
    },
    updateCount() {
      this.tabs = 0
      this.count = 0
      this.calculateProgress()
    },
    setSectionType(type) {
      this.sectionType = type;
      this.sectionDialog = false;
    },
    async validateBasicDetails() {
      console.log('validateBasicDetails')
      if (this.examType === '' || this.examName === '' || this.totalMarks === '' || this.passingMarks === '' || this.dateOfPublication === null || this.dateOfCompletion === null || this.timeOfPublication === null || this.timeOfCompletion === null) {
        showStatus("Please Fill Required Fields First", 9000, "warning", this);
      } else if (new Date(this.dateOfCompletion) < new Date(this.dateOfPublication)) {
        showStatus("Date of Completion must be later than Date of Publication", 9000, "warning", this);
      } else if ((this.totalMarks - '') < (this.passingMarks - '')) {
        showStatus("Passing Marks and Threshold Marks can not be greater than Total Marks", 9000, "warning", this);
      } else {
        await this.saveBasicDetails()
        await this.finalQuestionsSave()
      }
    },
    async uploadFile(fileArr) {
      try {
        let urlLinkArr = [];
        if (fileArr.length > 0) {

          let data = await this.uploadToDigitalOceanInstance.upload(fileArr, this.userData.uId)
          return data

        }
      } catch (error) {
        console.log('error')
        let arr = []
        return arr
      }


    },
    async saveBasicDetails() {
      // console.log('this.attachmentFiles', this.attachmentFiles)
      let examTypeName
      if (this.examType === 'Assignment') examTypeName = 'Non rubric'
      else if (this.examType === 'Online') examTypeName = 'Online Exam'
      else if (this.examType === 'Theory') examTypeName = 'Offline Exam'
      const passingPercentage = (this.passingMarks / this.totalMarks) * 100;
      const examBasicDetailsObject = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.prop_department,
        courseYear: this.prop_course,
        subjectIds: this.subjectIds,
        title: this.examName,
        examType: examTypeName,
        publishDate: this.dateOfPublication,
        publishTime: this.timeOfPublication,
        dueDate: this.dateOfCompletion,
        dueTime: this.timeOfCompletion,
        totalMarks: (this.totalMarks - ''),
        passingMarks: (this.passingMarks - ''),
        passingPercentage: passingPercentage,
        settings: {},
        uId: this.prop_selectedFaculty.uId,
        isDeleted: false,
        allowedFileTypes: this.fileType
      }
      if (this.exam?.exam?.examId) {
        examBasicDetailsObject.examId = this.exam?.exam?.examId
      }
      if (this.prop_selectedDivision !== '') {
        examBasicDetailsObject.division = this.prop_selectedDivision
      }
      if (this.prop_selectedBatch !== '') {
        examBasicDetailsObject.batch = this.prop_selectedBatch.batchName
      }
      if (this.prop_selectedGroup !== '') {
        examBasicDetailsObject.assignedGroupForSubject = this.prop_selectedGroup
      }
      if (this.descriptionOfExam !== '') {
        examBasicDetailsObject.description = this.descriptionOfExam
      }
      if (this.thresholdMarks !== '') {
        examBasicDetailsObject.threshold = this.thresholdMarks
      }
      if (this.bufferEndDate !== null) {
        examBasicDetailsObject.bufferEndDate = this.bufferEndDate
      }
      if (this.remarkForLateSubmission !== '') {
        examBasicDetailsObject.lateSubmissionRemark = this.remarkForLateSubmission
      }
      if (this.attainmentCheckbox) {
        examBasicDetailsObject.settings.isForAttainment = this.attainmentCheckbox;
      }

      if (this.questionsCheckbox) {
        examBasicDetailsObject.settings.areQuestionsAvailable = this.questionsCheckbox;
      }

      if (this.rubricCheckbox) {
        examBasicDetailsObject.settings.isRubricAvailable = this.rubricCheckbox;
      }

      if (this.overallCosBloomsData) {
        examBasicDetailsObject.settings.questionWiseCo = false
        examBasicDetailsObject.settings.questionWiseBlooms = false
      }

      if (!this.overallCosBloomsData) {
        examBasicDetailsObject.settings.questionWiseCo = true
        examBasicDetailsObject.settings.questionWiseBlooms = true
      }

      if (this.attachmentFiles?.length > 0) {
        this.subjectObjectiveFileLink = await this.uploadFile(this.attachmentFiles)
        examBasicDetailsObject.linksOfAttachments = this.subjectObjectiveFileLink
      }

      console.log('examBasicDetailsObject', examBasicDetailsObject)
      try {
        this.exam = await this.newExamRepositoryInstance.createExam(examBasicDetailsObject)
        if (this.exam.status.status === 200) {
          showStatus("Successfully saved basic details of the exam.", 2000, 'success', this);
        } else {
          showStatus("Something went wrong", 2000, 'error', this);
        }
      } catch (error) {
        console.error(error);
      }
    },
    createSets() {
      if (this.numberOfSets > 0) {
        if (this.numberOfSets > this.setsToCreate.length) {
          const value = this.numberOfSets - this.setsToCreate.length
          for (let i = 0; i < value; i++) {
            this.setsToCreate.push({
              no: this.createdSets.length + this.setsToCreate.length + 1,
              setName: ''
            })
          }
        } else {
          const arr = []
          for (let i = 0; i < this.numberOfSets; i++) {
            arr.push(this.setsToCreate[i])
          }
          this.setsToCreate = arr
        }
      }
    },
    saveSets() {
      this.isSetNameEmpty = false
      this.setsToCreate.forEach(set => {
        if (set.setName.trim() === '') this.isSetNameEmpty = true
      })
      if (!this.isSetNameEmpty) {
        this.createSetDialog = false
        this.createdSets.push(...this.setsToCreate)
      }
      for (let i = 0; i < this.setsToCreate.length; i++) {
        this.setsObjectForQuestions[this.setsToCreate[i].setName] = []
      }
    },
    async downloadReport() {
      const sampleQuestionsHeader = ['Section No.', 'Section', 'Marks', 'Cos', 'Blooms', 'Question No.', 'Question', 'Marks', 'Cos', 'Blooms', 'Sub-Question No.', 'Sub-Question', 'Marks', 'Cos', 'Blooms']
      const objToPush = {
        headers: sampleQuestionsHeader,
        examTitle: this.exam.exam.title,
        selectedSubject: this.prop_selectedSubject.subjectName
      };
      const result =
        await this.ExamSubjectDetailsAndQuestionsRepositoryInstance.generateExamQuestionsExcel(
          objToPush
        );
      this.downloadFileViaBuffer(result.data, 'SampleQuestionsExcel.xlsx')
    },
    downloadFileViaBuffer(fileBuffer, fileName) {
      const pdfBlob2 = new Blob([fileBuffer], { type: "application/pdf" });
      const url = window.URL.createObjectURL(pdfBlob2);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();
    },
    async startUploadingQuestionExcel() {
      this.uploadQuestionExcelDialog = false;
      this.loading = true
      if (this.quizQuestionExcelFile) {
        try {
          var formData = new FormData()
          formData.append('excel', this.quizQuestionExcelFile)
          formData.append('subjectId', this.prop_selectedSubject.subjectId)
          formData.append('examId', this.exam.exam.examId)
          formData.append('instituteId', this.selectedInstitute)
          formData.append('marks', this.exam.exam.totalMarks)

          const data = await this.ExamSubjectDetailsAndQuestionsRepositoryInstance.parseExcel(formData);

          this.createdQuestions = data.examQuestions.examSubjectAndQuestions
        } catch (error) {
          console.error('Error parsing Excel:', error);
        } finally {
          this.quizQuestionExcelFile = null;
          this.loading = false
        }
      }
    },
    async startUploadingOverallMarksExcel() {
      this.schema = {}
      this.loading = true
      const data = []
      this.excelErrors = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] })
        this.schema[sets[i]] = {
          PRN: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'prn'
          },
          Name: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'name'
          },
          [`Overall_Marks(${this.exam.exam.totalMarks})`]: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'overall_marks'
          }
        }
      }
      const res = await generateJsonFromExcel(this.questionMarksExcelFile, this.schema, false);
      if (res.errors.length === 0) {
        try {
          const objectsArrayToUpdateMarks = [];
          const excelSheets = Object.keys(res.data);

          for (const sheet of excelSheets) {
            for (const data of res.data[sheet]) {
              const student = this.students.find(s => s.PRN_No === data.prn);
              // console.log('datadata', data)
              if (student) {
                if (this.exam.exam.totalMarks < data.overall_marks) {
                  this.excelErrors.push('Marks given to ' + student.name + ' should not be greater than ' + this.exam.exam.totalMarks)
                } else {
                  objectsArrayToUpdateMarks.push({
                    uId: student.uId,
                    instituteId: this.selectedInstitute,
                    semId: this.selectedSemester.semId,
                    examId: this.exam.exam.examId,
                    status: 'Submitted',
                    submittedOn: student?.submittedOn ? new Date(student?.submittedOn).toISOString() : new Date().toISOString(),
                    subjectId: this.prop_selectedSubject.subjectId,
                    attemptNumber: student.attemptNumber,
                    totalMarksObtained: data.overall_marks,
                    answers: [],
                    marksGivenTo: data,
                    setName: sheet
                  });
                }
              }
            }
          }

          if (this.excelErrors.length === 0) {
            await this.assignmentUserRepositoryInstance.bulkUpdateMarksObtainedForAnAssignment({
              bulkDataArray: objectsArrayToUpdateMarks
            });
            this.uploadOverallMarksViaExcel = false
            this.questionMarksExcelFile = null
            this.StudentMarks()
            showStatus("Marks updated successfully", 2000, 'success', this);
          }
          // this.students[this.students.indexOf(item)].totalMarksObtained = item.totalMarksObtained
          // this.students[this.students.indexOf(item)].submittedOn = convertDateTime(objToPush.submittedOn);
          // showStatus("Marks updated successfully", 2000, 'success', this);
        } catch (err) {
          console.error(err);
          showStatus("Error updating marks", 1000, "error", this);
        }
      } else {
        this.excelErrors = res.errors
      }
    },
    async startUploadingSectionMarksExcel() {
      this.schema = {}
      this.loading = true
      const data = []
      this.excelErrors = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] })
        this.schema[sets[i]] = {
          PRN: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'prn'
          },
          Name: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'name'
          }
        }
        for (let k = 0; k < this.students.length; k++) {
          data[i][sets[i]].push({ PRN: this.students[k].PRN_No, Name: this.students[k].name })
        }
        let sectionName = '';
        for (let j = 0; j < this.setsObjectForQuestions[sets[i]].length; j++) {
          if (this.setsObjectForQuestions[sets[i]][j].sectionNames) {
            sectionName = this.setsObjectForQuestions[sets[i]][j].sectionNames;
            const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
            this.schema[sets[i]][`${sectionName}(${questionMark})`] = {
              validation: {
                type: 'textLength'
              },
              variableName: sectionName
            }
            data[i][sets[i]].push({
              [sectionName]: ''
            });
          }
        }
      }
      const res = await generateJsonFromExcel(this.questionMarksExcelFile, this.schema, false);
      if (res.errors.length === 0) {
        try {
          const objectsArrayToUpdateMarks = [];
          const excelSheets = Object.keys(res.data);

          for (const sheet of excelSheets) {
            for (const data of res.data[sheet]) {
              const student = this.students.find(s => s.PRN_No === data.prn);

              if (student) {
                objectsArrayToUpdateMarks.push({
                  uId: student.uId,
                  instituteId: this.selectedInstitute,
                  semId: this.selectedSemester.semId,
                  examId: this.exam.exam.examId,
                  status: 'Submitted',
                  submittedOn: student?.submittedOn ? new Date(student?.submittedOn).toISOString() : new Date().toISOString(),
                  subjectId: this.prop_selectedSubject.subjectId,
                  attemptNumber: student.attemptNumber,
                  totalMarksObtained: 0,
                  answers: [],
                  marksGivenTo: data,
                  setName: sheet
                });
              }
            }
          }

          for (let i = 0; i < objectsArrayToUpdateMarks.length; i++) {
            const marksGivenToKeys = Object.keys(objectsArrayToUpdateMarks[i].marksGivenTo)
            for (let j = 0; j < marksGivenToKeys.length; j++) {
              const sectionAndQuestion = marksGivenToKeys[j]?.split('_')
              if (sectionAndQuestion.length === 1) {
                for (let k = 0; k < this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName].length; k++) {
                  if (
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames === sectionAndQuestion[0] &&
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks >= objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                  ) {
                    objectsArrayToUpdateMarks[i].totalMarksObtained += objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                    objectsArrayToUpdateMarks[i].answers.push({
                      questionId: this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionId,
                      obtainedMarks: objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                    });
                  } else if (this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames === sectionAndQuestion[0] &&
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks < objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]) {
                    this.excelErrors.push('Given Marks of ' + this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames + ':' + ' ' + objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]] + ' are greater than its assigned marks')
                    // showStatus("Given Marks of " + this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames + " are greater than its assigned marks", 5000, 'error', this);
                  }
                }
              }
            }
          }
          if (this.excelErrors.length === 0) {
            await this.assignmentUserRepositoryInstance.bulkUpdateMarksObtainedForAnAssignment({
              bulkDataArray: objectsArrayToUpdateMarks
            });
            this.uploadSectionMarksViaExcel = false
            this.questionMarksExcelFile = null
            this.StudentMarks()
            showStatus("Marks updated successfully", 2000, 'success', this);
          }
        } catch (err) {
          console.error(err);
          showStatus("Error updating marks", 1000, "error", this);
        }
      } else {
        this.excelErrors = res.errors
      }
    },
    async startUploadingQuestionMarksExcel() {
      this.schema = {}
      this.loading = true
      const data = []
      this.excelErrors = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] })
        this.schema[sets[i]] = {
          PRN: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'prn'
          },
          Name: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'name'
          }
        }
        for (let k = 0; k < this.students.length; k++) {
          data[i][sets[i]].push({ PRN: this.students[k].PRN_No, Name: this.students[k].name })
        }
        let sectionName = '';
        for (let j = 0; j < this.setsObjectForQuestions[sets[i]].length; j++) {
          let qNo = '';

          if (this.setsObjectForQuestions[sets[i]][j].sectionNames) {
            sectionName = this.setsObjectForQuestions[sets[i]][j].sectionNames;
            const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
            this.schema[sets[i]][`${sectionName}(${questionMark})`] = {
              validation: {
                type: 'textLength'
              },
              variableName: sectionName
            }
            data[i][sets[i]].push({
              [sectionName]: ''
            });
          }

          if (this.setsObjectForQuestions[sets[i]][j].questionNumber && this.setsObjectForQuestions[sets[i]][j].questionNumber !== 'OR') {
            qNo = this.setsObjectForQuestions[sets[i]][j].questionNumber;
            const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
            this.schema[sets[i]][`${sectionName}_${qNo}(${questionMark})`] = {
              validation: {
                type: 'textLength'
              },
              variableName: `${sectionName}_${qNo}`
            }
            data[i][sets[i]].push({
              [`${sectionName}_${qNo}`]: ''
            });
          }

          if (this.setsObjectForQuestions[sets[i]][j].subQnNo && this.setsObjectForQuestions[sets[i]][j].subQnNo !== 'OR' && (!this.setsObjectForQuestions[sets[i]][j].anyStatement || (this.setsObjectForQuestions[sets[i]][j].anyStatement && this.setsObjectForQuestions[sets[i]][j].question))) {
            const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
            this.schema[sets[i]][`${sectionName}_${this.setsObjectForQuestions[sets[i]][j].subQnNo}(${questionMark})`] = {
              validation: {
                type: 'textLength'
              },
              variableName: `${sectionName}_${this.setsObjectForQuestions[sets[i]][j].subQnNo}`
            }
            data[i][sets[i]].push({
              [`${sectionName}_${this.setsObjectForQuestions[sets[i]][j].subQnNo}`]: ''
            });
          }
        }
      }
      const res = await generateJsonFromExcel(this.questionMarksExcelFile, this.schema, false);
      if (res.errors.length === 0) {
        try {
          const objectsArrayToUpdateMarks = [];
          const excelSheets = Object.keys(res.data);

          for (const sheet of excelSheets) {
            for (const data of res.data[sheet]) {
              const student = this.students.find(s => s.PRN_No === data.prn);

              if (student) {
                objectsArrayToUpdateMarks.push({
                  uId: student.uId,
                  instituteId: this.selectedInstitute,
                  semId: this.selectedSemester.semId,
                  examId: this.exam.exam.examId,
                  status: 'Submitted',
                  submittedOn: student?.submittedOn ? new Date(student?.submittedOn).toISOString() : new Date().toISOString(),
                  subjectId: this.prop_selectedSubject.subjectId,
                  attemptNumber: student.attemptNumber,
                  totalMarksObtained: 0,
                  answers: [],
                  marksGivenTo: data,
                  setName: sheet
                });
              }
            }
          }

          for (let i = 0; i < objectsArrayToUpdateMarks.length; i++) {
            const marksGivenToKeys = Object.keys(objectsArrayToUpdateMarks[i].marksGivenTo)
            for (let j = 0; j < marksGivenToKeys.length; j++) {
              const sectionAndQuestion = marksGivenToKeys[j]?.split('_')
              if (sectionAndQuestion.length === 1) {
                for (let k = 0; k < this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName].length; k++) {
                  if (
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames === sectionAndQuestion[0] &&
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks >= objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                  ) {
                    objectsArrayToUpdateMarks[i].totalMarksObtained += objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                    objectsArrayToUpdateMarks[i].answers.push({
                      questionId: this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionId,
                      obtainedMarks: objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                    });
                  } else if (this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames === sectionAndQuestion[0] &&
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks < objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]) {
                    this.excelErrors.push('Given Marks of ' + this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames + ':' + ' ' + objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]] + ' are greater than its assigned marks')
                    // showStatus("Given Marks of " + this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames + " are greater than its assigned marks", 5000, 'error', this);
                  }
                }
              } else {
                let secName = ''
                for (let k = 0; k < this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName].length; k++) {
                  if (this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k]?.sectionNames) secName = this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k]?.sectionNames
                  if (secName === sectionAndQuestion[0] && (sectionAndQuestion[1] === this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionNumber || sectionAndQuestion[1] === this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].subQnNo) && this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks >= objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion.join('_')]) {
                    objectsArrayToUpdateMarks[i].answers.push({
                      questionId: this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionId,
                      obtainedMarks: objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion.join('_')]
                    });
                  } else if (secName === sectionAndQuestion[0] && (sectionAndQuestion[1] === this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionNumber || sectionAndQuestion[1] === this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].subQnNo) && this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks < objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion.join('_')]) {
                    this.excelErrors.push("Given Marks of " + secName + "-" + (this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionNumber ? this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionNumber : this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].subQnNo) + ':' + ' ' + objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion.join('_')] + " are greater than its assigned marks");
                  }
                }
              }
            }
          }
          if (this.excelErrors.length === 0) {
            await this.assignmentUserRepositoryInstance.bulkUpdateMarksObtainedForAnAssignment({
              bulkDataArray: objectsArrayToUpdateMarks
            });
            this.uploadQuestionViseMarksViaExcel = false
            this.questionMarksExcelFile = null
            this.StudentMarks()
            showStatus("Marks updated successfully", 2000, 'success', this);
          }
          // this.students[this.students.indexOf(item)].totalMarksObtained = item.totalMarksObtained
          // this.students[this.students.indexOf(item)].submittedOn = convertDateTime(objToPush.submittedOn);
          // showStatus("Marks updated successfully", 2000, 'success', this);
        } catch (err) {
          console.error(err);
          showStatus("Error updating marks", 1000, "error", this);
        }
      } else {
        this.excelErrors = res.errors
      }
    },
    async showQuestions() {
      await this.fetchAssignmentQuestions()
      if (this.createdQuestions !== null) {
        this.openQuestionsPreview = true
      } else {
        showStatus("Questions are not added yet for this assignment", 9000, "warning", this);
      }
    },
    deleteQuestion(index) {
      if (index >= 0 && index < this.questionsArray.length) {
        if (this.questionsArray[index].sectionNames) {
          this.questionsArray.splice(index, 1);
          for (let i = index; i < this.questionsArray.length; i++) {
            if (this.questionsArray[i].sectionNames) {
              break;
            } else {
              this.questionsArray.splice(i, 1);
              i--;
            }
          }
        } else if (this.questionsArray[index].questionNumber) {
          this.questionsArray.splice(index, 1);
          for (let i = index; i < this.questionsArray.length; i++) {
            if (this.questionsArray[i].questionNumber || this.questionsArray[i].sectionNames) {
              break;
            } else {
              // const qnNo = this.questionsArray[i].questionNumber
              this.questionsArray.splice(i, 1);
              // this.questionsArray[i].questionNumber = qnNo
              i--;
            }
          }
        } else if (this.questionsArray[index].subQnNo) {
          this.questionsArray.splice(index, 1);
          for (let i = index; i < this.questionsArray.length; i++) {
            if (this.questionsArray[i].subQnNo) {
              break;
            } else {
              this.questionsArray.splice(i, 1);
              i--;
            }
          }
        }
      }
      this.resetQueNo()
    },
    resetQueNo() {
      let secNo = 1
      let qnNo = 1
      let sQnNo = 1
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i].sectionNames) {
          qnNo = 1
          sQnNo = 1
          this.questionsArray[i].questionNo = 'Section' + secNo
          this.questionsArray[i].sectionNames = 'Section' + secNo
          secNo++
        } else if (this.questionsArray[i].questionNumber && this.questionsArray[i].questionNumber !== 'OR') {
          sQnNo = 1
          this.questionsArray[i].questionNo = 'Q' + qnNo
          this.questionsArray[i].questionNumber = 'Q' + qnNo
          qnNo++
        } else if (this.questionsArray[i].subQnNo && this.questionsArray[i].subQnNo !== 'OR' && !this.questionsArray[i].questionNumberAny && this.questionsArray[i].subQnNo.slice(0, 3) !== 'Any') {
          this.questionsArray[i].subQnNo = 'Q' + (qnNo - 1) + '.' + sQnNo
          sQnNo++
        }
      }
    },
    addSection() {
      let sectionNames = ++this.sectionNo
      for (let i = this.questionsArray?.length - 1; i >= 0; i--) {
        if (this.questionsArray[i]?.sectionNames) {
          sectionNames = (this.questionsArray[i].sectionNames[this.questionsArray[i].sectionNames.length - 1]) - '' + 1
        }
      }
      this.questionNo = 0
      const quesObject = {
        sectionNames: 'Section' + sectionNames,
        question: '',
        sectionId: v4(),
        questionMarks: null,
        cos: this.coNames,
        blooms: this.bloomsNames
      }
      this.questionsArray.push(quesObject)
      this.resetQueNo()
    },
    addAnyTypeQuestion() {
      const quesObject = {
        questionNumber: 'Q' + ++this.questionNo,
        questionForSection: this.questionItem,
        anyCount: null,
        outOfCount: null,
        questionId: v4(),
        question: 'Solve any_out of',
        questionMarks: null,
        cos: this.coNames,
        blooms: this.bloomsNames
      }
      let foundSec = false
      let breaked = false
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i]?.sectionNames === this.questionItem) {
          foundSec = true
        } else if (this.questionsArray[i].sectionNames && this.questionsArray[i].sectionNames !== this.questionItem && foundSec) {
          this.questionsArray.splice(i, 0, quesObject)
          breaked = true
          break
        }
      }
      if (!breaked) {
        this.questionsArray.push(quesObject)
      }
      // this.questionsArray.push(quesObject)
      this.resetQueNo()
    },
    dummy(item, index) {
      if (item.outOfCount && item.anyCount && item.questionMarks) {
        this.addSubquestionsOfAny(item.outOfCount, item.anyCount, item.questionMarks, index)
      }
    },
    addSubquestionsOfAny(outOfCount, anyCount, questionMarks, index) {
      const qMarks = questionMarks / anyCount
      const quesObject = {
        subQnNo: 'Any ' + anyCount,
        anyQuestionId: v4(),
        questionNumberAny: 'Any ' + outOfCount
        // questionNumber: 'Any ' + anyCount
      }
      this.questionsArray.splice(index, 0, quesObject)
      index++

      for (let i = index; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i]?.questionNo) {
          break
        } else if ((this.questionsArray[i].anyQuestionId) || (this.questionsArray[i].anySubQuestionId)) {
          this.questionsArray.splice(i, 1)
          i--
        }
      }

      // this.questionsArray.push(quesObject)
      let subQueCount = 0
      for (let i = 0; i < outOfCount; i++) {
        // index++
        const obj = {
          question: '',
          anySubQuestionId: v4(),
          anyCount: anyCount,
          subQnNo: 'Q' + this.questionNo + '.' + ++subQueCount,
          anyQue: true,
          questionMarks: qMarks,
          cos: this.coNames,
          blooms: this.bloomsNames
        }
        this.questionsArray.splice(index, 0, obj)
        this.resetQueNo()
      }
    },
    addNormalQuestion() {
      this.subQuestionNo = 0
      const quesObject = {
        questionNumber: 'Q' + ++this.questionNo,
        questionForSection: this.questionItem,
        question: '',
        questionId: v4(),
        questionMarks: null,
        cos: this.coNames,
        blooms: this.bloomsNames
      }
      let foundSec = false
      let breaked = false
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i]?.sectionNames === this.questionItem) {
          foundSec = true
        } else if (this.questionsArray[i].sectionNames && this.questionsArray[i].sectionNames !== this.questionItem && foundSec) {
          this.questionsArray.splice(i, 0, quesObject)
          breaked = true
          break
        }
      }
      if (!breaked) {
        this.questionsArray.push(quesObject)
      }
      // for (let i = 0; i < this.allQuestionObjectArray.length; i++) {
      //   if (this.allQuestionObjectArray[i][this.questionItem]) {
      //     this.allQuestionObjectArray[i][this.questionItem].push(quesObject)
      //   }
      // }
      // this.arrayOfQuestionsToUpdate = []
      // for (let j = 0; j < this.allQuestionObjectArray.length; j++) {

      // }
      // const sectionNames = Object.keys(this.allQuestionObjectArray)
      // for (let i = 0; i < sectionNames.length; i++) {
      //   this.questionsArray.push()
      // }
      // this.questionsArray.push(quesObject)
      this.resetQueNo()
    },
    addOrQuestion(nextIndex) {
      this.subQuestionNo = 0
      const quesObject = {
        questionNumber: 'Q' + ++this.questionNo,
        question: '',
        questionId: v4(),
        questionMarks: null,
        cos: this.coNames,
        blooms: this.bloomsNames
      }
      // quesObject.questionNumber = this.questionsArray[this.questionItemIndex] ? 'Q' + (parseInt(this.questionsArray[this.questionItemIndex - 2]?.questionNumber[this.questionsArray[this.questionItemIndex - 2].questionNumber.length - 1]) + 1) : 'Q' + ++this.questionNo
      // this.questionsArray.splice(this.questionItemIndex, 0, quesObject)
      // for (let i = this.questionItemIndex + 1; i < this.questionsArray.length; i++) {
      //   const previousQuestion = this.questionsArray[i - 1];
      //   const currentQuestion = this.questionsArray[i];

      //   if (previousQuestion?.questionNumber === 'OR') {
      //     this.questionNo = parseInt(this.questionsArray[i - 2]?.questionNumber.slice(-1)) + 1;
      //     currentQuestion.questionNumber = 'Q' + (isNaN(this.questionNo) ? 1 : this.questionNo);
      //   } else {
      //     this.questionNo = parseInt(previousQuestion?.questionNumber.slice(-1)) + 1;
      //     currentQuestion.questionNumber = 'Q' + (isNaN(this.questionNo) ? 1 : this.questionNo);
      //   }
      // }
      // this.questionsArray.splice(this.questionItemIndex + 1, 0, quesObject)
      // this.questionsArray.push(quesObject)
      if (nextIndex) this.questionsArray.splice(nextIndex + 1, 0, quesObject)
      else this.questionsArray.push(quesObject)
      this.resetQueNo()
    },
    addInOrQuestion() {
      const quesObject = {
        questionNumber: 'OR',
        questionId: v4()
      }

      let nextIndex = null
      let isQuestionFound = false
      for (let i = this.questionItemIndex; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i].questionNumber) {
          this.questionsArray.splice(i, 0, quesObject)
          isQuestionFound = true
          nextIndex = i
          break
        } else if (this.questionsArray[i].sectionNames) {
          this.questionsArray.splice(i - 1, 0, quesObject)
          isQuestionFound = true
          nextIndex = i
          break
        }
      }
      if (!isQuestionFound) {
        this.questionsArray.push(quesObject)
      }

      // this.questionsArray.splice(this.questionItemIndex, 0, quesObject)
      this.resetQueNo()
      // this.questionsArray.push(quesObject)
      this.addOrQuestion(nextIndex)
    },
    addSubQuestion() {
      const quesObject = {
        question: '',
        subQuestionId: v4(),
        subQnNo: 'Q' + this.questionNo + '.' + ++this.subQuestionNo,
        questionMarks: null,
        cos: this.coNames,
        blooms: this.bloomsNames
      }
      let foundQn = false
      let breaked = false
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i]?.questionNo === this.questionItem && i === this.questionItemIndex - 1) {
          foundQn = true
        } else if ((this.questionsArray[i].questionNo && this.questionsArray[i].questionNo !== this.questionItem && foundQn) || (this.questionsArray[i].questionNumber && foundQn)) {
          this.questionsArray.splice(i, 0, quesObject)
          breaked = true
          break
        }
      }
      if (!breaked) {
        this.questionsArray.push(quesObject)
      }
      // this.questionsArray.splice(this.questionItemIndex, 0, quesObject)
      this.resetQueNo()
    },
    addOrSubQuestion() {
      const quesObject = {
        subQnNo: 'OR',
        subQuestionId: v4()
      }
      this.questionsArray.splice(this.subQnIndex, 0, quesObject)
      const quesObject2 = {
        question: '',
        subQuestionId: v4(),
        subQnNo: 'Q' + this.questionNo + '.' + ++this.subQuestionNo,
        questionMarks: null,
        cos: this.coNames,
        blooms: this.bloomsNames
      }
      this.questionsArray.splice(this.subQnIndex + 1, 0, quesObject2)
      // this.questionsArray.push(quesObject)
      // this.addSubQuestion()
      this.resetQueNo()
    },
    finalSetQuestions() {
      this.allQuestions = []
      this.setsObjectForQuestions[this.activeSet.setName] = this.questionsArray
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        this.questionsArray = this.setsObjectForQuestions[sets[i]]
        let isBreaked = false
        for (let j = 0; j < this.questionsArray.length; j++) {
          if (this.questionsArray[j]?.question === '' || this.questionsArray[j]?.questionMarks === '') {
            isBreaked = true
            showStatus("Enter question statement or marks in " + (this.questionsArray[j].sectionNames ? this.questionsArray[j].sectionNames : (this.questionsArray[j].questionNumber ? this.questionsArray[j].questionNumber : this.questionsArray[j].subQnNo)), 5000, "error", this);
            break
          }
        }
        if (isBreaked) {
          this.questionsValidationSuccess = false
          break
        } else {
          this.questionsValidationSuccess = true
          this.validateSectionWiseMarks(sets[i])
        }
      }
      if (this.questionsValidationSuccess) {
        this.finalQuestionsSave()
      }
    },
    async finalQuestionsSave() {
      try {
        const objToPass = {
          subjectId: this.prop_selectedSubject.subjectId,
          examId: this.exam.exam.examId,
          instituteId: this.selectedInstitute,
          marks: this.exam.exam.totalMarks,
          overallCOs: this.overallCos,
          overallBlooms: this.overallBlooms
        }
        this.questionsCheckbox ? objToPass.allQuestionsArray = this.allQuestions : objToPass.allQuestionsArray = []
        const data = await this.ExamSubjectDetailsAndQuestionsRepositoryInstance.parseExcel(objToPass);
        if (data) showStatus("Questions successfully added in exam.", 2000, 'success', this);
      } catch (error) {
        console.error(error);
      }
    },
    validateSectionWiseMarks(set) {
      let totalSectionMarks = 0
      if (this.questionsArray.length > 0) {
        for (let i = 0; i < this.questionsArray.length; i++) {
          if (this.questionsArray[i]?.sectionNames) {
            totalSectionMarks += (this.questionsArray[i].questionMarks - '')
          }
        }
        if (totalSectionMarks !== this.exam.exam.totalMarks) {
          showStatus("Section marks should be equal to the total marks of assignment", 5000, "error", this);
          this.questionsValidationSuccess = false
        } else {
          this.validateQuestionUnderSectionWiseMarks(set)
        }
      } else {
        showStatus("Questions are not added yet", 5000, "error", this);
        this.questionsValidationSuccess = false
      }
    },
    validateQuestionUnderSectionWiseMarks(set) {
      let totalQuestionUnderSectionMarks = 0
      let sectionMarks = 0
      let areQuestionPresent = false
      let checkNext = true
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i]?.sectionNames) {
          if (totalQuestionUnderSectionMarks !== sectionMarks && areQuestionPresent) {
            showStatus(`Total questions under ${this.questionsArray[i].sectionNames} should be equal to the marks assigned to it`, 5000, "error", this);
            checkNext = false
            this.questionsValidationSuccess = false
            break
          }
          sectionMarks = (this.questionsArray[i].questionMarks - '')
          totalQuestionUnderSectionMarks = 0
          areQuestionPresent = false
        } else if (this.questionsArray[i]?.questionNumber && this.questionsArray[i]?.questionNumber !== 'OR') {
          totalQuestionUnderSectionMarks += (this.questionsArray[i].questionMarks - '')
          areQuestionPresent = true
        } else if (this.questionsArray[i]?.questionNumber && this.questionsArray[i]?.questionNumber === 'OR') {
          totalQuestionUnderSectionMarks -= (this.questionsArray[i + 1].questionMarks - '')
        }
      }
      if (totalQuestionUnderSectionMarks !== sectionMarks && areQuestionPresent) {
        checkNext = false
        showStatus(`Total questions under section should be equal to the marks assigned to it`, 5000, "error", this);
        checkNext = false
        this.questionsValidationSuccess = false
      }
      if (checkNext) {
        this.validateSubQUestionUnderQuestionUnderSectionWiseMarks(set)
      }
    },
    validateSubQUestionUnderQuestionUnderSectionWiseMarks(set) {
      let totalSubQuestionUnderQuestionUnderSectionMarks = 0
      let questionMarks = 0
      let areSubQuestionsAvailable = false
      let saveQuestions = true
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i]?.questionNumber && this.questionsArray[i]?.questionNumber !== 'OR') {
          if (totalSubQuestionUnderQuestionUnderSectionMarks !== questionMarks && areSubQuestionsAvailable) {
            showStatus(`Total sub questions marks under ${this.questionsArray[i].question} should be equal to the marks assigned to it`, 5000, "error", this);
            saveQuestions = false
            this.questionsValidationSuccess = false
            break
          }
          questionMarks = (this.questionsArray[i].questionMarks - '')
          totalSubQuestionUnderQuestionUnderSectionMarks = 0
          areSubQuestionsAvailable = false
        } else if (this.questionsArray[i]?.subQnNo && this.questionsArray[i]?.subQnNo !== 'OR' && !this.questionsArray[i]?.questionNumberAny && !this.questionsArray[i]?.anySubQuestionId && !this.questionsArray[i].anyStatement) {
          totalSubQuestionUnderQuestionUnderSectionMarks += (this.questionsArray[i].questionMarks - '')
          areSubQuestionsAvailable = true
        } else if (this.questionsArray[i]?.subQnNo && this.questionsArray[i]?.subQnNo === 'OR') {
          totalSubQuestionUnderQuestionUnderSectionMarks -= (this.questionsArray[i + 1].questionMarks - '')
        }
      }
      if (totalSubQuestionUnderQuestionUnderSectionMarks !== questionMarks && areSubQuestionsAvailable) {
        saveQuestions = false
        showStatus(`Total sub questions marks under question should be equal to the marks assigned to it`, 5000, "error", this);
        saveQuestions = false
        this.questionsValidationSuccess = false
      }
      if (saveQuestions) {
        this.saveQuestions(set)
      }
    },
    isFloatingNumber(value) {
      return typeof value === 'number' && value % 1 !== 0;
    },
    async saveQuestions(set) {
      this.questionToGiveMarks = this.questionsArray
      this.allQuestions.push({
        setName: set
      })
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i].sectionNames) {
          this.allQuestions.push({
            'Section No.': this.questionsArray[i].sectionNames,
            Section: this.questionsArray[i].question,
            Marks: this.questionsArray[i].questionMarks,
            Cos: this.questionsArray[i].selectedCos,
            Blooms: this.questionsArray[i].selectedBlooms
          })
          continue
        }
        if (this.questionsArray[i].questionNumber && this.questionsArray[i].questionNumber !== 'OR') {
          this.allQuestions.push({
            'Question No.': this.questionsArray[i].questionNumber,
            Question: this.questionsArray[i].question,
            Marks: this.questionsArray[i].questionMarks,
            Cos: this.questionsArray[i].selectedCos,
            Blooms: this.questionsArray[i].selectedBlooms
          })
          continue
        }
        if (this.questionsArray[i].questionNumber && this.questionsArray[i].questionNumber === 'OR') {
          this.allQuestions.push({
            'Question No.': 'OR'
          })
          continue
        }
        if (this.questionsArray[i].subQnNo && this.questionsArray[i].subQnNo !== 'OR') {
          this.allQuestions.push({
            'Sub-Question No.': this.questionsArray[i].subQnNo,
            'Sub-Question': this.questionsArray[i].question,
            Marks: this.isFloatingNumber(this.questionsArray[i].questionMarks)
              ? parseFloat(this.questionsArray[i].questionMarks.toFixed(2))
              : this.questionsArray[i].questionMarks,
            Cos: this.questionsArray[i].selectedCos,
            Blooms: this.questionsArray[i].selectedBlooms
          })
          continue
        }
        if (this.questionsArray[i].subQnNo && this.questionsArray[i].subQnNo === 'OR') {
          this.allQuestions.push({
            'Sub-Question No.': 'OR'
          })
          continue
        }
        if (this.questionsArray[i].questionNumberAny) {
          this.allQuestions.push({
            'Question No.': this.questionsArray[i].questionNumberAny
          })
          continue
        }
      }
    },
    async StudentMarks() {
      if (this.questionsCheckbox) {
        console.log('!this.headers1[3]', this.headers1[3])
        if(this.headers1[3].text !== 'Set attempted') {
          this.headers1.splice(3, 0, {
            text: 'Set attempted',
            value: 'sets',
            sortable: false,
            width: "10%",
            align: "center"
          });
        }
      }
      else if (!this.questionsCheckbox && this.headers1[3].text === 'Set attempted') {
        this.headers1.splice(3, 1);
      }

      try {
        if (this.prop_typeDivGrpBatch === "Division" || this.prop_typeDivGrpBatch === "Batch") {
          this.prop_selectedSubject.division = this.prop_selectedDivision
          console.log('reached')
          const data =
            await this.semUserTestInstance.getSemesterUsersOfASubjectOfADivision(
              this.prop_selectedSubject
            );
          if (this.prop_typeDivGrpBatch === "Batch") {
            this.students = data.filter(item => item.batch === this.prop_selectedBatch.batchName)
          } else {
            this.students = data
          }
        }
        if (this.prop_typeDivGrpBatch === "Group") {
          this.prop_selectedSubject.groupId = this.prop_selectedGroup.groupId
          this.students =
            await this.semUserInstance.getSemesterUsersOfASubjectOfAGroup(
              this.prop_selectedSubject
            );
        }
        // this.prop_assignment.assignedStudentList.map((id) => {
        //   this.students.map((stu) => {
        //     if (stu.uId === id) {
        //       this.dummyStudentListFiltered.push(stu)
        //     }
        //   })
        // })
        // this.students = [...this.dummyStudentListFiltered]
      } catch (err) {
        console.error(err);
        console.log('error')
      }

      let assignmentUsers = [];
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          assignmentId: this.exam.exam.examId,
          department: this.prop_selectedSubject.department,
          courseYear: this.prop_selectedSubject.courseYear,
          division: this.prop_selectedDivision,
          batch: this.prop_selectedBatch.batchName,
          subjectId: this.prop_selectedSubject.subjectId
        };
        if (this.prop_typeDivGrpBatch === "Group") {
          objToPush.groupId = this.prop_selectedGroup.groupId
        }
        assignmentUsers =
          await this.assignmentUserRepositoryInstance.getAssignmentUsersOfAnAssignmentForADivision(
            objToPush
          );
        this.calculateStudent(assignmentUsers)
      } catch (err) {
        console.error(err);
        assignmentUsers = []
        this.calculateStudent(assignmentUsers)
      }
    },
    async calculateStudent(assignmentUsers) {
      for (let i = 0; i < this.students.length; i++) {
        const assignmentUserDocs = assignmentUsers.filter(
          (assignmentUser) => assignmentUser.uId === this.students[i].uId
        );
        const assignmentUser =
          assignmentUserDocs[assignmentUserDocs.length - 1];

        if (assignmentUser) {
          let tempAnsMarks = 0;
          if (assignmentUser.questions?.length > 0) {
            for (let i = 0; i < assignmentUser.questions.length; i++) {
              tempAnsMarks += +assignmentUser.questions[i].obtainedMarks || 0;
            }
            this.students[i].totalMarksObtained = tempAnsMarks;
          } else {
            this.students[i].totalMarksObtained = assignmentUserDocs[0].totalMarksObtained;
          }
          this.students[i].submittedOn = assignmentUser.submittedOn
          this.totalSubmissions++;
          this.students[i].status = assignmentUser.status === '' ? "Submitted" : assignmentUser.status;
          this.students[i].attemptNumber = assignmentUser.attemptNumber;
          this.students[i].files = assignmentUser.linksOfAttachments || [];
          this.students[i].remarkAttachments =
            assignmentUser.remarkAttachments || [];
          this.students[i].remark = assignmentUser.remark || "";
          this.students[i].questions = assignmentUser.questions || [];
          this.students[i].answers = assignmentUser.answers;
        } else {
          this.students[i].totalMarksObtained = 0;
          this.students[i].status = "Not Submitted";
          this.students[i].attemptNumber = 1;
          this.students[i].files = [];
        }
      }
      const batchSize = 100;
      const promises = [];

      for (let i = 0; i < this.students.length; i += batchSize) {
        promises.push(this.getFullNameOfUser(i, Math.min(i + batchSize, this.students.length)));
      }

      await Promise.allSettled(promises);

      this.students = [...this.students];
    },

    async getFullNameOfUser(firstIndex, lastIndex) {
      const sets = []
      await this.fetchAssignmentQuestions()
      for (let i = 0; i < this.createdQuestions?.allQuestions.length; i++) {
        if (this.createdQuestions.allQuestions[i].questionType === 'Set') {
          sets.push({
            questionId: this.createdQuestions.allQuestions[i].questionId,
            setName: this.createdQuestions.allQuestions[i].questionStatement
          })
        }
      }
      try {
        const userObject = {
          uIds: []
        };

        for (let i = firstIndex; i < lastIndex; i++) {
          userObject.uIds.push(this.students[i].uId);
        }

        const fullNames = await this.userRepositoryInstance.getFullNameOfUsers(userObject);

        for (let i = firstIndex; i < lastIndex; i++) {
          const { fullName, collegePRNNo } = fullNames.find(({ uId }) => uId === this.students[i].uId) || {};
          this.students[i].name = fullName || "";
          this.students[i].PRN_No = collegePRNNo || "";
          this.students[i].sets = sets
          // if (this.students[i].submittedOn && new Date(this.students[i].submittedOn.split('T')[0]) > new Date(this.dateOfCompletion)) this.students[i].status = "Late Submitted on " + this.students[i].submittedOn.split('T')[0]
          // else if (this.students[i].submittedOn && new Date(this.students[i].submittedOn.split('T')[0]) < new Date(this.dateOfCompletion)) this.students[i].status = "Submitted on " + this.students[i].submittedOn.split('T')[0]
          if (this.students[i].submittedOn) {
            const submittedDate = new Date(this.students[i].submittedOn?.split('T')[0]);

            if (submittedDate > new Date(this.dateOfCompletion)) {
              this.students[i].submittedStatus = "Late Submitted on " + this.students[i].submittedOn?.split('T')[0]
            } else {
              this.students[i].submittedStatus = "Submitted on " + this.students[i].submittedOn?.split('T')[0]
            }
          } else {
            this.students[i].submittedStatus = "Not Submitted"
          }
        }
        this.students = this.students.sort((a, b) => a.PRN_No.localeCompare(b.PRN_No))
        for (let i = 0; i < this.students.length; i++) {
          this.students[i].srNo = i + 1
        }
      } catch (err) {
        console.error(err);
      }
    },
    async showCanvas(itemFile, itemLink, assignmentUser) {
      let file
      let link
      let contentType
      this.showAttachment = true
      if (typeof (itemFile.submittedFile) === 'object') {
        file = itemFile.submittedFile.url
      } else {
        file = { submittedFile: itemLink.submittedFile.url }
      }
      if (itemFile.checkedFile) {
        if (typeof (itemFile.checkedFile) === 'object') {
          file = itemFile.checkedFile.url
        } else {
          file = { checkedFile: itemLink.url }
        }
      }
      if (typeof (itemLink) === 'object') {
        link = itemLink.url
        contentType = itemLink.mimeType
      } else {
        link = itemLink
      }
      //TODO: add a middle function in between to first
      //check if the file is image or not with a more
      //generic name eg: openFile or handleFileClick
      if (typeof (itemLink) !== 'object') {
        const firebaseInstance = this.firebaseInstance;
        const storageRef = firebaseInstance.storage().refFromURL(link);
        contentType = await storageRef.getMetadata();
      }
      if (typeof (itemLink) !== 'object') {
        if (!contentType.includes("image")) {
          window.open(link, "_blank").focus();
          return;
        }
      } else {
        if (contentType !== 'jpeg' && contentType !== 'jpg' && contentType !== 'png') {
          window.open(link, "_blank").focus();
          return;
        }
      }
      this.showCanvasDialog = false;
      this.tempStroke = [];
      this.strokes = [];
      const self = this;
      self.tempFile = file;
      self.tempLink = link;
      self.tempAssignmentUser = assignmentUser;
      self.canvasContainer.innerHTML = "";
      const background = new Image();
      background.src = self.tempLink;
      background.setAttribute("crossorigin", "anonymous");
      const width = self.mainCard.offsetWidth - 20;
      background.onload = function () {
        self.background = background;
        self.canvas = document.createElement("canvas");
        self.ctx = self.canvas.getContext("2d");
        self.oc = document.createElement("canvas");
        self.octx = self.oc.getContext("2d");
        self.canvas.width = width;
        self.canvas.height = (self.canvas.width * this.height) / this.width;
        var cur = {
          width: Math.floor(this.width * 0.5),
          height: Math.floor(this.height * 0.5)
        };
        self.oc.width = cur.width;
        self.oc.height = cur.height;
        self.octx.drawImage(this, 0, 0, cur.width, cur.height);
        while (cur.width * 0.5 > width) {
          cur = {
            width: Math.floor(cur.width * 0.5),
            height: Math.floor(cur.height * 0.5)
          };
          self.octx.drawImage(
            self.oc,
            0,
            0,
            cur.width * 2,
            cur.height * 2,
            0,
            0,
            cur.width,
            cur.height
          );
        }
        self.ctx.drawImage(
          self.oc,
          0,
          0,
          cur.width,
          cur.height,
          0,
          0,
          self.canvas.width,
          self.canvas.height
        );
        self.canvasContainer.appendChild(self.canvas);
        self.showCanvasDialog = true;
        self.ctx.lineCap = "square";
        self.ctx.lineWidth = 5;
        self.ctx.globalCompositeOperation = "source-over";
        let lastPos = false;
        let isDown = false;
        self.canvas.onpointerdown = function (e) {
          e.preventDefault();
          isDown = true;
          self.tempStroke = [];
          lastPos = getPos(e);
          self.ctx.strokeStyle = "red";
        };
        self.canvas.onpointermove = function (e) {
          e.preventDefault();
          if (!isDown) return;
          var pos = getPos(e);
          self.ctx.beginPath();
          self.ctx.moveTo(lastPos.x, lastPos.y);
          self.ctx.lineTo(pos.x, pos.y);
          self.tempStroke.push({
            lastX: lastPos.x,
            lastY: lastPos.y,
            posX: pos.x,
            posY: pos.y
          });
          self.ctx.stroke();
          lastPos = pos;
        };
        self.canvas.onpointerup = function (e) {
          e.preventDefault();
          self.strokes.push(self.tempStroke);
          isDown = false;
        };
        self.canvas.touchstart = function (e) {
          e.preventDefault();
          isDown = true;
          self.tempStroke = [];
          lastPos = getPos(e);
          self.ctx.strokeStyle = "red";
        };
        self.canvas.touchmove = function (e) {
          e.preventDefault();
          if (!isDown) return;
          var pos = getPos(e);
          self.ctx.beginPath();
          self.ctx.moveTo(lastPos.x, lastPos.y);
          self.ctx.lineTo(pos.x, pos.y);
          self.tempStroke.push({
            lastX: lastPos.x,
            lastY: lastPos.y,
            posX: pos.x,
            posY: pos.y
          });
          self.ctx.stroke();
          lastPos = pos;
        };
        self.canvas.touchend = function (e) {
          e.preventDefault();
          self.strokes.push(self.tempStroke);
          isDown = false;
        };
        function getPos(e) {
          var rect = self.canvas.getBoundingClientRect();
          return { x: e.clientX - rect.left, y: e.clientY - rect.top };
        }
      };
      window.scroll({
        top: 64,
        behavior: "smooth"
      });
    },
    async undoStroke() {
      const self = this;
      this.showCanvasDialog = true;
      self.canvasContainer.innerHTML = "";
      const width = self.mainCard.offsetWidth - 20;
      const background = new Image();
      background.src = self.tempLink;
      background.setAttribute("crossorigin", "anonymous");
      background.onload = function () {
        self.background = background;
        self.canvas = document.createElement("canvas");
        self.ctx = self.canvas.getContext("2d");
        self.oc = document.createElement("canvas");
        self.octx = self.oc.getContext("2d");
        self.canvas.width = width;
        self.canvas.height = (self.canvas.width * this.height) / this.width;
        var cur = {
          width: Math.floor(this.width * 0.5),
          height: Math.floor(this.height * 0.5)
        };
        self.oc.width = cur.width;
        self.oc.height = cur.height;
        self.octx.drawImage(this, 0, 0, cur.width, cur.height);
        while (cur.width * 0.5 > width) {
          cur = {
            width: Math.floor(cur.width * 0.5),
            height: Math.floor(cur.height * 0.5)
          };
          self.octx.drawImage(
            self.oc,
            0,
            0,
            cur.width * 2,
            cur.height * 2,
            0,
            0,
            cur.width,
            cur.height
          );
        }
        self.ctx.drawImage(
          self.oc,
          0,
          0,
          cur.width,
          cur.height,
          0,
          0,
          self.canvas.width,
          self.canvas.height
        );
        self.canvasContainer.appendChild(self.canvas);
        self.showCanvasDialog = true;
        self.ctx.lineCap = "square";
        self.ctx.lineWidth = 5;
        self.ctx.globalCompositeOperation = "source-over";
        let lastPos = false;
        let isDown = false;
        self.strokes.pop();
        // self.strokes.pop()
        if (self.strokes.length > 0) {
          for (let i = 0; i < self.strokes.length; i++) {
            for (let j = 0; j < self.strokes[i].length; j++) {
              const str = self.strokes[i][j];
              self.ctx.strokeStyle = "red";
              self.ctx.beginPath();
              self.ctx.moveTo(str.lastX, str.lastY);
              self.ctx.lineTo(str.posX, str.posY);
              self.ctx.stroke();
            }
          }
        }
        self.canvas.onpointerdown = function (e) {
          e.preventDefault();
          isDown = true;
          self.tempStroke = [];
          lastPos = getPos(e);
          self.ctx.strokeStyle = "red";
        };
        self.canvas.onpointermove = function (e) {
          e.preventDefault();
          if (!isDown) return;
          var pos = getPos(e);
          self.ctx.beginPath();
          self.ctx.moveTo(lastPos.x, lastPos.y);
          self.ctx.lineTo(pos.x, pos.y);
          self.tempStroke.push({
            lastX: lastPos.x,
            lastY: lastPos.y,
            posX: pos.x,
            posY: pos.y
          });
          self.ctx.stroke();
          lastPos = pos;
        };
        self.canvas.onpointerup = function (e) {
          e.preventDefault();
          self.strokes.push(self.tempStroke);
          isDown = false;
        };
        self.canvas.touchstart = function (e) {
          e.preventDefault();
          isDown = true;
          self.tempStroke = [];
          lastPos = getPos(e);
          self.ctx.strokeStyle = "red";
        };
        self.canvas.touchmove = function (e) {
          e.preventDefault();
          if (!isDown) return;
          var pos = getPos(e);
          self.ctx.beginPath();
          self.ctx.moveTo(lastPos.x, lastPos.y);
          self.ctx.lineTo(pos.x, pos.y);
          self.tempStroke.push({
            lastX: lastPos.x,
            lastY: lastPos.y,
            posX: pos.x,
            posY: pos.y
          });
          self.ctx.stroke();
          lastPos = pos;
        };
        self.canvas.touchend = function (e) {
          e.preventDefault();
          self.strokes.push(self.tempStroke);
          isDown = false;
        };
        function getPos(e) {
          var rect = self.canvas.getBoundingClientRect();
          return { x: e.clientX - rect.left, y: e.clientY - rect.top };
        }
      };
    },
    getAllSections() {
      this.allSections = []
      for (let i = 0; i < this.createdQuestions?.allQuestions.length; i++) {
        if (this.createdQuestions?.allQuestions[i].questionType === 'Set') {
          for (let j = 0; j < this.createdQuestions.allQuestions[i].question.length; j++) {
            this.allSections.push({ questionId: this.createdQuestions.allQuestions[i].question[j].questionId, sectionName: this.createdQuestions.allQuestions[i].question[j].questionStatement, obtainedMarks: null, questionMarks: this.createdQuestions.allQuestions[i].question[j].questionMarks })
          }
        }
      }
      for (let i = 0; i < this.students.length; i++) {
        this.students[i].allSections = this.allSections
      }
    },
    getQuestionsOfSelectedSet(set, item) {
      this.studentData = item
      let anyQuestionFound = false
      let setQuestions = [];
      for (let i = 0; i < this.createdQuestions.allQuestions.length; i++) {
        if (this.createdQuestions.allQuestions[i].questionType === 'Set' && this.createdQuestions.allQuestions[i].questionStatement === set.setName) {
          setQuestions = this.createdQuestions.allQuestions[i].question;
        }
      }
      this.seperatedSetQuestions = []

      for (let i = 0; i < setQuestions?.length; i++) {
        anyQuestionFound = false
        this.seperatedSetQuestions.push({
          sectionNames: setQuestions[i].questionNumber,
          question: setQuestions[i].questionStatement,
          questionId: setQuestions[i].questionId,
          questionMarks: setQuestions[i].questionMarks,
          selectedCos: setQuestions[i]?.cos,
          cos: this.coNames,
          selectedBlooms: setQuestions[i].blooms,
          blooms: this.bloomsNames
        })
        if (setQuestions[i].question?.length > 0) {
          const sectionQuestion = setQuestions[i].question
          for (let j = 0; j < sectionQuestion?.length; j++) {
            if (sectionQuestion[j].questionType === 'OrQuestion') {
              anyQuestionFound = false
              this.seperatedSetQuestions.push({
                questionNumber: 'OR'
              })
            }
            anyQuestionFound = false
            this.seperatedSetQuestions.push({
              questionNumber: sectionQuestion[j]?.questionNumber,
              question: sectionQuestion[j]?.questionStatement === 'Solve any_out of' ? 'Solve any (' + sectionQuestion[j].NumberOfQuestionsToSolve + ')' : sectionQuestion[j].questionStatement,
              tryl: sectionQuestion[j]?.NumberOfQuestionsToSolve,
              anyCount: sectionQuestion[j]?.NumberOfQuestionsToSolve,
              outOfCount: sectionQuestion[j]?.question?.length,
              solveAnyCount: sectionQuestion[j]?.questionStatement === 'Solve any_out of' ? 0 : sectionQuestion[j]?.NumberOfQuestionsToSolve,
              questionId: sectionQuestion[j]?.questionId,
              questionMarks: sectionQuestion[j]?.questionMarks,
              selectedCos: sectionQuestion[j]?.cos,
              cos: this.coNames,
              selectedBlooms: sectionQuestion[j]?.blooms,
              blooms: this.bloomsNames
            })
            if (sectionQuestion[j].NumberOfQuestionsToSolve) {
              anyQuestionFound = true
              this.seperatedSetQuestions.push({
                subQnNo: 'Any ' + sectionQuestion[j].NumberOfQuestionsToSolve,
                anyCount: sectionQuestion[j].NumberOfQuestionsToSolve,
                anyStatement: true
              })
            }
            if (sectionQuestion[j]?.question?.length > 0) {
              const subQuestion = sectionQuestion[j]?.question
              for (let k = 0; k < subQuestion.length; k++) {
                if (subQuestion[k].questionType === 'OrSubQuestion') {
                  this.seperatedSetQuestions.push({
                    subQnNo: 'OR',
                    anyStatement: false
                  })
                }
                if (anyQuestionFound) {
                  this.seperatedSetQuestions.push({
                    subQnNo: subQuestion[k].questionNumber,
                    anySubQuestion: true,
                    anyStatement: true,
                    question: subQuestion[k].questionStatement,
                    questionId: subQuestion[k]?.questionId,
                    questionMarks: subQuestion[k].questionMarks,
                    selectedCos: subQuestion[k].cos,
                    cos: this.coNames,
                    selectedBlooms: subQuestion[k].blooms,
                    blooms: this.bloomsNames
                  })
                } else {
                  anyQuestionFound = false
                  this.seperatedSetQuestions.push({
                    subQnNo: subQuestion[k].questionNumber,
                    anyStatement: false,
                    question: subQuestion[k].questionStatement,
                    questionId: subQuestion[k]?.questionId,
                    questionMarks: subQuestion[k].questionMarks,
                    selectedCos: subQuestion[k].cos,
                    cos: this.coNames,
                    selectedBlooms: subQuestion[k].blooms,
                    blooms: this.bloomsNames
                  })
                }
              }
            }
          }
        }
      }
      // this.questionWiseMarksDialog = true
      if (!set.forShowingQuestions && this.studentData?.answers) {
        const studentDataMap = new Map(this.studentData?.answers.map(data => [data.questionId, data]));
        for (const setQuestion of this.seperatedSetQuestions) {
          const studentDataForQuestion = studentDataMap.get(setQuestion.questionId);
          if (studentDataForQuestion) {
            setQuestion.assignMarks = studentDataForQuestion.obtainedMarks;
          }
        }
        this.questionWiseMarksDialog = true
      } else return this.seperatedSetQuestions
    },
    validateGivenSectionWiseMarks() {
      let totalSectionMarks = 0
      if (this.seperatedSetQuestions.length > 0) {
        for (let i = 0; i < this.seperatedSetQuestions.length; i++) {
          if (this.seperatedSetQuestions[i]?.sectionNames) {
            totalSectionMarks += (this.seperatedSetQuestions[i]?.assignMarks - '')
          }
        }
        if (totalSectionMarks > this.exam.exam.totalMarks) {
          showStatus("Section marks should be equal or less to the total marks of assignment", 5000, "error", this);
          this.questionsValidationSuccess = false
        } else {
          this.validateGivenQuestionUnderSectionWiseMarks()
        }
      } else {
        showStatus("Questions are not added yet", 5000, "error", this);
        this.questionsValidationSuccess = false
      }
    },
    validateGivenQuestionUnderSectionWiseMarks(set) {
      let totalQuestionUnderSectionMarks = 0
      let sectionMarks = 0
      let areQuestionPresent = false
      let checkNext = true
      for (let i = 0; i < this.seperatedSetQuestions.length; i++) {
        if (this.seperatedSetQuestions[i]?.sectionNames) {
          if (totalQuestionUnderSectionMarks > sectionMarks && areQuestionPresent) {
            showStatus(`Total questions under ${this.seperatedSetQuestions[i].sectionNames} should be equal or less to the marks assigned to it`, 5000, "error", this);
            checkNext = false
            this.questionsValidationSuccess = false
            break
          }
          sectionMarks = (this.seperatedSetQuestions[i]?.assignMarks - '')
          totalQuestionUnderSectionMarks = 0
        } else if (this.seperatedSetQuestions[i]?.questionNumber && this.seperatedSetQuestions[i]?.questionNumber !== 'OR') {
          totalQuestionUnderSectionMarks += (this.questionsArray[i]?.assignMarks - '')
          areQuestionPresent = true
        } else if (this.seperatedSetQuestions[i]?.questionNumber && this.seperatedSetQuestions[i]?.questionNumber === 'OR') {
          totalQuestionUnderSectionMarks -= (this.seperatedSetQuestions[i + 1]?.assignMarks - '')
        }
      }
      if (totalQuestionUnderSectionMarks > sectionMarks && areQuestionPresent) {
        checkNext = false
        showStatus(`Total questions under section should be equal or less to the marks assigned to it`, 5000, "error", this);
        checkNext = false
        this.questionsValidationSuccess = false
      }
      if (checkNext) {
        this.validateGivenSubQUestionUnderQuestionUnderSectionWiseMarks()
      }
    },
    validateGivenSubQUestionUnderQuestionUnderSectionWiseMarks() {
      let totalSubQuestionUnderQuestionUnderSectionMarks = 0
      let assignMarks = 0
      let areSubQuestionsAvailable = false
      let saveQuestions = true
      for (let i = 0; i < this.seperatedSetQuestions.length; i++) {
        if (this.seperatedSetQuestions[i]?.questionNumber) {
          if (totalSubQuestionUnderQuestionUnderSectionMarks > assignMarks && areSubQuestionsAvailable) {
            showStatus(`Total sub questions marks under ${this.seperatedSetQuestions[i].question} should be equal or less to the marks assigned to it`, 5000, "error", this);
            saveQuestions = false
            this.questionsValidationSuccess = false
            break
          }
          assignMarks = (this.seperatedSetQuestions[i]?.assignMarks - '')
          totalSubQuestionUnderQuestionUnderSectionMarks = 0
        } else if (this.seperatedSetQuestions[i]?.subQnNo && this.seperatedSetQuestions[i]?.subQnNo !== 'OR' && !this.seperatedSetQuestions[i]?.questionNumberAny && !this.seperatedSetQuestions[i]?.anySubQuestionId) {
          totalSubQuestionUnderQuestionUnderSectionMarks += (this.seperatedSetQuestions[i].assignMarks - '')
          areSubQuestionsAvailable = true
        } else if (this.seperatedSetQuestions[i]?.subQnNo && this.seperatedSetQuestions[i]?.subQnNo === 'OR') {
          totalSubQuestionUnderQuestionUnderSectionMarks -= (this.seperatedSetQuestions[i + 1].assignMarks - '')
        }
      }
      if (totalSubQuestionUnderQuestionUnderSectionMarks > assignMarks && areSubQuestionsAvailable) {
        saveQuestions = false
        showStatus(`Total sub questions marks under question should be equal or less to the marks assigned to it`, 5000, "error", this);
        saveQuestions = false
        this.questionsValidationSuccess = false
      }
      if (saveQuestions) {
        this.saveQuestionWiseMarks()
      }
    },
    async saveQuestionWiseMarks() {
      let marksIncorrect = false
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.studentData.uId,
          examId: this.exam.exam.examId,
          attemptNumber: this.studentData.attemptNumber,
          totalMarksObtained: null,
          status: 'Submitted',
          subjectId: this.prop_selectedSubject.subjectId,
          answers: []
        };
        for (let i = 0; i < this.seperatedSetQuestions.length; i++) {
          if (this.seperatedSetQuestions[i].assignMarks) {
            if (Number(this.seperatedSetQuestions[i].assignMarks) > Number(this.seperatedSetQuestions[i].questionMarks)) {
              marksIncorrect = true
              showStatus(`Given Marks can not be greater than question marks`, 5000, "error", this);
            } else {
              objToPush.answers.push({
                questionId: this.seperatedSetQuestions[i]?.questionId,
                obtainedMarks: this.seperatedSetQuestions[i].assignMarks
              })
              objToPush.totalMarksObtained += this.seperatedSetQuestions[i].sectionNames ? (this.seperatedSetQuestions[i].assignMarks - '') : 0
            }
          }
        }
        if (this.studentData?.status !== 'Submitted') {
          objToPush.submittedOn = this.studentData?.submittedOn ? new Date(this.studentData?.submittedOn).toISOString() : new Date().toISOString();
        }
        if (!marksIncorrect) {
          await this.assignmentUserRepositoryInstance.updateMarksObtainedOfAnUserForAnAssignment(
            objToPush
          );
          this.students[this.students.indexOf(this.studentData)].totalMarksObtained = this.studentData.totalMarksObtained
          this.students[this.students.indexOf(this.studentData)].submittedOn = convertDateTime(objToPush.submittedOn);
          showStatus("Marks updated successfully", 2000, 'success', this);
          this.StudentMarks()
          this.questionWiseMarksDialog = false
        }
      } catch (err) {
        console.error(err);
        showStatus("Error updating marks", 1000, "error", this);
      }
    },
    async updateMarksOfAStudent(item, limit) {
      if (item.totalMarksObtained > limit) {
        showStatus("Invalid marks entered", 1000, "error", this);
        this.fetchData();
      } else {
        if (item.totalMarksObtained !== "") {
          try {
            const objToPush = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              uId: item.uId,
              examId: this.exam.exam.examId,
              attemptNumber: item.attemptNumber,
              totalMarksObtained: item.totalMarksObtained,
              status: 'Submitted',
              subjectId: this.prop_selectedSubject.subjectId,
              answers: [{ questionId: item.selectedSet?.questionId, obtainedMarks: item.totalMarksObtained }]
            };
            if (item.status !== 'Submitted') {
              objToPush.submittedOn = item?.submittedOn ? new Date(item?.submittedOn).toISOString() : new Date().toISOString();
            }
            await this.assignmentUserRepositoryInstance.updateMarksObtainedOfAnUserForAnAssignment(
              objToPush
            );
            this.students[this.students.indexOf(item)].totalMarksObtained = item.totalMarksObtained
            this.students[this.students.indexOf(item)].submittedOn = convertDateTime(objToPush.submittedOn);
            showStatus("Marks updated successfully", 2000, 'success', this);
          } catch (err) {
            console.error(err);
            showStatus("Error updating marks", 1000, "error", this);
          }
        }
      }
    },
    getSetSections(selectedSet) {
      this.sectionsOfSelectedSet = []
      for (let i = 0; i < this.setsObjectForQuestions[selectedSet.setName].length; i++) {
        if (this.setsObjectForQuestions[selectedSet.setName][i].sectionNames) {
          this.sectionsOfSelectedSet.push(this.setsObjectForQuestions[selectedSet.setName][i].question)
        }
      }
    },
    async sectionWiseStudentMarks(item, secName) {
      const answersArray = []
      let totalMarksObtained = 0
      for (let i = 0; i < this.allSections.length; i++) {
        if (item[this.allSections[i].sectionName]) {
          answersArray.push({
            questionId: this.allSections[i].questionId,
            obtainedMarks: (item[this.allSections[i].sectionName] - '')
          })
          totalMarksObtained += (item[this.allSections[i].sectionName] - '')
        }
      }
      if (totalMarksObtained > this.exam.exam.totalMarks) {
        showStatus("Given Section Marks can't be greater than Total assignment Marks", 1000, "error", this);
      } else {
        try {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            uId: item.uId,
            examId: this.exam.exam.examId,
            attemptNumber: item.attemptNumber,
            totalMarksObtained: totalMarksObtained,
            status: 'Submitted',
            subjectId: this.prop_selectedSubject.subjectId,
            answers: answersArray
          };
          if (item.status !== 'Submitted') {
            objToPush.submittedOn = item?.submittedOn ? new Date(item?.submittedOn).toISOString() : new Date().toISOString();
          }
          await this.assignmentUserRepositoryInstance.updateMarksObtainedOfAnUserForAnAssignment(
            objToPush
          );
          this.students[this.students.indexOf(item)].totalMarksObtained = item.totalMarksObtained
          this.students[this.students.indexOf(item)].submittedOn = convertDateTime(objToPush.submittedOn);
          showStatus("Marks updated successfully", 2000, 'success', this);
        } catch (err) {
          console.error(err);
          showStatus("Error updating marks", 1000, "error", this);
        }
      }
      // }
    },
    async saveImage() {
      this.cancelEditing();
      this.loading = true;
      this.showAttachment = false
      const imageBase = this.canvas.toDataURL("image/png");
      const image = new Image();
      image.src = imageBase;
      const file = this.dataURLtoFile(imageBase, "checkedAssignment.png");

      const doFile = await this.uploadFile([file])
      this.checkAssignment(doFile)
    },
    async uploadFile(fileArr) {
      try {
        // let urlLinkArr = [];
        if (fileArr.length > 0) {
          const fileName = fileArr.filter(item => item.name);
          const data = await this.uploadToDigitalOceanInstance.upload(fileName, this.prop_selectedFaculty.uId, 'checkedAssignments')
          return data
        }
      } catch (error) {
        const arr = []
        return arr
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl?.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    async cancelEditing() {
      this.canvasContainer.innerHTML = "";
      this.showCanvasDialog = false;
      this.tempStroke = [];
      this.strokes = [];
      this.showAttachment = false
    },
    async checkAssignment(checkedFile) {
      this.tempAssignmentUser.files.forEach((file) => {
        if (file.submittedFile.url === this.tempFile) {
          file.checkedFile = checkedFile[0];
        }
      });
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.tempAssignmentUser.uId,
          assignmentId: this.prop_assignment.assignmentId,
          attemptNumber: this.tempAssignmentUser.attemptNumber,
          linksOfAttachments: this.tempAssignmentUser.files || []
        };
        await this.assignmentUserRepositoryInstance.checkAssignment(objToPush);
        this.fetchData();
      } catch (err) {
        console.error(err);
        showStatus("Error updating marks", 1000, "error", this);
        this.fetchData();
      }
    },
    goBack() {
      this.$router.push({
        name: 'activityManageAssignments',
        params: {
          prop_department: this.prop_department,
          prop_course: this.prop_course,
          prop_selectedSubject: this.prop_selectedSubject,
          prop_typeDivGrpBatch: this.prop_typeDivGrpBatch,
          prop_selectedDivision: this.prop_selectedDivision,
          prop_selectedBatch: this.prop_selectedBatch,
          prop_selectedGroup: this.prop_selectedGroup,
          prop_selectedSemester: this.prop_selectedSemester,
          prop_selectedFaculty: this.prop_selectedFaculty
        }
      })
    },
    goToOldMyExam() {
      if (this.examType === 'Theory') {
        this.$router.push({
          name: 'activityMyExam'
          // params: {
          //   // prop_department: this.prop_department,
          //   // prop_course: this.prop_course,
          //   // prop_selectedSubject: this.prop_selectedSubject,
          //   // prop_typeDivGrpBatch: this.prop_typeDivGrpBatch,
          //   // prop_selectedDivision: this.prop_selectedDivision,
          //   // prop_selectedBatch: this.prop_selectedBatch,
          //   // prop_selectedGroup: this.prop_selectedGroup
          // }
        })
      }
    }
  }
};
</script>

<style src="./activityCreateAssignments.css"></style>

