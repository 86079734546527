<template src='./activityTopicFeedbackAnalysis.html'>

</template>

<script>
import inputContainer from '../../Components/inputContainer'
// import showStatus from '../../NetworkManager/showStatus'
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'

import { convertDate } from '../../Services/Utils/DateTimeFormatter'

import SemesterRepository from '../../Repository/Semester'
import SubjectRepository from '../../Repository/Subject'
import TopicRepository from '../../Repository/Topic'
import TopicFeedbackRepository from '../../Repository/TopicFeedback'
import TopicFeedbackActionRepository from '../../Repository/TopicFeedbackAction'
import TopicFeedbackTemplateRepository from '../../Repository/TopicFeedbackTemplate'
import TopicFeedbackActionTemplateRepository from '../../Repository/TopicFeedbackActionTemplate'

Vue.use(VueApexCharts)

export default {
  name: 'activityTopicFeedbackAnalysis',
  components: {
    apexcharts: VueApexCharts,
    inputContainer
  },
  data () {
    return {
      assignedCourseYearForSubAdmin: [],
      assignedDepartment: [],
      userData: {},
      selectedInstitute: '',
      selectedSemester: {},
      selectedSemesterName: '',
      semesterNamesList: [],
      loading: false,
      tree: {},
      departments: [],
      courseYears: [],
      divisions: [],
      selectedDepartment: '',
      selectedCourseYear: '',
      selectedSubjectName: '',
      selectedSubject: {},
      selectedDivision: '',
      semestersList: [],
      semesters: [],
      subjects: [],
      subjectNames: [],
      topics: [],
      topicFeedbacks: [],
      topicFeedbackTemplates: [],
      topicFeedbackActions: [],
      topicFeedbackActionTemplates: [],
      selectedTopicFeedback: '',
      selectedTopicFeedbackAction: '',
      showTopicFeedbackAnalysisDialog: false,
      showTopicFeedbackActionAnalysisDialog: false,
      headersForTopicFeedbacks: [
        {
          text: 'Feedback Name',
          value: 'topicFeedbackName'
        },
        {
          text: 'Created on',
          value: 'dateOfCreation'
        },
        {
          text: 'Filled By',
          value: 'filledBy'
        },
        {
          text: 'Actions',
          value: 'action'
        }
      ],
      barOptions: {
        series: [{
          name: 'Analysis',
          data: []
        }],
        chart: {
          type: 'bar',
          height: 257
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'rounded',
            columnWidth: '70%',
            colors: {
              ranges: [{
                from: 0,
                to: 74,
                color: '#ff0000'
              },
              {
                from: 75,
                to: 89,
                color: '#050992'
              },
              {
                from: 90,
                to: 100,
                color: '#11bc7d'
              }],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: []
        }
      }
    }
  },
  created () {
    this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment'].accessibleDepartment
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.assignedCourseYearForSubAdmin = this.$store.getters['liveData/get_accessibleCourseYearForSubAdmin']?.accessibleCourseYearForSubAdmin || []
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.topicRepositoryInstance = new TopicRepository(this)
    this.topicFeedbackRepositoryInstance = new TopicFeedbackRepository(this)
    this.topicFeedbackTemplatesRepositoryInstance = new TopicFeedbackTemplateRepository(this)
    this.topicFeedbackActionRepositoryInstance = new TopicFeedbackActionRepository(this)
    this.topicFeedbackActionTemplatesRepositoryInstance = new TopicFeedbackActionTemplateRepository(this)
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.loading = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(objToPush)
        this.semesters.reverse()
        this.semesterNamesList = this.semesters.map((semester) => semester.semName)
        console.log(this.semestersList)

        if (this.semesterNamesList.length > 0) {
          this.selectedSemesterName = this.semesterNamesList[0]
        }
      } catch (err) {
        console.log(err)
        this.semesters = []
        this.semesterNamesList = []
      }

      try {
        this.topicFeedbackTemplates = await this.topicFeedbackTemplatesRepositoryInstance.getTopicFeedbackTemplatesOfAnInstitute({
          instituteId: this.selectedInstitute
        })
      } catch (err) {
        this.topicFeedbackTemplates = []
        console.log(err)
      }

      try {
        this.topicFeedbackActionTemplates = await this.topicFeedbackActionTemplatesRepositoryInstance.getTopicFeedbackActionTemplatesOfAnInstitute({
          instituteId: this.selectedInstitute
        })
      } catch (err) {
        this.topicFeedbackActionTemplates = []
        console.log(err)
      }
      console.log(this.topicFeedbackTemplates)
      if (this.semesterNamesList.length > 0) {
        this.selectSemester(this.semesterNamesList[0])
      }
    },
    selectSemester () {
      this.selectedSemester = this.getSemesterObjectOfSelectedSemester(this.selectedSemesterName)
      this.setupDataForActivity()
    },
    getSemesterObjectOfSelectedSemester (semName) {
      const index = this.semesterNamesList.indexOf(semName)
      return this.semesters[index]
    },
    async setupDataForActivity () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        }
        this.semestersList = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        this.constructAcademicDataTree()
      } catch (err) {
        console.log(err)
      }
    },
    async constructAcademicDataTree () {
      this.tree = {}

      this.semestersList.forEach((sem) => {
        if (!this.tree[sem.department]) {
          this.tree[sem.department] = {}
        }
        if (!this.tree[sem.department][sem.courseYear]) {
          this.tree[sem.department][sem.courseYear] = []
        }
        this.tree[sem.department][sem.courseYear].push(sem.division)
      })

      // let idForDisplay = 0
      this.departments = []
      this.courseYears = []
      this.divisions = []

      Object.keys(this.tree).forEach((department, index) => {
        this.departments.push({
          name: department,
          department: department,
          id: index
        })
        let subAdminAssignedDepartmentWiseLecture = []
      if(this.assignedDepartment.length > 0) {
        this.departments.map((data) => {
          if(this.assignedDepartment.includes(data.name)){
            subAdminAssignedDepartmentWiseLecture.push(data)
          }
        })
        this.departments = subAdminAssignedDepartmentWiseLecture
        }

        // const departmentObject = {
        //   id: idForDisplay,
        //   name: department,
        //   children: []
        // }

        // const courseYearsOfADepartment = {
        //   id: idForDisplay,
        //   name: department,
        //   department: department,
        //   children: []
        // }

        // idForDisplay++

        // Object.keys(this.tree[department]).forEach((courseYear) => {
        //   const divisionsOfACourseYear = {
        //     id: idForDisplay,
        //     name: courseYear,
        //     children: []
        //   }

        //   const courseYearObject = {
        //     id: idForDisplay,
        //     name: courseYear,
        //     department: department,
        //     courseYear: courseYear
        //   }

        //   idForDisplay++

        //   this.tree[department][courseYear].forEach((division) => {
        //     // idForDisplay++

        //     divisionsOfACourseYear.children.push({
        //       id: idForDisplay++,
        //       department: department,
        //       courseYear: courseYear,
        //       name: division
        //     })
        //   })

        //   departmentObject.children.push(divisionsOfACourseYear)
        //   courseYearsOfADepartment.children.push(courseYearObject)
        // })

        // this.divisions.push(departmentObject)
        // this.courseYears.push(courseYearsOfADepartment)
      })

      console.log(this.divisions, this.courseYears, this.departments)
      this.loading = false
      this.departments = this.departments.map((child) => child.name)
    },
    selectDepartment () {
      this.loading = true
      this.courseYears = []
      this.divisions = []
      this.selectedCourseYear = ''
      this.selectedDivision = ''
      this.courseYears = Object.keys(this.tree[this.selectedDepartment])
      let allowedCourseYearForSelectedDepartment = []
      if (this.assignedCourseYearForSubAdmin.length > 0) {
        this.assignedCourseYearForSubAdmin.map((cy) => {
          let [course, department1] = cy.split('_')
          if (department1 === this.selectedDepartment) {
            allowedCourseYearForSelectedDepartment.push(course)
          }
        })
        this.courseYears = allowedCourseYearForSelectedDepartment
      }
      this.refresh = false
      this.loading = false
    },
    selectCourseYear () {
      this.loading = true
      this.divisions = []
      this.selectedDivision = ''
      this.divisions = this.tree[this.selectedDepartment][this.selectedCourseYear]
      this.refresh = false
      this.loading = false
      this.getSubjectsForACourseYear()
    },
    async getSubjectsForACourseYear () {
      this.loading = true
      this.subjects = []
      try {
        this.subjects = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear
        })
        this.subjectNames = this.subjects.map((elem) => elem.subjectName)
      } catch (err) {
        console.log(err)
      }
      this.loading = false
      this.refresh = false
    },
    selectSubject () {
      this.loading = true
      this.selectedSubject = this.getSubjectFromSubjectName(this.selectedSubjectName)
      this.divisions = []
      this.divisions = this.tree[this.selectedDepartment][this.selectedCourseYear]
      this.refresh = false
      this.loading = false
    },
    getSubjectFromSubjectName (subjectName) {
      return this.subjects.find((elem) => elem.subjectName === subjectName)
    },
    async selectDivision () {
      this.loading = true
      this.refresh = false

      this.topics = []
      this.topicFeedbacks = []
      this.topicFeedbackActions = []
      // this.topicFeedbackTemplates = []
      // this.topicFeedbackActionTemplates = []

      try {
        this.topics = await this.topicRepositoryInstance.getTopicsOfADivisionOfASubject({
          ...this.selectedSubject,
          division: this.selectedDivision
        })
      } catch (err) {
        console.log(err)
      }

      try {
        this.topicFeedbacks = await this.topicFeedbackRepositoryInstance.getTopicFeedbacksForADivisionForASubject({
          ...this.selectedSubject,
          division: this.selectedDivision
        })
      } catch (err) {
        this.topicFeedbacks = []
        console.log(err)
      }

      for (let i = 0; i < this.topicFeedbacks.length; i++) {
        this.topicFeedbacks[i].topics = this.topicFeedbacks[i].topicIds
          .map((topicId) => this.topics.find((elem) => elem.topicId === topicId))
          .filter((elem) => elem)

        const topicFeedbackTemplate = this.topicFeedbackTemplates.find((elem) => elem.topicFeedbackTemplateId === this.topicFeedbacks[i].topicFeedbackTemplateId)

        const stats = {}
        console.log(this.topicFeedbackTemplates, this.topicFeedbacks[i].topicFeedbackTemplateId)
        if (topicFeedbackTemplate) {
          // this.topicFeedbacks[i].responses = this.topicFeedbacks[i].responses.map((response) => {
          //   response.question = topicFeedbackTemplate.questions.find((elem) => elem.questionId === response.questionId).question
          //   return response
          // })
          topicFeedbackTemplate.questions.forEach((questionObject, index) => {
            stats[questionObject.questionId] = {
              question: questionObject.question,
              options: {},
              barchartid: 'barchart' + index
            }
            questionObject.options.forEach((option) => {
              stats[questionObject.questionId].options[option] = 0
            })
          })
          Object.values(this.topicFeedbacks[i].responses).forEach((response) => {
            Object.keys(response).forEach((questionId) => {
              stats[questionId].options[response[questionId]]++
            })
          })
        }
        console.log(stats)
        this.topicFeedbacks[i].stats = Object.values(stats)
        this.topicFeedbacks[i].actions = await this.getActionsForATopicFeedback(this.topicFeedbacks[i])
      }
      this.loading = false
    },
    async getActionsForATopicFeedback (item) {
      let topicFeedbackActions = []
      try {
        topicFeedbackActions = await this.topicFeedbackActionRepositoryInstance.getTopicFeedbackActionsOfATopicFeedback(item)
      } catch (err) {
        console.log(err)
        topicFeedbackActions = []
      }

      for (let i = 0; i < topicFeedbackActions.length; i++) {
        const topicFeedbackActionTemplate = this.topicFeedbackActionTemplates.find((elem) => elem.topicFeedbackActionTemplateId === topicFeedbackActions[i].topicFeedbackActionTemplateId)
        if (topicFeedbackActionTemplate) {
          topicFeedbackActions[i].responses = topicFeedbackActions[i].responses.map((response) => {
            response.action = topicFeedbackActionTemplate.actions.find((elem) => elem.actionId === response.actionId).action
            return response
          })
        }
      }
      return topicFeedbackActions
    },
    openTopicFeedbackAnalysisDialog (item) {
      this.selectedTopicFeedback = item
      console.log(this.selectedTopicFeedback)
      for (let i = 0; i < this.selectedTopicFeedback.stats.length; i++) {
        const categories = Object.keys(this.selectedTopicFeedback.stats[i].options)
        const chartOptions = {
          chart: {
            id: 'Question ' + (i + 1)
          },
          xaxis: {
            categories: categories
          }
        }
        const series = [{
          name: 'Options',
          data: Object.values(this.selectedTopicFeedback.stats[i].options)
        }]
        // for (let j = 0; j < this.barOptions.xaxis.categories.length; j++) {
        //   series[0].data[j] = Object.values(this.selectedTopicFeedback.stats[i].options)
        // }
        console.log(chartOptions, series)
        this.selectedTopicFeedback.stats[i].chartOptions = chartOptions
        this.selectedTopicFeedback.stats[i].series = series
      }
      this.showTopicFeedbackAnalysisDialog = true
    },
    closeTopicFeedbackAnalysisDialog () {
      this.showTopicFeedbackAnalysisDialog = false
      this.selectedTopicFeedback = ''
    },
    openTopicFeedbackActionAnalysisDialog (item) {
      this.selectedTopicFeedback = item
      for (let i = 0; i < this.selectedTopicFeedback.actions.length; i++) {
        const categories = this.selectedTopicFeedback.actions[i].responses.map((response) => response.action)
        const chartOptions = {
          chart: {
            id: 'Action ' + (i + 1)
          },
          xaxis: {
            categories: categories
          }
        }
        const series = [{
          name: 'Options',
          data: this.selectedTopicFeedback.actions[i].responses.map((response) => response.uIds.length)
        }]
        // for (let j = 0; j < this.barOptions.xaxis.categories.length; j++) {
        //   series[0].data[j] = Object.values(this.selectedTopicFeedback.stats[i].options)
        // }
        console.log(chartOptions, series)
        this.selectedTopicFeedback.actions[i].chartOptions = chartOptions
        this.selectedTopicFeedback.actions[i].series = series
      }
      this.showTopicFeedbackActionAnalysisDialog = true
    },
    closeTopicFeedbackActionAnalysisDialog () {
      this.showTopicFeedbackActionAnalysisDialog = false
      this.selectedTopicFeedback = ''
    },
    convertDate (item) {
      return convertDate(item)
    }
  }
}
</script>

<style src='./activityTopicFeedbackAnalysis.css'>

</style>
