<template src="./examSubjectDetails.html"></template>
<script>
import SubjectRepository from "../../../Repository/Subject";
import NewExamRepository from "../../../Repository/NewExam";
import ExamSubjectAndQuestionRepository from "../../../Repository/ExamSubjectAndQuestion";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import UserRepository from "../../../Repository/User";
import showStatus from "../../../NetworkManager/showStatus";
import {
  createExcelThroughJsonForMultipleSheets,
  generateJsonFromExcel,
  generateFromJson,
  createExcelThroughJson,
  createExcelThroughJsonAndSchema,
} from "../../../utils/excel";
import { sub } from "date-fns";
export default {
  name: "examSubjectDetails",
  data() {
    return {
      progressIndicator1: 0,
      loading: false,
      examName: "",
      backgroundColor: "#E7E7FFCF",
      rules: {
        required: (value) => !!value || "This field is required",
        totalMarks: (value) =>
          /^\d*$/.test(value) || "Only numbers are allowed.",
        passingMarks: (value) =>
          /^\d*$/.test(value) || "Only numbers are allowed.",
      },
      search: '',
      studentsSearch: '',
      facultySearch: '',
      accessDialogue: false,
      subjectsArray: [],
      headers: [
        { text: 'Sr No.', value: 'srNo' },
        { text: 'Subject code', value: 'subjectCode' },
        { text: 'Subject name', value: 'subjectName' },
        { text: 'Allocated Students', value: 'allocatedStudents' },
        { text: 'Access Control', value: 'accessControl' },
        { text: 'Exam date', value: 'examDate', width: '150px' },
        { text: 'Time', value: 'examTime', width: '120px' },
        { text: 'Duration(in mins)', value: 'examDuration' },
        { text: 'Total marks', value: 'totalMarks', sortable: false },
        { text: 'Passing marks', value: 'passingMarks', sortable: false },
        { text: 'Threshold %', value: 'threshold', sortable: false }
      ],
      facultyAccessArray: [],
      applyAccessToAllBasicDetails: false,
      applyAccessToAllSubjectDetails: false,
      applyAccessToAllExamForm: false,
      applyAccessToAllHallTicket: false,
      applyAccessToAllInvigilation: false,
      applyAccessToAllControlAccess: false,
      applyAccessToAllQuestionsAnswers: false,
      applyAccessToAllStudentMarks: false,
      accessControlHeaders: [
      { text: 'Faculty name', value: 'fullName', sortable: false, width: '10%'},
        { text: 'Basic details', value: 'basicDetails', sortable: false, width: '10%' },
        { text: 'Subject details', value: 'subjectDetails', sortable: false, width: '10%' },
        { text: 'Exam form', value: 'examForm', sortable: false, width: '10%' },
        { text: 'Hall ticket', value: 'hallTicket', width: '120px', sortable: false, width: '10%' },
        { text: 'Invigilation', value: 'invigilation', width: '120px', sortable: false, width: '10%' },
        { text: 'Control access', value: 'controlAccess', sortable: false, width: '10%' },
        { text: 'Questions & Answers', value: 'questionsAnswers', sortable: false, width: '10%' },
        { text: 'Student marks', value: 'studentMarks', sortable: false, width: '10%' }
      ],
      studentDialogHeaders: [
        { text: "Selected", value: "selected", sortable: false },
        { text: "Roll No.", value: "rollNumber" },
        { text: "Prn", value: "prnNumber" },
        { text: "Exam Seat Number", value: "examSeatNumber" },
        { text: "Student Name  ", value: "studentName" },
      ],
      itemsArray: [
        {
          subjectCode: "123",
          subjectName: "Maths",
          passingMarks: 2,
          examDate: null,
          examTime: null,
          // 'id1' : v4()
        },
        {
          subjectCode: "1234",
          subjectName: "Bios",
          examDate: null,
          examTime: null,
        },
        {
          subjectCode: "124",
          subjectName: "Os",
          examDate: null,
          examTime: null,
        },
        ],
        examDateModel: false,
        examDate:null,
        calenderIcon: 'mdi-calendar-month-outline',
        menu : false,
        selectedDepartment:'',
        selectedCourseYear:'',
        selectedInstitute:'',
        selectedSemester:null,
        subjects:[],
        selectedSubjects: [],
        examId: null,
        subjectsArray : [],
        studentDialog: false,
        courseYearStudents:[],
        userDetails : [],
        studentsTableData : [],
        studentRadio: 'option1',
        filteredStudents:[],
        subjectAllocatedStudents : null,
        subejctToEdit: {},
        exam : {},
        examTabDependecies : {},
        previousItemState:[],
        selectAllStudents:false,
        selectedStudentsCount: null,
        subjectAccess: {},
        subjectsAndQuestionsOfExam: [],
        uploadSubjectDetailsViaExcel: false,
      // uploadSubjectDetailsExcel,
        subjectDetailsExcelFile: null,
        schema:{},
        selectAll: false,
        selectAllTotalMarks: false,
        selectAllThreshold: false,
        selectAllPassingMarks: false
    }
  },
  computed: {
    filteredStudentsByRadio() {
      switch (this.studentRadio) {
        case "option1":
          return this.studentsTableData; // All Students for Course Mapped
        case "option2":
          return this.studentsTableData.filter((student) => student.isSelected); // Show Selected Students for Existing Exam
        case "option3":
          return this.studentsTableData.filter(
            (student) => !student.isSelected
          ); // Show not Selected Students for Existing Exam
        default:
          return this.studentsTableData;
      }
    },
    computedSelectedStudents() {
      return (item) =>
        item.allocatedStudent.filter((stud) => stud.isSelected === true);
    },
  },
  mounted() {
  },
  activated() {
    // console.log("activated")
    let newValue = this.$store.state.liveData.examTabDependencies;
    let flag = false;
    Object.entries(newValue).forEach(([key, value]) => {
      if (value["subjectDetailsTab"] === true) {
        flag = true;
        value["subjectDetailsTab"] = false;
      }
    });
    // console.log("here man", this.$store.state.liveData.examTabDependencies, flag);
    if (flag === true) {
      this.initialize();
    }
  },
  watch: {
    studentRadio() {
      this.filteredStudents = this.filteredStudentsByRadio;
    },
  },
  async created() {
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.newExamRepositoryInstance = new NewExamRepository(this);
    this.examSubjectAndQuestionRepositoryInstance =
      new ExamSubjectAndQuestionRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.selectedDepartment =
      this.$store.getters["liveData/get_examDepartment"];
    this.selectedCourseYear =
      this.$store.getters["liveData/get_examCourseYear"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.examId = this.$store.getters["liveData/get_examId"];
    await this.initialize();
    this.examTabDependecies =
      this.$store.getters["liveData/get_examTabDependencies"];
  },
  methods: {
    applyAllTotalMarks() {
      this.selectedSubjects.forEach((subject) => {
        this.$set(subject, "totalMarks", this.selectedSubjects[0].totalMarks);
      })
    },
    applyAllPassingMarks() {
      this.selectedSubjects.forEach((subject) => {
        this.$set(subject, "passingMarks", this.selectedSubjects[0].passingMarks);
      })
    },
    applyAllThreshold() {
      this.selectedSubjects.forEach((subject) => {
        this.$set(subject, "threshold", this.selectedSubjects[0].threshold);
      })
    },
    selectAllItems() {
      // Toggle the selection of all items based on the selectAll value
      this.filteredStudents.forEach(item => {
        item.isSelected = this.selectAllStudents;
      });
    },
    async initialize() {
      await this.getStudentsOfACourseYear();
      let studentIds = this.courseYearStudents?.map((student) => student.uId);
      await this.getUsersDetails(studentIds);
      await this.fetchSubjects();
      await this.getExam();
      await this.getSubjectData();
      await this.prepareSubjectDetailsTable("fromDatabase");
      this.calculateProgressIndicator();
    },
    allowedDates: (val) => parseInt(val.split("-")[2], 10) % 2 === 0,
    async getStudentsOfACourseYear() {
      try {
        this.courseYearStudents =
          await this.semesterUserRepositoryInstance.getStudentsOfACourseYear({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
          });
      } catch (error) {
        this.courseYearStudents = [];
      }
    },
    async fetchSubjects() {
      const allSubjects = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester({
        instituteId: this.selectedInstitute,
        semId:  this.selectedSemester.semId,
        department:this.selectedDepartment,
        courseYear:this.selectedCourseYear,
      })
      this.subjectsArray = allSubjects
      this.subjects = allSubjects?.map((s) => {
        const { subjectCode, subjectName, subjectId } = s;
        return { subjectCode, subjectName, subjectId };
      });
    },
    async getExam() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          examId: this.examId,
        };
        let res = await this.newExamRepositoryInstance.getExam(objToPush);
        this.exam = res.exam;
        let thresholdFound = false
        this.headers.forEach((header) => {
          if(header.text === "Threshold %") {
            thresholdFound = true
          }
        })
        if (!thresholdFound){
          if(this.exam.settings.isForAttainment === true) {
            this.headers.push({ text: "Threshold %", value: "threshold" })
          }
        }
        // console.log("exam", this.exam);
      } catch (error) {
        console.error("error", error);
      }
    },
    async getSubjectData(){
      let response = await this.examSubjectAndQuestionRepositoryInstance.getExamMultipleSubjectQuestions({
        instituteId: this.selectedInstitute,
        examId: this.examId,
        subjectIds: this.exam.subjectIds
      })
      this.subjectsAndQuestionsOfExam = response.result
      let subjectsData = response.result
      let temp = this.subjects?.map((subject)=>{

          let dataFound  = subjectsData.find((d)=>d.subjectId === subject.subjectId)
          let studentIds = this.courseYearStudents?.map(student => student.uId)

          let toReturn = {};
          if (dataFound !== undefined) {
            toReturn = {
              subjectCode: subject.subjectCode,
              subjectId: subject.subjectId,
              subjectName: subject.subjectName,
              examDuration: dataFound.examDuration,
              threshold: dataFound.threshold,
              passingMarks: dataFound.passingMarks,
              totalMarks: dataFound.totalMarks,
              examDate: dataFound.examDate,
              examTime: dataFound.examTime,
              allocatedStudentIds: dataFound.allocatedStudents,
              // allocatedStudents : dataFound.allocatedStudents,
            };
          } else {
            // toReturn = {
            //   subjectCode : subject.subjectCode,
            //   subjectId : subject.subjectId,
            //   subjectName : subject.subjectName,
            //   allocatedStudentIds : studentIds,
            // }
          }
          return toReturn;
        })
        .filter((item) => item?.subjectId);
      this.selectedSubjects = temp;
      // temp = temp.filter((item) => item);
      // this.
      // this.selectedSubjects =  this.subjects.filter((subject) => this.exam.subjectIds.includes(subject.subjectId))
      // this.selectedSubject.map((subject) => {

      // })
      // this.createdQuestions = assignmentQuestions.result
    },
    async saveSubjectIds() {
      let selectedSubjectsIds = this.selectedSubjects?.map((s) => s?.subjectId);
      try {
        let response = await this.newExamRepositoryInstance.createExam({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          examId: this.examId,
          subjectIds: selectedSubjectsIds,
          courseYear: this.selectedCourseYear,
        });
        this.exam = response.exam;
        if (response.status.status === 200) {
          await this.saveSubjectDetails();
          // showStatus("Successfully saved basic details of the exam.", 2000, 'success', this);
          // let response = this.examSubjectAndQuestionRepository.createExamSubjectQuestions({
          //   instituteId: this.selectedInstitute,
          //   semId:  this.selectedSemester.semId,
          //   examId: this.examId,
          // })
        } else {
          showStatus("Something went wrong", 2000, "error", this);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async saveSubjectDetails() {
      let marksPresent = true
      let invalidPassingMarks = false
      this.selectedSubjects.forEach((subject) => {
          if (!subject.totalMarks || !subject.passingMarks) {
              marksPresent = false;
          }
          
          if ((subject?.totalMarks - '') < (subject?.passingMarks - '')) {
              invalidPassingMarks = true;
          }
      });
      if (marksPresent) {
        if (!invalidPassingMarks) {
        let subjectsToUpdate = this.selectedSubjects?.map((s) => {
          const { subjectCode, subjectName, subjectId } = s;
          const examDuration =
            s.examDuration != null ? Number(s.examDuration) : null;
          const passingMarks =
            s.passingMarks != null ? Number(s.passingMarks) : null;
          const totalMarks = s.totalMarks != null ? Number(s.totalMarks) : null;
          const threshold = s.threshold != null ? Number(s.threshold) : null;
          const examId = this.examId;
          const instituteId = this.selectedInstitute;
          const examDate = s.examDate;
          const examTime = s.examTime;
          const allocatedStudents = s.allocatedStudent
            ?.filter((s) => s.isSelected === true)
            .map((s) => s.uId);
          // const allocatedStudents = s.allocatedStudents.filter()
          return {
            instituteId,
            examId,
            subjectId,
            subjectCode,
            subjectName,
            examDuration,
            passingMarks,
            totalMarks,
            threshold,
            examDate,
            examTime,
            allocatedStudents,
          };
        });
        try {
          let response =
            await this.examSubjectAndQuestionRepositoryInstance.bulkCreateExamSubjectQuestions(
              subjectsToUpdate
            );
          if (response.status.status === 200) {
            showStatus(
              "Successfully saved basic details of the exam.",
              2000,
              "success",
              this
            );
          }
        } catch (err) {
          console.error("error", err);
        }
        // showStatus("Something went wrong", 2000, 'error', this);
        // let response = this.examSubjectAndQuestionRepository.bulkCreateExamSubjectQuestions(subjectsToUpdate)
        // console.log("response", response)
        for (let key in this.examTabDependecies["subjectDetailsTab"]) {
          // this.examTabDependecies['tab3'][key] = Math.random();
          this.examTabDependecies["subjectDetailsTab"][key] = true;
        }
      } else {
        showStatus("Passing marks should be less than total marks subject", 3000, "error", this);
      }
    } else {
        showStatus("Total marks and passing marks are required for every subject", 3000, "error", this);
      }
    },
    async getUsersDetails(uIds) {
      try {
        const data = await this.userRepositoryInstance.getUsers({
        uIds: uIds,
      });
      this.userDetails = data;
      } catch (error) {
        console.error(error)
      }

    },
    calculateStudentCount(item) {
      let counter = 0;
      item.allocatedStudent?.forEach((student) => {
        if (student.isSelected === true) {
          counter++;
        }
      });
      this.$set(item, "allocatedStudentsCount", counter);
      // item.allocatedStudentsCount = counter
      // let temp = item

      // item = temp
    },
    async prepareSubjectDetailsTable(calledFrom) {
      this.selectedSubjects?.forEach((selectedSubject) => {
        let studentsForSubject = this.courseYearStudents?.filter((student) =>
          student.subjects.includes(selectedSubject?.subjectId)
        );
        let data = [];
        studentsForSubject?.forEach((student, index) => {
          let studentDetails = this.userDetails.find(
            (user) => user.uId === student.uId
          );
          // let isSelected = selectedSubject.allocatedStudentIds?.includes(student.uId)
          // console.log("isSelected", isSelected)
          // let isSelected = selectedSubject.allocatedStudentIds.includes(student.uId)
          // selectedSubject.isSelected
          // let isSelected = true
          // let isSelected = ;
          // console.log("selectedSubject",student,index,selectedSubject.allocatedStudent?.filter((item)=>item.uId ===student.uId))
          let isSelected = true;
          if (calledFrom === "fromDatabase") {
            isSelected = selectedSubject.allocatedStudentIds?.includes(
              student.uId
            );
          } else {
            if (selectedSubject.allocatedStudent) {
              // Check if the student is in the allocatedStudent array and get isSelected value
              let studentAllocation = selectedSubject.allocatedStudent?.find(
                (item) => item.uId === student.uId
              );
              isSelected = studentAllocation
                ? studentAllocation.isSelected
                : false;
            }
            // console.log(selectedSubject.allocatedStudent);
            // isSelected = selectedSubject.allocatedStudent !== undefined ?
            // console.log("here ", selectedSubject.allocatedStudent ?  selectedSubject?.allocatedStudent?.filter(selectedSubject => selectedSubject.uId === student.uId)[0]?.isSelected : true)
          }
          // if(selectedSubject.isSelected){
          //   isSelected = selectedSubject.isSelected
          // }
          data.push({
            uId: student.uId,
            rollNumber: student.rollNumber,
            prnNumber: studentDetails.collegePRNNo,
            examSeatNumber: studentDetails.examSeatNumber,
            studentName:
              studentDetails.firstName + " " + studentDetails.lastName,
            // isSelected: temp?.isSelected !== undefined ? temp?.isSelected : true
            // isSelected: selectedSubject.allocatedStudentIds ? selectedSubject.allocatedStudentIds.includes(student.uId) : isSelected
            isSelected: isSelected,
          });
          // console.log("data: ", data);
          selectedSubject.allocatedStudent = data;
        });
        if (this.selectedSubjects.length == this.subjects.length) {
          // console.log("first")
          this.selectAll = true;
        }
        this.calculateStudentCount(selectedSubject);
      });
    },
    toggleSelectAll() {
      this.selectAll = !this.selectAll;
      // console.log("this selected 0", this.selectAll);
      if (this.selectAll === true) {
        // Select all subjects
        // console.log("this selected 1", this.selectAll);
        this.selectedSubjects = this.subjects?.map((subject) => subject);
      } else {
        // Deselect all subjects
        // console.log("this selected 2", this.selectAll);
        this.selectedSubjects = [];
      }
      this.prepareSubjectDetailsTable1();
    },
    async prepareSubjectDetailsTable1() {
      // console.log("called from1 ");

      this.selectedSubjects.forEach((selectedSubject) => {
        let studentsForSubject = this.courseYearStudents?.filter((student) =>
          student.subjects.includes(selectedSubject?.subjectId)
        );
        let data = [];
        studentsForSubject.forEach((student, index) => {
          let studentDetails = this.userDetails.find(
            (user) => user.uId === student.uId
          );
          let temp = selectedSubject?.allocatedStudent?.filter(
            (selectedSubject) => selectedSubject.uId === student.uId
          )[0];
          let bool;
          if (temp === undefined) {
            bool = true;
          } else {
            bool = temp.isSelected;
          }
          // console.log("temp", temp);
          data.push({
            uId: student.uId,
            rollNumber: student.rollNumber,
            prnNumber: studentDetails.collegePRNNo,
            examSeatNumber: studentDetails.examSeatNumber,
            studentName:
              studentDetails.firstName + " " + studentDetails.lastName,
            // isSelected: temp?.isSelected !== undefined ? temp?.isSelected : true
            isSelected: bool,
          });
        });
        selectedSubject.allocatedStudent = [...data];
        this.calculateStudentCount(selectedSubject);
        this.calculateProgressIndicator();
      });
    },
    async prepareStudentsDialog(item) {
      this.studentDialog = true;
      this.selectedSubjectToEdit = item;
      this.studentsTableData = item.allocatedStudent;
      this.filteredStudents = this.studentsTableData;
      this.previousItemState = this.studentsTableData;
      this.selectedStudentsCount = this.studentsTableData.filter(
        (student) => student.isSelected
      ).length;
      // console.log("selectedStudentsCount", this.selectedStudentsCount);
    },
    async saveAllocatedStudents() {
      // item.allocatedStudent = data;
      this.selectedSubjects?.map((sub) => {
        if (sub?.subjectId === this.selectedSubjectToEdit?.subjectId) {
          // this.selectedSubjects
          sub.allocatedStudent = this.studentsTableData;
          this.calculateStudentCount(sub);
        }
        // console.log("sub", sub);
      });
      this.studentDialog = false;
    },
    cancelAllocatedStudets() {
      this.selectedSubjects?.map((sub) => {
        if (sub.subjectId === this.selectedSubjectToEdit?.subjectId) {
          // this.selectedSubjects
          // console.log("im am here to cancel all", this.previousItemState);
          this.filteredStudents = this.previousItemState;
        }
        this.calculateStudentCount(sub);
      });
      this.studentDialog = false;
    },
    calculateProgressIndicator() {
      // this.progressIndicator1 = (this.selectedSubjects.length) / this.examFormTableData.length) * 100;
      this.progressIndicator1 =
        (this.selectedSubjects.length / this.subjects.length) * 100;
    },
    goToNextPage() {
      this.$emit("nextButtonClick");
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    getSchema(){
      this.schema = {
        Sheet1: {
          "Subject Code": {
            validation: {
              type: "textLength",
              // required: true
            },
            variableName: "subjectCode",
          },
          "Subject Name": {
            validation: {
              type: "textLength",
              // required: true,
            },
            variableName: "subjectName",
          },
          "Exam Date": {
            validation: {
              type: "textLength",
              // required: true,
            },
            variableName: "examDate",
          },
          Time: {
            validation: {
              type: "textLength",
              // required: true,
            },
            variableName: "examTime",
          },
          Duration: {
            validation: {
              type: "textLength",
              // required: true,
            },
            variableName: "examDuration",
          },
          "Total marks": {
            validation: {
              type: "textLength",
              // required: true,
            },
            variableName: "totalMarks",
          },
          "Passing marks": {
            validation: {
              type: "textLength",
              // required: true,
            },
            variableName: "passingMarks",
          },
          Threshold: {
            validation: {
              type: "textLength",
              // required: true,
            },
            variableName: "threshold",
          },
        },
      };
    },
    async downloadSubjectDetailsSample() {
      let array = this.selectedSubjects.map((subject) => {
        return [
          subject.subjectCode,
          subject.subjectName,
          subject.examDate,
          subject.examTime,
          subject.examDuration,
          subject.totalMarks,
          subject.passingMarks,
          subject.threshold
      ];
      });
      this.getSchema()
      createExcelThroughJsonAndSchema(array, this.schema, "Subject Details");
    },
    async uploadSubjectDetailsExcel() {
      this.getSchema()
      const res = await generateJsonFromExcel(this.subjectDetailsExcelFile, this.schema, false);
      const {errors, data} = res;
      let see = data['Sheet1'].map(({examDate, ...rest})=>({
        examDate:  examDate !== undefined ? new Date(examDate).toISOString().split("T0")[0] : '',
        ...rest
      }))
      this.selectedSubjects = see
      this.uploadSubjectDetailsViaExcel = false
    },
    applyAllForBasicDetails() {
      if (this.applyAccessToAllBasicDetails === true) {

        let facultyDoc = this.facultyAccessArray[0]
        this.facultyAccessArray.map((info) => {
          this.$set(info, 'selectedAccessForBasicDetails', facultyDoc.selectedAccessForBasicDetails);
        })
      } else {
        this.facultyAccessArray.map((info, i) => {
          if (i !== 0) {

            info.selectedAccessForBasicDetails = ''
          }
        })
      }
    },
    applyAllForSubjectDetails() {
      if (this.applyAccessToAllSubjectDetails === true) {

        let facultyDoc = this.facultyAccessArray[0]
        this.facultyAccessArray?.map((info) => {
          this.$set(info, 'selectedAccessForSubjectDetails', facultyDoc.selectedAccessForSubjectDetails);
        })
      } else {
        this.facultyAccessArray?.map((info, i) => {
          if (i !== 0) {

            info.selectedAccessForSubjectDetails = ''
          }
        })
      }
    },
    applyAllForExamForm() {
      if (this.applyAccessToAllExamForm === true) {

        let facultyDoc = this.facultyAccessArray[0]
        this.facultyAccessArray?.map((info) => {
          this.$set(info, 'selectedAccessForExamForm', facultyDoc.selectedAccessForExamForm);
        })
      } else {
        this.facultyAccessArray.map((info, i) => {
          if (i !== 0) {

            info.selectedAccessForExamForm = ''
          }
        })
      }
    },
    applyAllForHallTicket() {
      if (this.applyAccessToAllHallTicket === true) {

        let facultyDoc = this.facultyAccessArray[0]
        this.facultyAccessArray?.map((info) => {
          this.$set(info, 'selectedAccessForHallTicket', facultyDoc.selectedAccessForHallTicket);
        })
      } else {
        this.facultyAccessArray.map((info, i) => {
          if (i !== 0) {

            info.selectedAccessForHallTicket = ''
          }
        })
      }
    },
    applyAllForInvigilation() {
      if (this.applyAccessToAllInvigilation === true) {

        let facultyDoc = this.facultyAccessArray[0]
        this.facultyAccessArray.map((info) => {
          this.$set(info, 'selectedAccessForInvigilation', facultyDoc.selectedAccessForInvigilation);
        })
      } else {
        this.facultyAccessArray.map((info, i) => {
          if (i !== 0) {

            info.selectedAccessForInvigilation = ''
          }
        })
      }
    },
    applyAllForControlAccess() {
      if (this.applyAccessToAllControlAccess === true) {

        let facultyDoc = this.facultyAccessArray[0]
        this.facultyAccessArray?.map((info) => {
          this.$set(info, 'selectedAccessForControlAccess', facultyDoc.selectedAccessForControlAccess);
        })
      } else {
        this.facultyAccessArray.map((info, i) => {
          if (i !== 0) {

            info.selectedAccessForControlAccess = ''
          }
        })
      }
    },
    applyAllForQuestionsAnswers() {
      if (this.applyAccessToAllQuestionsAnswers === true) {

        let facultyDoc = this.facultyAccessArray[0]
        this.facultyAccessArray?.map((info) => {
          this.$set(info, 'selectedAccessForQuestionsAnswers', facultyDoc.selectedAccessForQuestionsAnswers);
        })
      } else {
        this.facultyAccessArray?.map((info, i) => {
          if (i !== 0) {

            info.selectedAccessForQuestionsAnswers = ''
          }
        })
      }
    },
    applyAllForStudentMarks() {
      if (this.applyAccessToAllStudentMarks === true) {

        let facultyDoc = this.facultyAccessArray[0]
        this.facultyAccessArray?.map((info) => {
          this.$set(info, 'selectedAccessForStudentMarks', facultyDoc.selectedAccessForStudentMarks);
        })
      } else {
        this.facultyAccessArray?.map((info, i) => {
          if (i !== 0) {

            info.selectedAccessForStudentMarks = ''
          }
        })
      }
    },
    async saveFacultyAccess() {
      let accessToFaculty = []
      this.facultyAccessArray?.forEach((faculty) => {
        let obj = {
          uId : faculty.uId,
          accessControlArray: []
        }
        if(faculty.selectedAccessForBasicDetails) {
          obj.accessControlArray.push({'basicDetails': faculty.selectedAccessForBasicDetails})
        }
        if(faculty.selectedAccessForSubjectDetails) {
          obj.accessControlArray.push({'subjectDetails': faculty.selectedAccessForSubjectDetails})
        }
        if(faculty.selectedAccessForExamForm) {
          obj.accessControlArray.push({'examForm': faculty.selectedAccessForExamForm})
        }
        if(faculty.selectedAccessForHallTicket) {
          obj.accessControlArray.push({'hallTicket': faculty.selectedAccessForHallTicket})
        }
        if(faculty.selectedAccessForInvigilation) {
          obj.accessControlArray.push({'invigilation': faculty.selectedAccessForInvigilation})
        }
        if(faculty.selectedAccessForControlAccess) {
          obj.accessControlArray.push({'controlAccess': faculty.selectedAccessForControlAccess})
        }
        if(faculty.selectedAccessForQuestionsAnswers) {
          obj.accessControlArray.push({'questionsAnswers': faculty.selectedAccessForQuestionsAnswers})
        }
        if(faculty.selectedAccessForStudentMarks) {
          obj.accessControlArray.push({'studentMarks': faculty.selectedAccessForStudentMarks})
        }
        accessToFaculty.push(obj)
      })
      let subjectForAccessControl = this.subjectsAndQuestionsOfExam.find((examSubject) => examSubject.subjectId === this.subjectAccess.subjectId)
      subjectForAccessControl.accessToFaculty = accessToFaculty
      try {
        let response = await this.examSubjectAndQuestionRepositoryInstance.bulkCreateExamSubjectQuestions([subjectForAccessControl])
          if(response.status.status === 200){
            showStatus("Successfully saved basic details of the exam.", 2000, 'success', this);
          }
        }catch(err){
          console.error("error",err)
        }
    },
    async subjectWiseAccess() {
      this.facultyAccessArray = []
      let subjectForAccessControl = this.subjectsArray.find((subject) => subject.subjectId === this.subjectAccess.subjectId)
      this.subjectAccess = subjectForAccessControl
      this.facultyAccessArray = await this.userRepositoryInstance.getFullNameOfUsers({
            uIds: this.subjectAccess.faculties,
          });
          this.facultyAccessArray.forEach(faculty => {
            faculty.basicDetails = ['View', 'Edit']
            faculty.subjectDetails = ['View', 'Edit']
            faculty.examForm = ['View', 'Edit']
            faculty.hallTicket = ['View', 'Edit']
            faculty.invigilation = ['View', 'Edit']
            faculty.controlAccess = ['View', 'Edit']
            faculty.questionsAnswers = ['View', 'Edit']
            faculty.studentMarks = ['View', 'Edit']
          })
          for (const subject of this.subjectsAndQuestionsOfExam) {
            for (const subjectFaculty of subject.accessToFaculty) {
                const faculty = this.facultyAccessArray.find(faculty => faculty.uId === subjectFaculty.uId);
                if (faculty) {
                    for (const accessControl of subjectFaculty.accessControlArray) {
                        const facultyAccess = faculty;
                        const keys = Object.keys(accessControl);
                        keys.forEach(key => {
                            if (accessControl[key]) facultyAccess[`selectedAccessFor${key.charAt(0).toUpperCase() + key.slice(1)}`] = accessControl[key];
                        });
                    }
                }
            }
        }
    },
  },
};
</script>

<style src="./examSubjectDetails.css"></style>