<template src='./activityCumulativeAttendance.html'></template>
<script>
import bar from "../../Components/AttendanceAnalysisBarGraph";
import pie from "../../Components/AttendanceAnalysisPieChart";
import LectureRepository from "../../Repository/Lecture";
import UserRepository from "../../Repository/User";
// import showStatus from "../../../NetworkManager/showStatus";
import showStatus from "../../NetworkManager/showStatus";
import SemesterRepository from "../../Repository/Semester";
import inputContainer from "../../Components/inputContainer";
import SemesterUserRepository from "../../Repository/SemesterUser";
import SubjectRepository from "../../Repository/Subject";
import { subjectTypes } from "../../Constants/Utils/Statics";
import networkManager from "../../NetworkManager/index";
import axios from "axios";
import {
  convertDate,
  formatToSlashSepearated,
  timeStringConverter,
  convertFromYYYYMMDDToDate,
  convertUTCDateToLocalDate,
  convertTime,
  convertLocalToUTC,
} from "../../Services/Utils/DateTimeFormatter";
export default {
  name: "activityAttendanceAnalysis",
  data() {
    return {
      loading: false,
      assignedCourseYearForSubAdmin: [],
      assignedDepartment: [],
      isDypims: false,
      analysisData: [],
      groupNameForSub: "",
      allSubjectForCourseYear: [],
      courseYearListExcel: [],
      divisionListExcel: [],
      departmentsListExcel: [],
      subjectListExcel: [],
      allDivData: [],
      typeOfExcel: ["Subject Wise Report", "Division wise Report"],
      typeNameArr: ["Division", "Batch", "Group"],
      selectedBatch: null,
      selectedDepartment: "",
      selected: null,
      selectedGroup: null,
      selectedGroupOfASubject: null,
      subjectWithGroup: [],
      selectedSubjectForGroup: null,
      groupsOfSubject: [],
      groupNamesOfSubject: [],
      allSubjectForDepartment: [],
      selectedType: "",
      selectedSubjectExcel: "",
      selectedSemesterExcel: "",
      selectedDepartmentExcel: "",
      selectedCourseYearExcel: "",
      selectedDivisionExcel: "",
      selectedCourseYear: "",
      selectedDivision: "",
      selectedBatch: "",
      startDate: "",
      endDate: "",
      modal1: false,
      modal2: false,
      items: [],
      itemsBackup: [],
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Total",
          value: "total",
        },
      ],
      headersBackup: [],
      dataProccessed: false,
      subjectCodes: [],
      attendanceDistribution: {
        "0 % - 10 %": 0,
        "10 % - 20 %": 0,
        "20 % - 30 %": 0,
        "30 % - 40 %": 0,
        "40 % - 50 %": 0,
        "50 % - 60 %": 0,
        "60 % - 70 %": 0,
        "70 % - 80 %": 0,
        "80 % - 90 %": 0,
        "90 % - 100 %": 0,
      },
      attendanceDistributionBackup: {},
      selectedSemester: {},
      disable: false,
      defaulterLimit: 75,
      defaulterCount: 0,
      limits: [0, 100],
      tempLimit: 75,
      changeDefaulterLimit: false,
      selectedSubject: null,
      userData: null,
      departments: [],
      subjectsMapping: [],
      semestersList: [],
      courseYearList: [],
      divisionList: [],
      batchList: [],
      groupList: [],
      departmentsList: [],
      subjectTypes: subjectTypes,
      instituteName: "",
    };
  },
  components: {
    bar,
    pie,
    inputContainer,
  },
  created() {
    this.loading = true;
    this.$store.commit(
      "liveData/set_selectedActivityName",
      "Attendance Analysis"
    );
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.instituteName = this.$store.getters["instituteData/get_instituteName"];
    this.assignedDepartment =
      this.$store.getters[
        "liveData/get_accessibleDepartment"
      ].accessibleDepartment;
    if (this.selectedInstitute === "6a2b6228-14b8-4d0b-8044-b55fe74240b3") {
      this.isDypims = true;
    }
    this.assignedCourseYearForSubAdmin =
      this.$store.getters["liveData/get_accessibleCourseYearForSubAdmin"]
        ?.accessibleCourseYearForSubAdmin || [];
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.userData = this.$store.getters["user/get_userData"];
    this.lectureRepositoryInstance = new LectureRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.getSemesters();
  },
  methods: {
    async attendanceAnalysisForABatch() {
      try {
        const objToPush = {
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.selectedDivision,
          batch: this.selectedBatch,
        };
        if (this.startDate !== "" && this.endDate !== "") {
          const currentUTCDate = new Date(this.startDate);
          const currentEndUTCDate = new Date(this.endDate);

          currentUTCDate.setUTCHours(0, 0, 0, 0);
          currentEndUTCDate.setUTCHours(23, 59, 59, 999);

          const val = convertLocalToUTC(currentUTCDate);
          const val2 = convertLocalToUTC(currentEndUTCDate);

          objToPush.fromDate = val.toISOString();
          objToPush.toDate = val2.toISOString();
        }
        this.analysisData =
          await this.lectureRepositoryInstance.getAttendanceAnalysisForABatchOfADivision(
            Object.assign(objToPush, {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              // uId: this.userData.uId
            })
          );
          const temp = [];
      this.analysisData.map((data) => {
        temp.push(data.assignedMentor);
      });
      let mentorsNameWithUid = [];
      try {
        mentorsNameWithUid =
          await this.userRepositoryInstance.getFullNameOfUsers({
            uIds: temp,
          });
      } catch (error) {
        console.error(error);
      }

      // },
      // fetchAnalysis () {
      this.dataProccessed = false;
      this.disable = true;
      this.items = [];
      this.subjectCodes = [];
      this.limits = [0, 100];
      this.headers = [
        {
          text: "Roll No",
          value: "rollNumber",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Mentor Name",
          value: "mentorName",
        },
        {
          text: "Total",
          value: "total",
        },
      ];
      this.attendanceDistribution = {
        "0 % - 10 %": 0,
        "10 % - 20 %": 0,
        "20 % - 30 %": 0,
        "30 % - 40 %": 0,
        "40 % - 50 %": 0,
        "50 % - 60 %": 0,
        "60 % - 70 %": 0,
        "70 % - 80 %": 0,
        "80 % - 90 %": 0,
        "90 % - 100 %": 0,
      };
      this.defaulterCount = 0;
      for (let i = 0; i < this.analysisData.length; i++) {
        const obj = {};
        obj.uId = this.analysisData[i].uId;
        obj.mentorName = mentorsNameWithUid.filter(
          (data) => data.uId == this.analysisData[i].assignedMentor
        )[0]?.fullName;
        const user = this.analysisData[i];
        const name = await this.userRepositoryInstance.getFullNameOfUser({
          uId: user.uId,
        });
        obj.rollNumber = user.rollNumber;
        this.analysisData[i].name = name;
        obj.name = name;
        let totalP = 0;
        let totalT = 0;
        for (let j = 0; j < this.analysisData[i].subjects.length; j++) {
          try {
            if (
              this.subjectsMapping[this.analysisData[i].subjects[j].subjectId]
            ) {
              // Subject details already exist in subjectsMapping, no need to fetch again
            } else {
              this.subjectsMapping[this.analysisData[i].subjects[j].subjectId] =
                await this.subjectRepositoryInstance.getDetailsOfASubject({
                  instituteId: this.selectedInstitute,
                  semId: this.selectedSemester.semId,
                  subjectId: this.analysisData[i].subjects[j].subjectId,
                });
            }

            this.analysisData[i].subjects[j].subjectName =
              this.subjectsMapping[
                this.analysisData[i].subjects[j].subjectId
              ].subjectName;
            this.analysisData[i].subjects[j].subjectCode =
              this.subjectsMapping[
                this.analysisData[i].subjects[j].subjectId
              ].subjectCode;
            this.analysisData[i].subjects[j].subjectType =
              this.subjectTypes[
                this.subjectsMapping[
                  this.analysisData[i].subjects[j].subjectId
                ].subjectType
              ];

            const obj2 = {
              text:
                this.analysisData[i].subjects[j].subjectName +
                "_" +
                this.analysisData[i].subjects[j].subjectType,
              value:
                this.analysisData[i].subjects[j].subjectName +
                "_" +
                this.analysisData[i].subjects[j].subjectType,
            };

            if (
              this.subjectCodes.includes(
                this.analysisData[i].subjects[j].subjectName +
                  "_" +
                  this.analysisData[i].subjects[j].subjectType
              )
            ) {
            } else {
              this.headers.push(obj2);
              this.subjectCodes.push(
                this.analysisData[i].subjects[j].subjectName +
                  "_" +
                  this.analysisData[i].subjects[j].subjectType
              );
            }

            if (this.analysisData[i].subjects[j].totalConducted > 0) {
              obj[
                this.analysisData[i].subjects[j].subjectName +
                  "_" +
                  this.analysisData[i].subjects[j].subjectType
              ] =
                this.analysisData[i].subjects[j].totalAttended +
                " / " +
                this.analysisData[i].subjects[j].totalConducted;
            } else {
              obj[
                this.analysisData[i].subjects[j].subjectName +
                  "_" +
                  this.analysisData[i].subjects[j].subjectType
              ] = "0 / 0";
            }

            totalP = totalP + this.analysisData[i].subjects[j].totalAttended;
            totalT = totalT + this.analysisData[i].subjects[j].totalConducted;
          } catch (error) {
            console.error("Failed to fetch subject details:", error);
            // Handle the error if needed
            // You can choose to continue the loop or break here
          }
        }

        let total = 0;
        if (totalT > 0) {
          total = Math.ceil((totalP / totalT) * 100);
        }
        obj.total = total;
        if (total > 0 && total <= 10) {
          this.attendanceDistribution["0 % - 10 %"]++;
        }
        if (total > 10 && total <= 20) {
          this.attendanceDistribution["10 % - 20 %"]++;
        }
        if (total > 20 && total <= 30) {
          this.attendanceDistribution["20 % - 30 %"]++;
        }
        if (total > 30 && total <= 40) {
          this.attendanceDistribution["30 % - 40 %"]++;
        }
        if (total > 40 && total <= 50) {
          this.attendanceDistribution["40 % - 50 %"]++;
        }
        if (total > 50 && total <= 60) {
          this.attendanceDistribution["50 % - 60 %"]++;
        }
        if (total > 60 && total <= 70) {
          this.attendanceDistribution["60 % - 70 %"]++;
        }
        if (total > 70 && total <= 80) {
          this.attendanceDistribution["70 % - 80 %"]++;
        }
        if (total > 80 && total <= 90) {
          this.attendanceDistribution["80 % - 90 %"]++;
        }
        if (total > 90 && total <= 100) {
          this.attendanceDistribution["90 % - 100 %"]++;
        }
        if (total < this.defaulterLimit) {
          this.defaulterCount++;
        }
        this.items.push(obj);
      }
      this.itemsBackup = JSON.parse(JSON.stringify(this.items));
      this.headersBackup = JSON.parse(JSON.stringify(this.headers));
      this.attendanceDistributionBackup = JSON.parse(
        JSON.stringify(this.attendanceDistributionBackup)
      );
      this.disable = false;
      this.dataProccessed = true;
      this.loading = false;
      } catch (error) {
        console.error("error", error);
      }
    },
    subjectChanges() {
      const [name, type] = this.selectedSubjectExcel.split("-");
      this.allSubjectForCourseYear.map((data) => {
        if (data.subjectName === name) {
          this.groupNameForSub = data.groupName;
        }
      });
    },
    shouldRenderDropdown(type) {
      return this.selected === type;
    },
    saveEndDateAndSelectDivision() {
      this.$refs.dialog2.save(this.endDate); // Saving endDate
      this.validateDate(); // Validating date
      if (!this.selectedGroupOfASubject) {
        this.selectDivision(); // Triggering selectDivision function
      }
      if (this.selectedBatch) {
        this.attendanceAnalysisForABatch();
      }
      if (this.selectedGroupOfASubject) {
        this.getGroupWiseAttendance();
      }
    },
    saveStartDateAndSelectDivision() {
      this.$refs.dialog1.save(this.startDate); // Saving startDate
    },
    async downloadAttendanceAnalysisSubjectWise() {
      const [name, type] = this.selectedSubjectExcel.split("-");
      let id = "";
      let groupName = "";
      this.allSubjectForCourseYear.map((data) => {
        if (data.subjectName === name) {
          id = data.subjectId;
          groupName = data.groupName;
        }
      });
      let facultyForSubject;
      try {
        facultyForSubject =
          await this.subjectRepositoryInstance.getFacultiesTeachingASubjectOfACourseYearInASemester(
            {
              subjectId: id,
              instituteId: this.selectedInstitute,
              semId: this.selectedSemesterExcel.semId,
            }
          );
      } catch (error) {
        console.error(error);
      }

      try {
        const obj = {
          subjectId: id,
          subjectName: name,
          subjectType: type,
          instituteId: this.selectedInstitute,
          semId: this.selectedSemesterExcel.semId,
          department: this.selectedDepartmentExcel,
          division: groupName === "Common" ? this.selectedDivisionExcel : "A",
          multiDivision: "A",
          courseYear: this.selectedCourseYearExcel,
          uId: facultyForSubject[0],
          groupName: groupName,
        };
        const pdfBlob =
          await this.lectureRepositoryInstance.generateExcelSubjectWise(obj);
        const pdfBlob2 = new Blob([pdfBlob.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(pdfBlob2);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Attendance Analysis.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.error(err);
        window.alert("Something went wrong while downloading the excel.");
      }
    },

    convertSubjectTypeToName(num) {
      let type;
      switch (num) {
        case 0: {
          type = "Normal Theory";
          break;
        }
        case 1: {
          type = "Normal Practical";
          break;
        }
        case 2: {
          type = "Normal Tutorial";
          break;
        }
        case 3: {
          type = "Normal Audit Course";
          break;
        }
        case 4: {
          type = "Elective Theory";
          break;
        }
        case 5: {
          type = "Elective Practical";
          break;
        }
        case 6: {
          type = "Elective Tutorial";
          break;
        }
        case 7: {
          type = "Elective Audit Course";
          break;
        }

        default:
          type = "Theory";

        // code block
      }
      return type;
    },
    typeChange() {
      this.selectedDivisionExcel = "";
      this.selectedSubjectExcel = "";
    },
    async downloadAttendanceAnalysisDivisionWise() {
      try {
        if (this.allSubjectForCourseYear.length > 0) {
          const obj = {
            applicableSubject: this.allSubjectForCourseYear,
            instituteId: this.selectedInstitute,
            semId: this.selectedSemesterExcel.semId,
            department: this.selectedDepartmentExcel,
            division: this.selectedDivisionExcel,
            multiDivision: this.selectedDivisionExcel,
            courseYear: this.selectedCourseYearExcel,
            classTeacherId: this.userData.uId,
          };
          const pdfBlob =
            await this.lectureRepositoryInstance.generateExcelAttendanceAnalysisDivisionWise(
              obj
            );
          const pdfBlob2 = new Blob([pdfBlob.data], {
            type: "application/pdf",
          });
          const url = window.URL.createObjectURL(pdfBlob2);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Attendance Analysis.xlsx");
          document.body.appendChild(link);
          link.click();
        } else {
          console.error("error");
        }
      } catch (err) {
        console.error(err);
        window.alert("Something went wrong while downloading the excel.");
      }
    },

    async getSemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
        };
        this.semestersList =
          await this.semesterRepositoryInstance.getSemesterNames(objToPush);
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    },
    async fetchDepartments() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
        };
        this.divisionListOfSem =
          await this.semesterRepositoryInstance.getDivisionsOfASemester(
            objToPush
          );
        this.departmentsList = [];
        this.courseYearList = [];
        this.divisionList = [];
        this.divisionListOfSem.map((div) => {
          if (!this.departmentsList.includes(div.department)) {
            this.departmentsList.push(div.department);
          }
        });
        let subAdminAssignedDepartmentWiseLecture = [];
        if (this.assignedDepartment.length > 0) {
          this.departmentsList.map((data) => {
            if (this.assignedDepartment.includes(data)) {
              subAdminAssignedDepartmentWiseLecture.push(data);
            }
          });
          this.departmentsList = subAdminAssignedDepartmentWiseLecture;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async fetchDepartmentsExcel() {
      this.selectedDepartmentExcel = "";
      this.selectedCourseYearExcel = "";
      this.selectedDivisionExcel = "";
      this.selectedType = "";
      this.selectedSubjectExcel = "";
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemesterExcel.semId,
        };
        this.allDivData = [];
        this.allDivData =
          await this.semesterRepositoryInstance.getDivisionsOfASemester(
            objToPush
          );
        // this.departmentsList = []
        // this.courseYearList = []
        // this.divisionList = []
        this.departmentsListExcel = [];
        this.allDivData.map((div) => {
          if (!this.departmentsListExcel.includes(div.department)) {
            this.departmentsListExcel.push(div.department);
          }
        });
      } catch (err) {
        console.error(err);
      }
    },
    async fetchCourseYear() {
      this.courseYearList = [];
      this.divisionList = [];
      this.divisionListOfSem.map((div) => {
        if (div.department === this.selectedDepartment)
          if (!this.courseYearList.includes(div.courseYear)) {
            this.courseYearList.push(div.courseYear);
          }
      });
      let allowedCourseYearForSelectedDepartment = [];
      if (this.assignedCourseYearForSubAdmin.length > 0) {
        this.assignedCourseYearForSubAdmin.map((cy) => {
          let [course, department1] = cy.split("_");
          if (department1 === this.selectedDepartment) {
            allowedCourseYearForSelectedDepartment.push(course);
          }
        });
        this.courseYearList = allowedCourseYearForSelectedDepartment;
      }
    },
    async fetchCourseYearExcel() {
      this.selectedCourseYearExcel = "";
      this.selectedDivisionExcel = "";
      this.selectedType = "";
      this.selectedSubjectExcel = "";

      this.courseYearListExcel = [];
      this.allDivData.map((div) => {
        if (div.department === this.selectedDepartmentExcel)
          if (!this.courseYearListExcel.includes(div.courseYear)) {
            this.courseYearListExcel.push(div.courseYear);
          }
      });
    },

    async fetchDivision() {
      this.divisionList = [];

      this.divisionListOfSem.map((div) => {
        if (div.department === this.selectedDepartment) {
          if (div.courseYear === this.selectedCourseYear) {
            if (!this.divisionList.includes(div.division)) {
              this.divisionList.push(div.division);
            }
          }
        }
      });
      this.divisionList.sort();
      this.getSubjectsWithGroups();
    },
    async getSubjectsWithGroups() {
      try {
        let allSubjectForDept =
          await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(
            {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              department: this.selectedDepartment,
              courseYear: this.selectedCourseYear,
            }
          );
        this.allSubjectForDepartment = allSubjectForDept;
        const subjectWithGroup = allSubjectForDept.filter((obj) => {
          if (obj.groupForSubject && obj.groupForSubject.length !== 0) {
            return true;
          }
          return false;
        });
        this.subjectWithGroup = subjectWithGroup.map((obj) => {
          return obj.subjectName;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getGroupsForSubject() {
      for (let i = 0; i < this.allSubjectForDepartment.length; i++) {
        if (
          this.allSubjectForDepartment[i].subjectName ===
          this.selectedSubjectForGroup
        ) {
          this.groupsOfSubject =
            this.allSubjectForDepartment[i].groupForSubject;
        }
      }
      const groupNames = this.groupsOfSubject.map((group) => group.groupName);
      this.groupNamesOfSubject = groupNames;
    },

    async getGroupWiseAttendance() {
      this.selectedBatch = null;
      this.selectedDivision = null;
      const objToPush = {
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
      };
      let subjectId, groupId;
      const selectedSubject = this.allSubjectForDepartment.find(
        (subject) => subject.subjectName === this.selectedSubjectForGroup
      );
      if (selectedSubject) {
        subjectId = selectedSubject.subjectId;
      }
      const selectedGroup = this.groupsOfSubject.find(
        (group) => group.groupName === this.selectedGroupOfASubject
      );
      if (selectedGroup) {
        groupId = selectedGroup.groupId;
      }
      try {
        if (this.startDate !== "" && this.endDate !== "") {
          const currentUTCDate = new Date(this.startDate);
          const currentEndUTCDate = new Date(this.endDate);

          currentUTCDate.setUTCHours(0, 0, 0, 0);
          currentEndUTCDate.setUTCHours(23, 59, 59, 999);

          const val = convertLocalToUTC(currentUTCDate);
          const val2 = convertLocalToUTC(currentEndUTCDate);

          objToPush.fromDate = val.toISOString(); // Store UTC format in the object
          objToPush.toDate = val2.toISOString(); // Store UTC format in the object
        }
        objToPush.groupId = groupId;
        objToPush.subjectId = subjectId;
        this.analysisData =
          await this.lectureRepositoryInstance.getAttendanceAnalysisForABatch(
            objToPush
          );
        const temp = [];
        this.analysisData.map((data) => {
          temp.push(data.assignedMentor);
        });
        let mentorsNameWithUid = [];
        try {
          mentorsNameWithUid =
            await this.userRepositoryInstance.getFullNameOfUsers({
              uIds: temp,
            });
        } catch (error) {
          console.error(error);
        }
        this.dataProccessed = false;
        this.disable = true;
        this.items = [];
        this.subjectCodes = [];
        this.limits = [0, 100];
        this.headers = [
          {
            text: "Roll No",
            value: "rollNumber",
          },
          {
            text: "Name",
            value: "name",
          },
          {
            text: "Mentor Name",
            value: "mentorName",
          },
          {
            text: "Total",
            value: "total",
          },
        ];
        this.attendanceDistribution = {
          "0 % - 10 %": 0,
          "10 % - 20 %": 0,
          "20 % - 30 %": 0,
          "30 % - 40 %": 0,
          "40 % - 50 %": 0,
          "50 % - 60 %": 0,
          "60 % - 70 %": 0,
          "70 % - 80 %": 0,
          "80 % - 90 %": 0,
          "90 % - 100 %": 0,
        };
        this.defaulterCount = 0;
        for (let i = 0; i < this.analysisData.length; i++) {
          const obj = {};
          obj.uId = this.analysisData[i].uId;
          obj.mentorName = mentorsNameWithUid.filter(
            (data) => data.uId == this.analysisData[i].assignedMentor
          )[0]?.fullName;
          const user = this.analysisData[i];
          const name = await this.userRepositoryInstance.getFullNameOfUser({
            uId: user.uId,
          });
          obj.rollNumber = user.rollNumber;
          this.analysisData[i].name = name;
          obj.name = name;
          let totalP = 0;
          let totalT = 0;
          for (let j = 0; j < this.analysisData[i].subjects.length; j++) {
            if (
              this.subjectsMapping[this.analysisData[i].subjects[j].subjectId]
            ) {
            } else {
              this.subjectsMapping[this.analysisData[i].subjects[j].subjectId] =
                await this.subjectRepositoryInstance.getDetailsOfASubject({
                  instituteId: this.selectedInstitute,
                  semId: this.selectedSemester.semId,
                  subjectId: this.analysisData[i].subjects[j].subjectId,
                });
            }
            this.analysisData[i].subjects[j].subjectName =
              this.subjectsMapping[
                this.analysisData[i].subjects[j].subjectId
              ].subjectName;
            this.analysisData[i].subjects[j].subjectCode =
              this.subjectsMapping[
                this.analysisData[i].subjects[j].subjectId
              ].subjectCode;
            this.analysisData[i].subjects[j].subjectType =
              this.subjectTypes[
                this.subjectsMapping[
                  this.analysisData[i].subjects[j].subjectId
                ].subjectType
              ];
            const obj2 = {
              text:
                this.analysisData[i].subjects[j].subjectName +
                "_" +
                this.analysisData[i].subjects[j].subjectType,
              value:
                this.analysisData[i].subjects[j].subjectName +
                "_" +
                this.analysisData[i].subjects[j].subjectType,
            };
            if (
              this.subjectCodes.includes(
                this.analysisData[i].subjects[j].subjectName +
                  "_" +
                  this.analysisData[i].subjects[j].subjectType
              )
            ) {
            } else {
              this.headers.push(obj2);
              this.subjectCodes.push(
                this.analysisData[i].subjects[j].subjectName +
                  "_" +
                  this.analysisData[i].subjects[j].subjectType
              );
            }
            if (this.analysisData[i].subjects[j].totalConducted > 0) {
              obj[
                this.analysisData[i].subjects[j].subjectName +
                  "_" +
                  this.analysisData[i].subjects[j].subjectType
              ] =
                this.analysisData[i].subjects[j].totalAttended +
                " / " +
                this.analysisData[i].subjects[j].totalConducted;
              //obj[this.analysisData[i].subjects[j].subjectName + '_' + this.analysisData[i].subjects[j].subjectCode + '_' + this.analysisData[i].subjects[j].subjectType] = Math.ceil((this.analysisData[i].subjects[j].totalAttended / this.analysisData[i].subjects[j].totalConducted) * 100)
            } else {
              obj[
                this.analysisData[i].subjects[j].subjectName +
                  "_" +
                  this.analysisData[i].subjects[j].subjectType
              ] = "0 / 0";
            }
            totalP = totalP + this.analysisData[i].subjects[j].totalAttended;
            totalT = totalT + this.analysisData[i].subjects[j].totalConducted;
          }
          let total = 0;
          if (totalT > 0) {
            total = Math.ceil((totalP / totalT) * 100);
          }
          obj.total = total;
          if (total > 0 && total <= 10) {
            this.attendanceDistribution["0 % - 10 %"]++;
          }
          if (total > 10 && total <= 20) {
            this.attendanceDistribution["10 % - 20 %"]++;
          }
          if (total > 20 && total <= 30) {
            this.attendanceDistribution["20 % - 30 %"]++;
          }
          if (total > 30 && total <= 40) {
            this.attendanceDistribution["30 % - 40 %"]++;
          }
          if (total > 40 && total <= 50) {
            this.attendanceDistribution["40 % - 50 %"]++;
          }
          if (total > 50 && total <= 60) {
            this.attendanceDistribution["50 % - 60 %"]++;
          }
          if (total > 60 && total <= 70) {
            this.attendanceDistribution["60 % - 70 %"]++;
          }
          if (total > 70 && total <= 80) {
            this.attendanceDistribution["70 % - 80 %"]++;
          }
          if (total > 80 && total <= 90) {
            this.attendanceDistribution["80 % - 90 %"]++;
          }
          if (total > 90 && total <= 100) {
            this.attendanceDistribution["90 % - 100 %"]++;
          }
          if (total < this.defaulterLimit) {
            this.defaulterCount++;
          }
          this.items.push(obj);
        }
        this.itemsBackup = JSON.parse(JSON.stringify(this.items));
        this.headersBackup = JSON.parse(JSON.stringify(this.headers));
        this.attendanceDistributionBackup = JSON.parse(
          JSON.stringify(this.attendanceDistributionBackup)
        );
        this.disable = false;
        this.dataProccessed = true;
        this.loading = false;
      } catch (error) {
        console.error("error", error);
      }
    },

    async fetchDivisionExcel() {
      this.selectedType = "";
      this.selectedSubjectExcel = "";
      this.selectedDivisionExcel = "";

      this.divisionListExcel = [];
      this.allDivData.map((div) => {
        if (div.department === this.selectedDepartmentExcel) {
          if (div.courseYear === this.selectedCourseYearExcel) {
            if (!this.divisionListExcel.includes(div.division)) {
              this.divisionListExcel.push(div.division);
            }
          }
        }
      });
      try {
        this.allSubjectForCourseYear = [];
        this.allSubjectForCourseYear =
          await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(
            {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemesterExcel.semId,
              department: this.selectedDepartmentExcel,
              courseYear: this.selectedCourseYearExcel,
            }
          );
        this.allSubjectForCourseYear.map((data) => {
          this.subjectListExcel.push(
            data.subjectName +
              "-" +
              this.convertSubjectTypeToName(data.subjectType)
          );
        });
      } catch (error) {
        console.error("error", error);
      }
    },

    async fetchData() {
      const objToPush = {};
      this.departments =
        await this.userRepositoryInstance.getDivisionInformationOfAFacultyAsClassTeacher(
          {
            instituteId: this.userData.instituteId,
            semId: this.userData.semId,
            uId: this.userData.uId,
          }
        );
      this.departments.map((dept) => {
        dept.mydivisions = `${dept.courseYear}_${dept.division}`;
      });
      this.loading = false;
    },
    async selectDivision() {
      this.loading = true;

      const objToPush = {
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        division: this.selectedDivision,
      };

      this.batchList = this.divisionListOfSem
        .filter((obj) => obj.division === this.selectedDivision)
        .map((obj) => obj.batchesOfDivision.map((batch) => batch.batchName))
        .flat();

      if (this.startDate !== "" && this.endDate !== "") {
        const currentUTCDate = new Date(this.startDate);
        const currentEndUTCDate = new Date(this.endDate);

        currentUTCDate.setUTCHours(0, 0, 0, 0);
        currentEndUTCDate.setUTCHours(23, 59, 59, 999);

        const val = convertLocalToUTC(currentUTCDate);
        const val2 = convertLocalToUTC(currentEndUTCDate);

        objToPush.fromDate = val.toISOString(); // Store UTC format in the object
        objToPush.toDate = val2.toISOString(); // Store UTC format in the object
      }
      this.analysisData =
        await this.lectureRepositoryInstance.getAttendanceAnalysisForADivision(
          Object.assign(objToPush, {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            // uId: this.userData.uId
          })
        );
      const temp = [];
      this.analysisData.map((data) => {
        temp.push(data.assignedMentor);
      });
      let mentorsNameWithUid = [];
      try {
        mentorsNameWithUid =
          await this.userRepositoryInstance.getFullNameOfUsers({
            uIds: temp,
          });
      } catch (error) {
        console.error(error);
      }

      // },
      // fetchAnalysis () {
      this.dataProccessed = false;
      this.disable = true;
      this.items = [];
      this.subjectCodes = [];
      this.limits = [0, 100];
      this.headers = [
        {
          text: "Roll No",
          value: "rollNumber",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Mentor Name",
          value: "mentorName",
        },
        {
          text: "Total",
          value: "total",
        },
      ];
      this.attendanceDistribution = {
        "0 % - 10 %": 0,
        "10 % - 20 %": 0,
        "20 % - 30 %": 0,
        "30 % - 40 %": 0,
        "40 % - 50 %": 0,
        "50 % - 60 %": 0,
        "60 % - 70 %": 0,
        "70 % - 80 %": 0,
        "80 % - 90 %": 0,
        "90 % - 100 %": 0,
      };
      this.defaulterCount = 0;
      for (let i = 0; i < this.analysisData.length; i++) {
        const obj = {};
        obj.uId = this.analysisData[i].uId;
        obj.mentorName = mentorsNameWithUid.filter(
          (data) => data.uId == this.analysisData[i].assignedMentor
        )[0]?.fullName;
        const user = this.analysisData[i];
        const name = await this.userRepositoryInstance.getFullNameOfUser({
          uId: user.uId,
        });
        obj.rollNumber = user.rollNumber;
        this.analysisData[i].name = name;
        obj.name = name;
        let totalP = 0;
        let totalT = 0;
        for (let j = 0; j < this.analysisData[i].subjects.length; j++) {
          try {
            if (
              this.subjectsMapping[this.analysisData[i].subjects[j].subjectId]
            ) {
              // Subject details already exist in subjectsMapping, no need to fetch again
            } else {
              this.subjectsMapping[this.analysisData[i].subjects[j].subjectId] =
                await this.subjectRepositoryInstance.getDetailsOfASubject({
                  instituteId: this.selectedInstitute,
                  semId: this.selectedSemester.semId,
                  subjectId: this.analysisData[i].subjects[j].subjectId,
                });
            }

            this.analysisData[i].subjects[j].subjectName =
              this.subjectsMapping[
                this.analysisData[i].subjects[j].subjectId
              ].subjectName;
            this.analysisData[i].subjects[j].subjectCode =
              this.subjectsMapping[
                this.analysisData[i].subjects[j].subjectId
              ].subjectCode;
            this.analysisData[i].subjects[j].subjectType =
              this.subjectTypes[
                this.subjectsMapping[
                  this.analysisData[i].subjects[j].subjectId
                ].subjectType
              ];

            const obj2 = {
              text:
                this.analysisData[i].subjects[j].subjectName +
                "_" +
                this.analysisData[i].subjects[j].subjectType,
              value:
                this.analysisData[i].subjects[j].subjectName +
                "_" +
                this.analysisData[i].subjects[j].subjectType,
            };

            if (
              this.subjectCodes.includes(
                this.analysisData[i].subjects[j].subjectName +
                  "_" +
                  this.analysisData[i].subjects[j].subjectType
              )
            ) {
              // Subject code already exists in subjectCodes, no need to push again
            } else {
              this.headers.push(obj2);
              this.subjectCodes.push(
                this.analysisData[i].subjects[j].subjectName +
                  "_" +
                  this.analysisData[i].subjects[j].subjectType
              );
            }

            if (this.analysisData[i].subjects[j].totalConducted > 0) {
              obj[
                this.analysisData[i].subjects[j].subjectName +
                  "_" +
                  this.analysisData[i].subjects[j].subjectType
              ] =
                this.analysisData[i].subjects[j].totalAttended +
                " / " +
                this.analysisData[i].subjects[j].totalConducted;
            } else {
              obj[
                this.analysisData[i].subjects[j].subjectName +
                  "_" +
                  this.analysisData[i].subjects[j].subjectType
              ] = "0 / 0";
            }

            totalP = totalP + this.analysisData[i].subjects[j].totalAttended;
            totalT = totalT + this.analysisData[i].subjects[j].totalConducted;
          } catch (error) {
            console.error("Failed to fetch subject details:", error);
            // Handle the error if needed
            // You can choose to continue the loop or break here
          }
        }

        let total = 0;
        if (totalT > 0) {
          total = Math.ceil((totalP / totalT) * 100);
        }
        obj.total = total;
        if (total > 0 && total <= 10) {
          this.attendanceDistribution["0 % - 10 %"]++;
        }
        if (total > 10 && total <= 20) {
          this.attendanceDistribution["10 % - 20 %"]++;
        }
        if (total > 20 && total <= 30) {
          this.attendanceDistribution["20 % - 30 %"]++;
        }
        if (total > 30 && total <= 40) {
          this.attendanceDistribution["30 % - 40 %"]++;
        }
        if (total > 40 && total <= 50) {
          this.attendanceDistribution["40 % - 50 %"]++;
        }
        if (total > 50 && total <= 60) {
          this.attendanceDistribution["50 % - 60 %"]++;
        }
        if (total > 60 && total <= 70) {
          this.attendanceDistribution["60 % - 70 %"]++;
        }
        if (total > 70 && total <= 80) {
          this.attendanceDistribution["70 % - 80 %"]++;
        }
        if (total > 80 && total <= 90) {
          this.attendanceDistribution["80 % - 90 %"]++;
        }
        if (total > 90 && total <= 100) {
          this.attendanceDistribution["90 % - 100 %"]++;
        }
        if (total < this.defaulterLimit) {
          this.defaulterCount++;
        }
        this.items.push(obj);
      }
      this.itemsBackup = JSON.parse(JSON.stringify(this.items));
      this.headersBackup = JSON.parse(JSON.stringify(this.headers));
      this.attendanceDistributionBackup = JSON.parse(
        JSON.stringify(this.attendanceDistributionBackup)
      );
      this.disable = false;
      this.dataProccessed = true;
      this.loading = false;
      // this.selectedBatch = null
      // axios.post(apiConfig.getAttendanceAnalysis, objToPush).then(res => {
      //   this.analysisData = res.data.attendance
      // }).catch(err => {
      //   console.error(err)
      //   window.alert('This Activity is only for Class Teachers!')
      //   this.$store.dispatch('liveData/loading', false)
      //   this.$store.commit('liveData/setselectedActivity', 2)
      //   this.$router.push('activityMySubjects')
      // })
    },
    async selectDivisionExcel() {},
    recalculateDefaulters() {
      this.defaulterCount = 0;
      this.loading = true;
      this.defaulterLimit = this.tempLimit;
      for (let i = 0; i < this.itemsBackup.length; i++) {
        if (this.itemsBackup[i].total < this.defaulterLimit) {
          this.defaulterCount++;
        }
      }
      this.loading = false;
      this.changeDefaulterLimit = false;
    },
    validateDate() {
      if (this.startDate > this.endDate) {
        showStatus(
          "End date cannot be ahead of start date.",
          9000,
          "error",
          this
        );
      }
    },
    applyLimit() {
      this.dataProccessed = false;
      this.loading = true;
      this.items = [];
      for (let i = 0; i < this.itemsBackup.length; i++) {
        if (
          this.itemsBackup[i].total <= this.limits[1] &&
          this.itemsBackup[i].total >= this.limits[0]
        ) {
          this.items.push(this.itemsBackup[i]);
        }
      }
      this.dataProccessed = true;
      this.loading = false;
    },
    showDefaulters() {
      this.limits = [0, this.defaulterLimit - 1];
      this.applyLimit();
    },
    async downloadCumulativeAttendanceExcelTest() {
      try {
        const pdfBlob =
          await this.lectureRepositoryInstance.generateCumulativeAttendanceExcel(
            {
              items: this.items,
              headers: this.headers,
            }
          );
        const pdfBlob2 = new Blob([pdfBlob.data], {
          type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(pdfBlob2);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Cumulative_Attendance.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.error(err);
        window.alert("Something went wrong while downloading the excel.");
      }
    },
    async downloadCumulativeAttendanceExcel() {
      const subjectsMappingArray = Object.keys(this.subjectsMapping).map(
        (key) => this.subjectsMapping[key]
      );
      let tempArr = [];
      Object.keys(this.subjectsMapping).forEach((key) => {
        tempArr.push(key);
      });
      await axios
        .post(
          networkManager.serverUrl +
            "/excel/lecture/generateCumulativeAttendanceExcel",
          {
            items: this.items,
            headers: this.headers,
            subjectDetails: tempArr,
            division: this.selectedDivision,
            subjectMapping: JSON.stringify(subjectsMappingArray),
            headingData: {
              department: this.selectedDepartment,
              courseYear: this.selectedCourseYear,
              instituteName: this.instituteName,
            },
            isGroup : this.selected === 'Group' ? true : false,
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Cumulative_Attendance_of_division_${this.selectedDivision}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        });
    },
    async downloadCumulativeAttendance() {
      // const objToPush = {
      //   collegeId: this.userData.collegeId,
      //   semId: this.userData.semId,
      //   uId: this.userData.id
      // }
      // axios.post(apiConfig.downloadCumulativeAttendance, objToPush, {
      //   responseType: 'arraybuffer'
      // }).then(res => {
      //   const url = window.URL.createObjectURL(new Blob([res.data], { type: 'vnd.ms-excel;charset=utf-8' }))
      //   const link = document.createElement('a')
      //   link.href = url
      //   link.setAttribute('download', 'Cumulative Attendance.xlsx')
      //   document.body.appendChild(link)
      //   link.click()
      // }).catch(err => {
      //   console.error(err)
      //   window.alert('Something went wrong while downloading the excel.')
      // })
      // try {
      //   const pdfBlob = await this.lectureRepositoryInstance.downloadCumulativeAttendance(this.prop_subject)
      //   const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
      //   const url = window.URL.createObjectURL(pdfBlob2)
      //   const link = document.createElement('a')
      //   link.href = url
      //   link.setAttribute('download', 'Attendance Report.xlsx')
      //   document.body.appendChild(link)
      //   link.click()
      // } catch (err) {
      //   console.error(err)
      //   window.alert('Something went wrong while downloading the excel.')
      // }
    },
  },
};
</script>
<style scoped src='./activityCumulativeAttendance.css'></style>
