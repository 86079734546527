import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Lecture'

class LectureRepository {
  constructor (context) {
    this.context = context
  }

  async getAllLecturesOfMultipleInstitutesPerDayByUId(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAllLecturesOfMultipleInstitutesPerDayByUId, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async getLecturesOfACourseYear(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesOfACourseYear, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async getLectureByIds(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getLectureByIds, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async facultyLectureClashDetection(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.facultyLectureClashDetection, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async roomClashDetection(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.roomClashDetection, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }
  async generateExcelAttendanceAnalysisDivisionWise(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateExcelAttendanceAnalysisDivisionWise, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async generateExcelSubjectWise(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateExcelSubjectWise, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async getAllLectureOfValueAddedActivity (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAllLectureOfValueAddedActivity, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lecture
  }
  async getLecture (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lecture
  }

  async getConductedLecturesCountOfASubject(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getConductedLecturesCountOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.conductedLectures
  }
  
  async getAttendanceAnalysisForADivision(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAttendanceAnalysisForADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.attendanceAnalysis
  }
  async getAttendanceAnalysisForABatchOfADivision(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAttendanceAnalysisForABatchOfADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.attendanceAnalysis
  }
  async getAttendanceAnalysisForABatch(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAttendanceAnalysisForABatch, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.attendanceAnalysis
  }
  // async getLecturesOfACourseYear(data) {
  //   this.context.$store.commit('liveData/set_loading', true)
  //   const response = await networkManager.getRequest(ApiNames.getLecturesOfACourseYear, data)
  //   this.context.$store.commit('liveData/set_loading', false)
  //   return response.lectures
  // }

  async generateAttendanceSheetExcel(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateAttendanceSheetExcel, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async saveAttendance(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.saveAttendance, data)
    this.context.$store.commit('liveData/set_loading', false)
    return { present: response.present, absent: response.absent }
  }

  async getLecturesBySubject(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getLecturesBySubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lecture
  }

  async getLecturesForADayForAFaculty (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesForADayForAFaculty, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

 async getTotalAttendedLecturesForADivisionForSubject(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTotalAttendedLecturesForADivisionForSubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.studentsList
  }
  
  async createLecturesFromATimetableDay (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createLecturesFromATimetableDay, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getAttendanceAnalysisForADivisionForASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAttendanceAnalysisForADivisionForASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getLecturesOfADepartmentForADay (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesOfADepartmentForADay, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getRealtimeSchedule (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getRealtimeSchedule, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getRealtimeScheduleForAdmin (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getRealtimeScheduleForAdmin, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getGroupedLecturesOfDay (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getGroupedLecturesOfDay, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }
  async getOverAllLectureForDates (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getOverAllLectureForDates, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async getLecturesOfADivisionForADay (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesOfADivisionForADay, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }
  async getLecturesOfAFacultyOfASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesOfAFacultyOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }
  async getLecturesOfAFacultyForASem (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesOfAFacultyForASem, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }
  async findAllClashOfLecOnSemLevel (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.findAllClashOfLecOnSemLevel, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }



  async arrangeALecture (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.arrangeALecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async createMultipleLectures (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createMultipleLectures, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async updateMultipleLectureDocument (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateMultipleLectureDocument, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getLecturesForADay (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesForADay, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async cancelLecture (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.cancelLecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectureStatus
  }

  async declareHoliday (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.declareHoliday, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectureStatus
  }

  async generateAttendanceAnalysisExcel (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateAttendanceAnalysisExcel, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async generateLectureDashboardExcel (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateLectureDashboardExcel, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getLectureById (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLectureById, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lecture
  }
  
  async getLecturesForADayForAStudent (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesForADayForAStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }
  
  async getAttendanceAnalysisForAStudent (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAttendanceAnalysisForAStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.attendanceAnalysis
  }

  async getLectureDetailsOfLecturesForATopic (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLectureDetailsOfLecturesForATopic, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getLecturesBySubject(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getLecturesBySubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lecture
  }
  async deleteLecture(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.deleteRequest(ApiNames.deleteLecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async cancelALecture(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.cancelALecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectureStatus
  }
}

export default LectureRepository
