<template src="./activityEditSemesterUsers.html"></template>

<script>
import ImportAllDialog from "./importAllDialog/importAllDialog";
import inputContainer from "../../Components/inputContainer";
import SemesterUserRepository from "../../Repository/SemesterUser";
import UserRepository from "../../Repository/User";
import InstituteUserRepository from "../../Repository/InstituteUser";
import arrayOps from "../../Services/Utils/arrayOps";
import showStatus from "../../NetworkManager/showStatus";
import { createExcelThroughJson } from "../../utils/excel";
import axios from "axios";
import ActivityLoggerRepository from "../../Repository/ActivityLogger";
import SemesterRepository from "../../Repository/Semester";

export default {
  name: "ActivityUsersTree",
  components: {
    ImportAllDialog,
    inputContainer,
  },
  props: [
    "PropsSelectedSemester",
    "prop_selectedDepartment",
    "prop_selectedCourseYear",
    "prop_selectedDivision",
    "tree",
  ],
  data() {
    return {
      dialog:false,
      singleSelect: false,
      userForDelete: false,
      deleteSemUserDialog: false,
      getUsersforPrn: [],
      pageLoader: false,
      uploadExcelForPrn: false,
      file: null,
      selectionType: "leaf",
      addDialog: false,
      importAll: false,
      loading: false,
      users: [],
      search: "",
      headersForStudents: [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name",
          filterable: true,
        },
        {
          text: "PRN",
          align: "left",
          sortable: true,
          value: "collegePRNNo",
          filterable: true,
        },
        {
          text: "Department",
          align: "left",
          sortable: true,
          value: "department",
          filterable: true,
        },
        {
          text: "Course Year",
          align: "left",
          sortable: true,
          value: "courseYear",
          filterable: true,
        },
        {
          text: "Division",
          align: "left",
          sortable: true,
          value: "division",
          filterable: true,
        },
        { text: "Batch", value: "batch", width: "120px" },
        { text: 'Specialization', value: 'specialization' },
        { text: "Roll Number", value: "rollNumber" },
        { text: "Exam Seat Number", value: "examSeatNumber" },
        {
          text: "Student Id",
          align: "center",
          sortable: true,
          value: "instituteProvidedId",
          filterable: true,
        },
        // {
        //   text: "Action",
        //   value: "action",
        //   sortable: false,
        //   filterable: false,
        //   align: "center",
        // },
      ],
      headersForFaculties: [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name",
          filterable: true,
        },
        {
          text: "Department",
          align: "left",
          sortable: true,
          value: "department",
          filterable: true,
        },
        {
          text: "Faculty Id",
          align: "center",
          sortable: true,
          value: "instituteProvidedId",
          filterable: true,
        },

      ],
      selectedUser: null,
      userType: "faculty",
      selection: [],
      selectedInstitute: null,
      selectedSemester: {},
      userMappings: {},
      changedObjs: [],
      changedIds: [],
      academicTreeForFilter: [],
      referenceAcademicTree: {},
      deleteMultipleUsers: [],
      deleteBulkUsers:[],
      bulkDeleteSemUserDialog: false,
      showSemesterUserLogs: false,
      searchTable: "",
      displayLogsArray: [],
      numPreviousLogs: 5,
      askForTransfer: false,
      semestersNamesList: [],
      semestersObjectsList: [],
      selectedSemesterName: "",
      filteredSemNames: [],
      academicDataTree1: {},
      fromDepartments: [],
      academicDataTree2: {},
      toDepartments: [],
      studentsToTransfer: {},
      logHeaders: [
        { text: "User type", value: "selectedUserType" },
        { text: "Name", value: "userName" },
        { text: "From", value: "selectMode" },
        { text: "Criteria", value: "selection" },
        { text: "Action Type", value: "actionType" },
        { text: "Saved At", value: "savedAt" },
        { text: "Saved By", value: "savedBy" },
      ],
    };
  },
  created() {
    this.$store.commit("liveData/set_selectedActivityName", "Semester Users");
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.instituteUserRepositoryInstance = new InstituteUserRepository(this);
    this.activityLoggerInstance = new ActivityLoggerRepository(this);
    this.userData = this.$store.getters["user/get_userData"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester = this.PropsSelectedSemester;
    this.academicTreeForFilter = JSON.parse(JSON.stringify(this.tree));
    this.setupActivity();
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.UpdateSemesterUserServiceInstance = new SemesterUserRepository(this);
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.selection.push(...this.academicTreeForFilter);
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
        };
        this.semestersObjectsList = await this.semesterRepositoryInstance.getSemesterNames(objToPush);
        this.semestersObjectsList.reverse();
        this.semestersNamesList = this.semestersObjectsList.map(
          (semester) => semester.semName
        );

        const commonSemId = this.selectedSemester?.semId;
        const filteredSemesters = this.semestersObjectsList
          .filter(item => item.semId !== commonSemId)

        this.filteredSemNames = filteredSemesters.map(
          (semester) => semester.semName
        );
        this.selectedToDepartment = "";
        this.selectedToCourseYear = "";
        this.toCourseYears = [];
        this.selectedToDivision = "";
        this.toDivisions = [];
        const obj = await this.getAcademicTreeForSemester(
          this.selectedSemester.semId
        );
        this.academicDataTree2 = obj.academicDataTree;
        this.toDepartments = obj.departments;
        this.copySemester();
      } catch (error) {
        console.log(error);
        this.semestersObjectsList = [];
        this.semestersNamesList = [];
      }
      this.onRefresh();
    },
    async deleteSemUser() {
      this.deleteSemUserDialog = false;
      try {
        if (this.userType === "faculty") {
          this.userForDelete.isFaculty = true;
        }
        let objToPush = {
          instituteId: this.userForDelete.instituteId,
          semId: this.userForDelete.semId,
          department: this.userForDelete.department,
          courseYear: this.userForDelete.courseYear,
          division: this.userForDelete.division,
          userName: this.userForDelete.name,
          uId: this.userForDelete.uId,
          actionType: "delete",
          semesterLogType: "delete",
          selectedUserType: this.userType,
          selectMode: "Semester Users",
          savedBy: this.userData.firstName,
        };
        await this.semesterUserRepositoryInstance.deleteSemesterUser(
          this.userForDelete
        );
        this.users = arrayOps.removeFromArray(
          this.users,
          this.users.indexOf(this.userForDelete)
        );
        await this.activityLoggerInstance.createLogForSingleUser(objToPush);
      } catch (err) {
        console.log(err);
      }
    },
    copyRollNoSeatNo() {
      this.askForTransfer = true;
    },
    async transferStudents() {
      try {
        let tempU = []
        this.users.map((obj)=>{
          let user = this.fromUsers.filter(obj1=>obj1.uId === obj.uId)
          if (user.length > 0) {
            tempU.push(user[0])
            obj.rollNumber = user[0].rollNumber?user[0].rollNumber:""
            obj.examSeatNumber = user[0].examSeatNumber?user[0].examSeatNumber:""
            obj.specialization = user[0].specialization?user[0].specialization:""
          }
        })
        if (tempU.length === 0) {
          showStatus("Students are not available for the selected data", 2000, "error", this);
          return;
        }

        let tempUsers = this.users.map((user) => {return {uId:user.uId,instituteId:user.instituteId,semId:user.semId, rollNumber:user.rollNumber, examSeatNumber:user.examSeatNumber, specialization:user.specialization}})
        let objToPush = {
          bulkDataArray: tempUsers.filter(e=>e.examSeatNumber !== undefined && e.rollNumber !== undefined &&  e.specialization !== undefined)
        };
        let updatedUsers = await this.UpdateSemesterUserServiceInstance.updateSemesterUser(objToPush);
        showStatus("Roll Number and Exam Seat has been copied successfully!", 2000, "success", this);
        this.askForTransfer = false;
      } catch (error) {
        console.error("An error occurred:", error);
        showStatus("Students are not available for the selected data", 2000, "error", this);
      }
    },
    async copySemester() {
      this.selectedFromDepartment = "";
      this.selectedFromCourseYear = "";
      this.fromCourseYears = [];
      this.selectedFromDivision = "";
      this.fromDivisions = [];
      const semObject = this.semestersObjectsList.find(
        (element) => element.semName === this.selectedSemester.semName
      );
      const obj = await this.getAcademicTreeForSemester(semObject.semId);
      this.academicDataTree1 = obj.academicDataTree;
      this.fromDepartments = obj.departments;
    },
    async getCourseYears1() {
      this.loading = true;
      this.selectedFromCourseYear = "";
      this.selectedFromDivision = "";
      this.fromDivisions = [];
      this.fromCourseYears = Object.keys(
        this.academicDataTree1[this.selectedFromDepartment]
      );
      this.loading = false;
    },
    async getDivisions1() {
      this.loading = true;
      this.selectedFromDivision = "";
      this.fromDivisions =
        this.academicDataTree1[this.selectedFromDepartment][
          this.selectedFromCourseYear
        ];
      this.loading = false;
    },
    async fetchStudents() {
      this.loading = true;
      // const index = this.semestersNamesList.indexOf(this.selectedFromSemesterName)
      const semObject = this.semestersObjectsList.find(element => element.semName === this.selectedSemesterName)
      const studentIds = await this.semesterUserRepositoryInstance.getStudentsOfADivision({
        instituteId: this.selectedInstitute,
        semId: semObject.semId,
        department: this.selectedFromDepartment,
        courseYear: this.selectedFromCourseYear,
        division: this.selectedFromDivision,
      })
      const uIds = studentIds.map((studentId)=>{
        return studentId.uId;
      })
      const response = await this.userRepositoryInstance.getFullNameOfUsers({uIds: uIds});
      this.fromUsers = response

      this.fromUsers = response.map((user) => {
        const studentData = studentIds.find((student) => student.uId === user.uId);
        const rollNumber = studentData?.rollNumber || '-';
        const examSeatNumber = studentData?.examSeatNumber || '-';
        const specialization = studentData?.specialization || '-';
        return { ...user, rollNumber, examSeatNumber, specialization };
      });
      this.loading = false
    },
    async getAcademicTreeForSemester(semId) {
      this.loading = true;
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: semId,
        };
        const semestersList =
        await this.semesterRepositoryInstance.getDivisionsOfASemester(
          objToPush
        );
        const academicDataTree = {};
        semestersList.forEach((sem) => {
          if (!academicDataTree[sem.department]) {
            academicDataTree[sem.department] = {};
          }
          if (!academicDataTree[sem.department][sem.courseYear]) {
            academicDataTree[sem.department][sem.courseYear] = [];
          }
          academicDataTree[sem.department][sem.courseYear].push(sem.division);
        });
        const departments = Object.keys(academicDataTree);
        return {
          academicDataTree: academicDataTree,
          departments: departments,
        };
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async generateStudentExcel() {
      const res = this.users.map((obj) => {
        return {
          department: obj?.department,
          courseYear: obj?.courseYear,
          division: obj?.division,
          batch: obj?.batch,
          Name: obj?.name,
          college_PRN_No: obj?.collegePRNNo,
          rollNumber: obj?.rollNumber,
          examSeatNumber: obj?.examSeatNumber,
          finalPercentage: obj?.finalPercentage,
          finalSgpa: obj?.finalSgpa,
        };
      });
      res.sort((a, b) => a.Name.localeCompare(b.Name));

      createExcelThroughJson(res, "userData");
    },
    async generateExcelPRN() {
      let obj = {};
      obj.studentData = this.users;
      try {
        const pdfBlob =
          await this.semesterUserRepositoryInstance.generateExcelUpdatePRN(obj);
        const pdfBlob2 = new Blob([pdfBlob.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(pdfBlob2);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Update PRN/RollNo Sample.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.log(err);
        window.alert("Something went wrong while downloading the excel.");
      }
    },
    uploadExcelPRN() {
      this.pageLoader = true;
      if (this.file) {
        var formData = new FormData();

        formData.append("excel", this.file);
        formData.append("semId", this.selectedSemester.semId);
        formData.append("instituteId", this.selectedInstitute);
        // formData.append('stud', this.users)
        const url =
          process.env.VUE_APP_SERVER_URL +
          process.env.VUE_APP_API_PORT +
          "/excel/semester/parseExcelUpdatePrn";
        axios
          .post(
            process.env.VUE_APP_SERVER_URL +
              process.env.VUE_APP_API_PORT +
              "/excel/semester/parseExcelUpdatePrn",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(
            async (response) => {
              // 2 call provided for reason dont change it
              await this.setupActivity();
              await this.setupActivity();
              this.uploadExcelForPrn = false;
              if (response.status === 200) {
                this.pageLoader = false;
                showStatus(
                  "PRN and Roll Number Updated successfully!",
                  2000,
                  "success",
                  this
                );
              } else {
                console.log("not parse");
                showStatus(
                  "PRN and Roll Number Not Updated!",
                  2000,
                  "error",
                  this
                );
              }
            },
            (error) => {
              console.log(error);
              this.pageLoader = false;
            }
          );
      }
    },
    async setupActivity() {
      await this.getMasterDataTree();
      await this.getUsers();
    },
    async getMasterDataTree() {
      this.loading = true;
      this.referenceAcademicTree = {};
      for (let i = 0; i < this.tree.length; i++) {
        const obj = this.tree[i];
        if (!this.referenceAcademicTree[obj.name]) {
          this.referenceAcademicTree[obj.name] = {};
        }
        for (let j = 0; j < obj.children.length; j++) {
          const obj2 = obj.children[j];
          if (!this.referenceAcademicTree[obj.name][obj2.name]) {
            this.referenceAcademicTree[obj.name][obj2.name] = [];
          }
          for (let k = 0; k < obj2.children.length; k++) {
            this.referenceAcademicTree[obj.name][obj2.name].push(
              obj2.children[k].name
            );
          }
        }
      }
      this.academicTreeForFilter = [];
      if (this.userType === "students") {
        this.academicTreeForFilter = JSON.parse(JSON.stringify(this.tree));
      } else {
        for (let i = 0; i < this.tree.length; i++) {
          const obj = this.tree[i];
          this.academicTreeForFilter.push({
            id: obj.id,
            name: obj.name,
            department: obj.name,
          });
        }
      }
      this.loading = false;
    },
    onRefresh() {
      this.setupActivity();
    },
    gotoTransferUsers() {
      this.$router.push({
        name: "activityTransferUsers",
        params: {
          prop_selectedDepartment: this.prop_selectedDepartment,
          // prop_selectedSemester: this.PropsSelectedSemester,
          prop_selectedCourseYear: this.prop_selectedCourseYear,
          prop_selectedDivision: this.prop_selectedDivision,
          tree: this.tree,
        },
      });
    },
    async getUsers() {
      this.loading = true;
      this.users = [];
      this.changedObjs = [];
      this.changedIds = [];

      let promises = [];

      if (this.userType === "students") {
        promises = this.selection.map((divisionObject) => {
          return this.semesterUserRepositoryInstance.getStudentsOfADivision({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: divisionObject.department,
            courseYear: divisionObject.courseYear,
            division: divisionObject.name,
          });
        });
      } else {
        promises = [
          ...new Set(this.selection.map((elem) => elem.department)),
        ].map((department) => {
          return this.semesterUserRepositoryInstance.getFacultiesOfADepartment({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: department,
          });
        });
      }

      const allSettledPromises = await Promise.allSettled(promises);

      for (let i = 0; i < allSettledPromises.length; i++) {
        if (allSettledPromises[i].value) {
          await this.addUsersToList(allSettledPromises[i].value);
        }
      }
      try {
        let uIdArray = [];
        if (this.users.length > 0) {
          this.users.map((data) => {
            if (!uIdArray.includes(data.uId)) {
              uIdArray.push(data.uId);
            }
          });
          this.getUsersforPrn = await this.userRepositoryInstance.getUsers({
            uIds: uIdArray,
          });
          this.users.map((data) => {
            this.getUsersforPrn.map((userDetails) => {
              if (userDetails.uId === data.uId) {
                data.collegePRNNo = userDetails.collegePRNNo;
              }
            });
          });
        }
      } catch (error) {
        console.log("error", error);
      }
      this.loading = false;
    },
    async bulkDeleteUser() {
      let arr = [];
      const objToPush = {
        instituteId: this.deleteMultipleUsers[0]?.instituteId,
        semId: this.deleteMultipleUsers[0]?.semId,
        department: this.deleteMultipleUsers[0]?.department,
        courseYear: this.deleteMultipleUsers[0]?.courseYear,
        division: this.deleteMultipleUsers[0]?.division,
        uIds: this.deleteMultipleUsers.map((obj) => obj.uId),
      };

      await this.semesterUserRepositoryInstance.bulkDeleteSemesterUsers(
        objToPush
      );
      this.deleteMultipleUsers.map(async (obj) => {
       obj.userName = obj.name;
       obj.actionType = "delete";
       obj.semesterLogType = "delete";
       obj.selectedUserType = this.userType;
       (obj.selectMode = "Semester Users"),
        (obj.savedBy = this.userData.firstName);
       await this.activityLoggerInstance.createLogForSingleUser(obj);
      });
      this.setupActivity();
      this.bulkDeleteSemUserDialog = false;
      this.deleteMultipleUsers = [];
      this.dialog = true
    },
    async addUsersToList(listOfSemesterUsers) {
      const lengthBeforeUpdate = this.users.length;
      this.users = this.users.concat(listOfSemesterUsers);
      const listOfPromises = [];
      for (let i = lengthBeforeUpdate; i < this.users.length; i++) {
        listOfPromises.push(this.getFullNameOfUser(this.users[i]));
        listOfPromises.push(this.getInstituteProvidedIdOfUser(this.users[i]));
      }
      await Promise.allSettled(listOfPromises);
    },
    async getFullNameOfUser(userObject) {
      try {
        if (!userObject.name) {
          userObject.name = await this.userRepositoryInstance.getFullNameOfUser(
            { uId: userObject.uId }
          );
        }
      } catch (err) {
        console.log(err);
        userObject.name = "";
      }
      return userObject;
    },
    async getInstituteProvidedIdOfUser(userObject) {
      try {
        if (!userObject.instituteProvidedId) {
          userObject.instituteProvidedId =
            await this.instituteUserRepositoryInstance.getInstituteProvidedId({
              instituteId: this.selectedInstitute,
              uId: userObject.uId,
            });
        }
      } catch (err) {
        console.log(err);
        userObject.instituteProvidedId = "--";
      }
      return userObject;
    },
    async deleteUser(user) {
      this.userForDelete = user;
      this.deleteSemUserDialog = true;
    },
    async updateAllRecords() {
      this.loading = true;
      // Call API to edit Semester Users
      const data = [...this.changedObjs];
      const res =
        await this.semesterUserRepositoryInstance.editSemesterUsersForDepartments(
          data
        );
      if (res.status === 200) {
        this.changedObjs = [];
        this.changedIds = [];
        showStatus("Changes saved successfully!", 1500, "success", this);
      }
      this.loading = false;
    },
    closeImportAllDialog() {
      this.importAll = false;
    },
    addInChanged(item) {
      if (!this.changedIds.includes(item.uId)) {
        this.changedIds.push(item.uId);
        this.changedObjs.push(item);
      }
    },
    async updateDepartment(item) {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: item.uId,
        department: item.department,
      };
      try {
        const indexOfUser = this.users.indexOf(item);
        this.users[indexOfUser].department =
          await this.semesterUserRepositoryInstance.updateDepartment(objToPush);

        objToPush.userName = item.name;
        objToPush.actionType = "update";
        objToPush.semesterLogType = "update";
        objToPush.selectedUserType = this.userType;
        objToPush.selectMode = "Semester Users";
        objToPush.savedBy = this.userData.firstName;

        if (item.courseYear) {
          objToPush.courseYear = item.courseYear;
        }
        if (item.division) {
          objToPush.division = item.division;
        }
        await this.activityLoggerInstance.createLogForSingleUser(objToPush);

        showStatus("Department Updated!", 1500, "success", this);
      } catch (err) {
        console.log(err);
      }
    },
    async updateCourseYear(item) {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: item.uId,
        courseYear: item.courseYear,
      };
      try {
        const indexOfUser = this.users.indexOf(item);
        this.users[indexOfUser].courseYear =
          await this.semesterUserRepositoryInstance.updateCourseYear(objToPush);
        showStatus("Course Year Updated!", 1500, "success", this);
      } catch (err) {
        console.log(err);
      }
    },
    async updateDivision(item) {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: item.uId,
        division: item.division,
      };
      try {
        const indexOfUser = this.users.indexOf(item);
        this.users[indexOfUser].division =
          await this.semesterUserRepositoryInstance.updateDivision(objToPush);
        showStatus("Division Updated!", 1500, "success", this);
      } catch (err) {
        console.log(err);
      }
    },
    async updateBatch(item) {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: item.uId,
        batch: item.batch,
      };
      try {
        const indexOfUser = this.users.indexOf(item);
        this.users[indexOfUser].batch =
          await this.semesterUserRepositoryInstance.updateBatch(objToPush);
        await this.updateLogsForStudentDetails(objToPush, item);
        showStatus("Batch Updated!", 500, "success", this);
      } catch (err) {
        console.log(err);
      }
    },
    async updateRollNumber(item) {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: item.uId,
        rollNumber: item.rollNumber,
      };
      try {
        const indexOfUser = this.users.indexOf(item);
        this.users[indexOfUser].rollNumber =
          await this.semesterUserRepositoryInstance.updateRollNumber(objToPush);
        await this.updateLogsForStudentDetails(objToPush, item);
        showStatus("Roll Number Updated!", 1500, "success", this);
      } catch (err) {
        console.log(err);
      }
    },
    async updateExamSeatNumber(item) {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: item.uId,
        examSeatNumber: item.examSeatNumber,
      };
      try {
        const indexOfUser = this.users.indexOf(item);
        this.users[indexOfUser].examSeatNumber =
          await this.semesterUserRepositoryInstance.updateExamSeatNumber(
            objToPush
          );
        await this.updateLogsForStudentDetails(objToPush, item);
        showStatus("Exam Seat Number Updated!", 1500, "success", this);
      } catch (err) {
        console.log(err);
      }
    },
    async updateInstituteProvidedId(item) {
      const objToPush = {
        instituteId: this.selectedInstitute,
        uId: item.uId,
        instituteProvidedId: item.instituteProvidedId,
      };
      try {
        const indexOfUser = this.users.indexOf(item);
        this.users[indexOfUser].instituteProvidedId =
          await this.instituteUserRepositoryInstance.updateInstituteProvidedId(
            objToPush
          );
        await this.updateLogsForStudentDetails(objToPush, item);
        showStatus("Institute User ID Updated!", 1500, "success", this);
      } catch (err) {
        console.log(err);
      }
    },
    //action tracker
    async getSemesterUserlog() {
      let selectedDepartments = [];
      const tempArr = [];

      if (this.selection.length === 0) {
        showStatus("Please select Department to continue", 3000, "error", this);
        return;
      }
      this.selection.map((obj) => {
        selectedDepartments.push(obj.department);
      });
      const { logs } = await this.activityLoggerInstance.getSemesterUserLogs({
        instituteId: this.selectedInstitute,
        semId: this.PropsSelectedSemester.semId,
        departments: selectedDepartments,
        limit: this.numPreviousLogs,
      });
      for (let i = 0; i < logs.length; i++) {
        let actionType =
          logs[i].actionType === "create" ? "New User" : "Semester Users";
        let selectionFrom = logs[i].from ? logs[i].from : actionType;
        let selectionTo = logs[i].to
          ? logs[i].to
          : `${logs[i].department} |
          ${logs[i].courseYear ? logs[i].courseYear : ""} | ${
              logs[i].division ? logs[i].division : ""
            }`;
        tempArr.push({
          userName: logs[i].userName,
          selectMode: selectionFrom,
          selectedUserType: logs[i].selectedUserType,
          selection: selectionTo,
          actionType: logs[i].actionType,
          savedAt: this.formatISODate(logs[i].savedAt),
          savedBy: logs[i].savedBy,
        });
      }
      this.displayLogsArray = tempArr;
      this.showSemesterUserLogs = true;
    },
    async updateLogsForStudentDetails(objToPush, item) {
      objToPush.userName = item.name;
      objToPush.semId = item.semId;
      objToPush.department = item.department;
      objToPush.actionType = "update";
      objToPush.semesterLogType = "update";
      objToPush.selectedUserType = this.userType;
      objToPush.selectMode = "Semester Users";
      objToPush.savedBy = this.userData.firstName;
      objToPush.courseYear = item.courseYear;
      objToPush.division = item.division;

      await this.activityLoggerInstance.createLogForSingleUser(objToPush);
    },
    formatISODate(isoString) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      const date = new Date(isoString);
      if (date == "Invalid Date") return "-";
      const formattedDate = date.toLocaleString("en-US", options);

      return formattedDate;
    },
    gotoSemestersManagment() {
      this.$router.push({
        name: "activitySemestersManagment",
        params: {
          prop_semName: this.selectedSemester.semName,
        },
      });
    },
  },
};
</script>

<style scoped src="./activityEditSemesterUsers.css"></style>
