export const subjectTypes = [
  "Normal Theory",
  "Normal Practical",
  "Normal Tutorial",
  "Normal Audit Course",
  "Elective Theory",
  "Elective Practical",
  "Elective Tutorial",
  "Elective Audit Course",
  "Course Survey",
];

export const conductableLectureTypes = [0, 1, 3, 4];

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const lectureTypes = {
  ORDINARY: 0,
  PENDING: 1,
  RESCHEDULED: 2,
  RESCHEDULED_EXTRA: 3,
  REMEDIAL: 4,
  HOLIDAY: 5,
  CANCELLED: 6,
};

export const questionTypes = [
  "Simple Question",
  "Single Answer MCQ",
  "Multiselect MCQ",
];
export const questionTypesTemp = ["Single Answer MCQ"];

export const optionEnabled = ["Single Answer MCQ", "Multiselect MCQ"];

export const ownershipStatuses = [""];

export const instituteTypes = [""];

export const semesterTypes = ["Current", "Upcoming", "Past"];

export const backgroundImagesForSubjectCards = [
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20836.png?alt=media&token=daf02332-bf0f-4df3-a14e-f499d70504ee",
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20838.png?alt=media&token=5e3925a9-3cc8-49b7-a283-0db0c225fc4a",
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20840.png?alt=media&token=02190c7e-4364-42b7-9c7f-a0ab6cf90403",
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20842.png?alt=media&token=7f4c0e49-280c-4b34-911b-dc11563500fc",
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20844.png?alt=media&token=9e56e2e9-7508-48d1-8427-3cbe41d57c95",
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20846.png?alt=media&token=f27f7952-64cd-4b02-9991-b596ae9b8c92",
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20848.png?alt=media&token=a9b93115-58db-4cac-86b1-161839cd5742",
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20851.png?alt=media&token=220d525a-72d5-4480-9be7-b98391a63a8d",
  "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/subjectBackgrounds%2FGroup%20853.png?alt=media&token=87f86d3c-a048-4a78-bd31-6f7115a91ce4",
];

export const certificateTypes = [
  {
    text: "Provisional Certificate",
    value: 1,
  },
  {
    text: "Mark sheet",
    value: 2,
  },
  {
    text: "Grade sheet",
    value: 3,
  },
  {
    text: "Course year certificate",
    value: 4,
  },
  {
    text: "Transcript",
    value: 5,
  },
];

export const courseYearNumbers = {
  "First Year": 1,
  "Second Year": 2,
  "Third Year": 3,
  "Fourth Year": 4,
  "Fifth Year": 5,
  "Sixth Year": 6,
  "Seventh Year": 7,
};

export const courseYearNumbersAbbr = {
  "FY": 1,
  "SY": 2,
  "TY": 3
};

export const courseInitialStMiras = {
  "BCOM": 'C',
  "BA": 'A',
  "BSc": 'S',
  "BBA (CA)": 'T',
  "BBA": 'B',
};

export const currentCourseYearInitialStMiras = {
  "FY": 1,
  "SY": 2,
  "TY": 3
};

