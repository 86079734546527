<template src="./activityCreateFBForm.html"></template>
<script>
import VueApexCharts from "vue-apexcharts";
import SemesterRepository from "../../../Repository/Semester";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import UserRepository from "../../../Repository/User";
import SubjectsRepository from "../../../Repository/Subject";
import Feedback from "../../../Repository/Feedback";
import showStatus from "../../../NetworkManager/showStatus";
import PortalFooter from "../../../Components/PortalFooter";
import inputContainer from "../../../Components/inputContainer";
import { v4 as uuidv4 } from "uuid";
import nbaRepository from "../../../Repository/NBA";
import axios from "axios";
import valueAddedActivityRepository from "../../../Repository/ValueAddedActivity";

export default {
  name: "activityCreateFBForm",
  props: ["formState", "formId", "activeTab"],
  components: {
    PortalFooter,
    inputContainer,
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      newInput: "1",
      levels: [],
      formState: "PUBLISH",
      assignedDepartment: [],
      assignedCourseYearForSubAdmin: [],
      loading: false,
      isMobileView: false,
      userData: null,
      e1: 1,
      basicDetailsFormType: [
        "Course Exit Survey",
        "Exit Survey",
        "Alumini Survey",
        "Miscellaneous",
      ],
      questionType: ["Multiple Choice Question", "Short Answer", "Rating"],
      tabItems: [
        "Total Expected Responses",
        "Total Obtained Responses",
        "Total Un-obtained Responses",
      ],
      tabs: null,
      selectedFormType: "",
      selectedSemester: "",
      semsterList: [],
      selectedDepartment: "",
      departmentList: [],
      selectedCourseYear: "",
      courseYearList: [],
      selectedDivision: [],
      divisionList: [],
      selectedSubject: null,
      subjectList: [],
      formTitle: "",
      formDescription: "",
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      startDateMenu: false,
      endDateMenu: false,
      cbIsNBACriteriaAttainment: false,
      uniqueLink: "",
      conductionDetails: "",
      anonymous: "true", //true = anonymous student data, false = non anonymous student data
      levelOne: "",
      levelTwo: "",
      levelThree: "",
      uploadQuestionViaExcelDialog: false,
      btnAddQuestions: false,
      uploadQuestionViaManuallyDialog: false,
      addMultipleTypeQuestionDialog: false,
      editMode: false,
      editModeIndex: "",
      manualQuestionList: [],
      uploadQuestions: false,
      uploadQuestionsWithoutNBA: false,
      nbaRepositoryInstance: null,
      cosList: [],
      posList: [],
      psosList: [],
      coDialog: false,
      totalCos: [],
      mappedCOs: [],
      selectedSubjectID: [],
      questionsExcel: null,
      tempQuestions: [],
      studentBackdatedResponse: [],
      totalExpectedStudent: "",
      totalObtainedStudent: "",
      totalUnobtainedStudent: "",
      dialogShowAnalysis: false,
      tableHeaders: [
        {
          text: "PRN No.",
          align: "left",
          value: "prnNo",
        },
        {
          text: "Student Name",
          align: "left",
          value: "fullName",
          class: "DataTable",
        },
      ],
      expectedStdudentIdsArr: [],
      obtainedStudentIdsArr: [],
      unobtainedStudentIdsArr: [],
      expectedStudentList: [],
      obtainedStudentList: [],
      unobtainedStudentList: [],
      barLoader: false,
      barOptions: {
        series: [],
        dataLabels: {
          enabled: false,
        },
        noData: {
          text: "Responses not found.",
          align: "center",
          verticalAlign: "middle",
          style: {
            color: "red",
            fontSize: "16px",
          },
        },
        colors: ["#050992", "#0DA5A5"],
        chart: {
          type: "bar",
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "rounded",
            borderRadius: 10,
            distributed: true,
            columnWidth: "25%",
          },
        },
        yaxis: {
          title: {
            text: "No. of Responses",
            style: {
              color: "#8A8A9E",
              fontSize: "11px",
            },
          },
        },
        xaxis: {
          categories: ["Total Expected Responses", "Total Obtained Responses"],
          labels: {
            show: true,
          },
        },
        legend: {
          show: false,
        },
        toolbar: {
          export: {
            csv: {
              filename: "Responses analysis",
            },
            svg: {
              filename: "Responses analysis",
            },
            png: {
              filename: "Responses analysis",
            },
          },
        },
      },
      nbaMcqResponsesOption: {
          series: [],
          legend: {
              show: true,
              showForSingleSeries: false,
              showForNullSeries: true,
              showForZeroSeries: true,
              position: 'bottom',
              horizontalAlign: 'center',
              floating: false,
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial',
              fontWeight: 400,
              inverseOrder: false,
              offsetX: 0,
              offsetY: 0,
              labels: {
                  colors: undefined,
                  useSeriesColors: false
              },
              markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 0,
                  strokeColor: '#fff',
                  fillColors: undefined,
                  radius: 12,
                  offsetX: 0,
                  offsetY: 0
              },
              itemMargin: {
                  horizontal: 5,
                  vertical: 0
              },
              onItemClick: {
                  toggleDataSeries: true
              },
              onItemHover: {
                  highlightDataSeries: true
              }
          },
          chart: {
              id: "chartId",
              type: "bar",
              height: 450,
              width: '100%',
              toolbar: {
                  show: true
              },
              animations: {
                  enabled: true,
                  easing: 'easeinout',
                  speed: 800,
              },
          },
          responsive: [{
              breakpoint: 1000,
              options: {
                  chart: {
                      width: '100%',
                      height: 'auto'
                  }
              }
          }],
          plotOptions: {
              bar: {
                  horizontal: false,
                  columnWidth: "55%",
                  endingShape: "rounded",
                  borderRadius: 5,
              },
          },
          colors: [
              "#FFE7B4", "#D6B4DF", "#B4DFDF", "#B4C3DF", "#FFCBBC", 
              "#DFEAB3", "#FFF9C1", "#BAE1FE", "#D4F2FA", "#C0D7DC", 
              "#D5F1D5", "#C9ECE9", "#CDCDCD", "#F4D5E4", "#FFE7B4", 
              "#D6B4DF", "#B4DFDF", "#B4C3DF", "#FFCBBC", "#DFEAB3", 
              "#FFF9C1", "#BAE1FE", "#D4F2FA", "#C0D7DC", "#D5F1D5", 
              "#C9ECE9", "#CDCDCD", "#F4D5E4"
          ],
          dataLabels: {
              enabled: true,
              style: {
                  colors: ['#000']
              }
          },
          stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
          },
          xaxis: {
              categories: [],
          },
          yaxis: {
              title: {
                  text: "No. of responses",
              },
          },
          fill: {
              opacity: 1,
          },
          tooltip: {
              y: {
                  formatter: function (val) {
                      return val + " responses";
                  },
              },
          },
      },
      mcqResponsesOption: {
        series: [],
        chart: {
          type: "bar",
          height: 450,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
            borderRadius: 5,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        legend: {
          show: true,
        },
        colors: ["#080892", "#00ABA7", "#FF7800", "#006438", "#000"],
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "No. of responses",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " response";
            },
          },
        },
      },
      demoExcelBackdated: "",
      noMcqQue: 0,
      noShortAnsQue: 0,
      noRatingQue: 0,
      avgMcqQues: 0,
      avgShortAnsQues: 0,
      avgRatingQues: 0,
      stdShAnsResQuestion: [],
      tdShAnsResResponse: [],
      stdRatingResCount: [],
      analysisSelectedQuestion: null,
      analysisSelectedStudent: null,
      analysisSelectedViewFullResponse: null,
      selectedRatingQuestion: "",
      dialogRatingQuestionAnalysis: false,
      dialogCompleteAnalysis: false,
      prnArrList: [],
      outcomeMapping: "",
      assignedPOArray: [],
      assignedCOArray: [],
      assignedPSOArray: [],
      coDialog: false,
      poDialog: false,
      psoDialog: false,
      optionsLevel: ["Program", "Course"],
      selectedOptionLevel: "",
      fbFormLoader: false,
      showCoColumn: false,
      showPoPsoColumn: false,
      totalShortAnsQuestions: [],
      totalRatingQuestions: [],
      totalMcqQuestions: [],
      showQuestionDialog: false,
      selectedQuestionType: "",
      showRatingResponseDialog: false,
      ratingQuestionToShow: {},
      ratingQuestionAvg: "",
      totalRatingResponseReceived: 0,
      individualResponseKey: false,
      tempArr: [],
      valueAddedActivities: [],
      selectedValueAddedActivity: {},
      downloadReportLoader: false,
      allStudentDetails: [],
      allFeedbackResponses: [],
      currentFeedbackFormDetails: {},
      threshold: 2,
    };
  },
  methods: {
    downloadChart() {
      this.$refs.chart.exportChart({
        type: 'png',
        filename: 'chart',
        options: {
          chart: {
            id: 'bar-chart',
            foreColor: '#000', // Ensure labels are visible
          },
        },
      });
    },
  },
  mounted() {
    document.getElementById('downloadChart').addEventListener('click', () => {
      ApexCharts.exec("chartId", "dataURI").then(({ imgURI, blob }) => {
        let a = document.createElement('a');
        a.href = imgURI;
        a.download = 'chart.png';
        a.click();
      });
    });
  },
  // this.valueAddedActivityInstance.getValueAddedActivityAll instituteId semId department
  created() {
    this.updateLevels();
    this.assignedDepartment =
      this.$store.getters[
        "liveData/get_accessibleDepartment"
      ].accessibleDepartment;
    this.uId = this.$store.getters["user/get_userData"].uId;
    this.assignedCourseYearForSubAdmin =
      this.$store.getters["liveData/get_accessibleCourseYearForSubAdmin"]
        ?.accessibleCourseYearForSubAdmin || [];
    this.selectedTopic = this.$store.getters["liveData/get_selectedTopic"];

    if (this.formState === "EDIT" || this.formState === "PUBLISH") {
      this.$store.commit(
        "liveData/set_selectedActivityName",
        "Edit Feedback Form"
      );
    } else {
      this.$store.commit(
        "liveData/set_selectedActivityName",
        "New Feedback Form"
      );
    }

    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];

    this.userData = this.$store.getters["user/get_userData"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];

    this.nbaRepositoryInstance = new nbaRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.feedbackRepositoryInstance = new Feedback(this);
    this.valueAddedActivityInstance = new valueAddedActivityRepository(this);

    if (this.formState === "CREATE") {
      this.uniqueLink = "https://feedbacks.studiumtech.in/" + uuidv4();
    }
    if (this.formState === "PUBLISH") {
      //Go to direct tab 3 analysis
      this.e1 = this.activeTab;
      // if(this.activeTab === 3){
      //     this.handleNextClickFormOne()
      // }
    }

    this.getAllSemster(this.selectedInstitute);
  },
  methods: {
    updateLevels() {
      const count = Math.max(parseInt(this.newInput) || 1, 1);
      this.levels = Array.from({ length: count }, (_, index) => ({
        value: "",
      }));
    },
    async downloadFeedbackReport() {
      this.downloadReportLoader = true;
      for (let index = 0; index < this.allFeedbackResponses.length; index++) {
        const resStudent = this.allFeedbackResponses[index];

        if (resStudent.userId) {
          const student = this.allStudentDetails.filter(
            (item) => item.uId === resStudent.userId
          )[0];
          const fullName =
            student.firstName + student.middleName + student.lastName;
          resStudent.candidateEmailId = student.email;
          (resStudent.candidateFullName = fullName),
            (resStudent.rollNo = student.rollNo);
        }
      }
      let objToPush = {
        questionDetails: this.currentFeedbackFormDetails.questions,
        studentDetails: this.allFeedbackResponses,
      };
      const result =
        await this.feedbackRepositoryInstance.generateFeedbackResponseExcel(
          objToPush
        );
      if (result.data) {
        const pdfBlob2 = new Blob([result.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(pdfBlob2);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${this.formTitle}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
      this.downloadReportLoader = false;
    },
    async getValueAddedActivities() {
      let allowedCourseYearForSelectedDepartment = [];
      if (this.assignedCourseYearForSubAdmin.length > 0) {
        this.assignedCourseYearForSubAdmin.map((cy) => {
          let [course, department1] = cy.split("_");
          if (department1 === this.selectedDepartment) {
            allowedCourseYearForSelectedDepartment.push(course);
          }
        });
        this.courseYearList = allowedCourseYearForSelectedDepartment;
      }
      this.valueAddedActivities = [];
      const res =
        await this.valueAddedActivityInstance.getValueAddedActivityAll({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
        });
      if (
        this.selectedFormType === "Course Exit Survey" ||
        (this.selectedFormType === "Miscellaneous" &&
          this.selectedOptionLevel === "Course")
      ) {
        this.valueAddedActivities = res.activity.filter(
          (item) => item.levelOfActivity === "Course Level"
        );
      } else {
        this.valueAddedActivities = res.activity.filter(
          (item) => item.levelOfActivity === "Program Level"
        );
      }
    },
    handleValueAddedActivity() {
      if (this.selectedValueAddedActivity.levelOfActivity === "Course Level") {
        this.assignedCOArray = [
          ...this.selectedValueAddedActivity.valueAddedCO,
        ];
        this.outcomeMapping = "overall";
      } else {
        this.assignedPOArray = [
          ...this.selectedValueAddedActivity.mapActivityPO,
        ];
        this.assignedPSOArray = [
          ...this.selectedValueAddedActivity.mapActivityPSO,
        ];
        this.outcomeMapping = "overall";
      }
    },
    activity() {
      this.$tours["activityCreateFbForm"].start();
    },
    gotoBack() {
      this.$router.push("/activityFBForm");
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.uniqueLink);
      showStatus("Copied to clipboard.", 3000, "success", this);
    },
    handleFormTypeSelect() {
      // this.getAllSemster(this.selectedInstitute)
    },
    async getAllSemster(instituteId) {
      try {
        const objToPush = {
          instituteId: instituteId,
        };
        const res = await this.semesterRepositoryInstance.getSemesterNames(
          objToPush
        );
        this.semsterList = res;
        if (this.formState === "EDIT" || this.formState === "PUBLISH") {
          this.getFormDetails(this.formId);
        }
      } catch (err) {
        showStatus(
          "An unknown error occurred, try later or contact admin",
          6000,
          "error",
          this
        );
      }
    },
    handleSemsterSelect() {
      this.getAllDepartment(
        this.selectedInstitute,
        this.selectedSemester.semId
      );
      this.valueAddedActivities = [];
      this.departmentList = [];
      this.divisionList = [];
      this.subjectList = [];
      this.courseYearList = [];
      this.selectedValueAddedActivity = "";
      this.selectedDepartment = "";
      this.selectedCourseYear = "";
      this.selectedDivision = [];
      this.selectedOptionLevel = "";
      this.selectedSubject = "";
    },
    async getAllDepartment(instituteId, semId) {
      try {
        const objToPush = {
          instituteId: instituteId,
          semId: semId,
        };
        const res =
          await this.semesterRepositoryInstance.getDivisionsOfASemester(
            objToPush
          );
        res
          ? res.map((item) => {
              this.courseYearList.push(item);
              this.divisionList.push(item);
              this.departmentList.push(item.department);
            })
          : null;
        let subAdminAssignedDepartmentWiseLecture = [];
        if (this.assignedDepartment?.length > 0) {
          this.departmentList.map((data) => {
            if (this.assignedDepartment.includes(data)) {
              subAdminAssignedDepartmentWiseLecture.push(data);
            }
          });
          this.departmentList = subAdminAssignedDepartmentWiseLecture;
        }
      } catch (err) {
        showStatus(
          "An unknown error occurred, try later or contact admin",
          6000,
          "error",
          this
        );
        console.error("err12", err);
      }
    },
    handleDivisionSelect() {
      this.getAllSubjects(
        this.selectedInstitute,
        this.selectedSemester.semId,
        this.selectedDepartment,
        this.selectedCourseYear
      );
    },
    async getAllSubjects(instituteId, semId, dept, courseYr) {
      try {
        const res =
          await this.subjectsRepositoryInstance.getSubjectsOfACourseYearInASemester(
            {
              instituteId: instituteId,
              semId: semId,
              department: dept,
              courseYear: courseYr,
            }
          );
        res
          ? res.map((item) => {
              this.subjectList.push(item);
            })
          : null;

        if (this.formState === "EDIT" || this.formState === "PUBLISH") {
          this.subjectList.map((item, index) => {
            if (item.subjectId === this.selectedSubjectID) {
              this.selectedSubject = Object.create(item);
            }
          });
        }
      } catch (err) {
        showStatus(err, 6000, "error", this);
      }
    },
    handleOutcomeMapping() {
      if (this.outcomeMapping === "overall") {
        if (
          this.selectedFormType === "Course Exit Survey" ||
          (this.selectedFormType === "Miscellaneous" &&
            this.selectedOptionLevel === "Course")
        ) {
          this.showCoColumn = false;
        } else {
          this.showPoPsoColumn = false;
        }
      } else {
        if (
          this.selectedFormType === "Course Exit Survey" ||
          (this.selectedFormType === "Miscellaneous" &&
            this.selectedOptionLevel === "Course")
        ) {
          this.showCoColumn = true;
        } else {
          this.showPoPsoColumn = true;
        }
      }
    },
    addQuestionManullyTempArray() {
      if (
        this.selectedFormType === "Course Exit Survey" ||
        (this.selectedFormType === "Miscellaneous" &&
          this.selectedOptionLevel === "Course")
      ) {
        this.manualQuestionList.push({ dialog: false, selectedCOs: [] });
        if (this.outcomeMapping === "individual") {
          this.showCoColumn = true;
        }
      } else {
        this.manualQuestionList.push({
          podialog: false,
          psodialog: false,
          selectedPOs: [],
          selectedPSOs: [],
        });
        if (this.outcomeMapping === "individual") {
          this.showPoPsoColumn = true;
        }
      }
    },
    async handleSubmitSaveFeedbackAsDraft() {
      this.fbFormLoader = true;
      let nbaAttainmentLevels = [];
      nbaAttainmentLevels = [...this.levels.map((level) => level.value)];
      if (this.formState === "CREATE") {
        let objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          formType: this.selectedFormType,
          formURL: this.uniqueLink.split("/").slice(-1)[0],
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.selectedDivision,
          // subjectId: this.selectedSubject.subjectId,
          // subjectName: this.selectedSubject.subjectName,
          formTitle: this.formTitle,
          formDescription: this.formDescription,
          startDate: this.startDate,
          endDate: this.endDate,
          anonymous: this.anonymous,
          conductionDetails: this.conductionDetails,
          isNBACriteriaAttainment: this.cbIsNBACriteriaAttainment,
          nbaAttainmentLevels: nbaAttainmentLevels,
          questions: this.manualQuestionList,
          isPublish: false,
          studentBackdatedResponse: this.studentBackdatedResponse,
        };
        if (this.selectedFormType === "Course Exit Survey") {
          objToPush["subjectId"] = this.selectedSubject.subjectId;
          objToPush["subjectName"] = this.selectedSubject.subjectName;
        }
        if (this.selectedFormType === "Miscellaneous") {
          if (this.selectedOptionLevel === "Course") {
            objToPush["subjectId"] = this.selectedSubject.subjectId;
            objToPush["subjectName"] = this.selectedSubject.subjectName;
            objToPush["optionLevel"] = this.selectedOptionLevel;
          } else {
            objToPush["optionLevel"] = this.selectedOptionLevel;
          }
        }
        if (this.outcomeMapping === "overall") {
          if (
            this.selectedFormType === "Course Exit Survey" ||
            (this.selectedFormType === "Miscellaneous" &&
              this.selectedOptionLevel === "Course")
          ) {
            objToPush["assignedCOArray"] = this.assignedCOArray;
            objToPush["subjectName"] = this.selectedSubject.subjectName;
            objToPush["subjectId"] = this.selectedSubject.subjectId;
          } else {
            objToPush["assignedPOArray"] = this.assignedPOArray;
            objToPush["assignedPSOArray"] = this.assignedPSOArray;
          }
        }
        try {
          const res = await this.feedbackRepositoryInstance.createFeedbackForm(
            objToPush
          );

          this.formState = "EDIT";
          showStatus("Form saved in draft", 6000, "success", this);
          this.fbFormLoader = false;
        } catch (err) {
          showStatus(err, 6000, "error", this);
          this.fbFormLoader = false;
        }
      } else {
        let objToPush = {
          feedbackID: this.formId,
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          formType: this.selectedFormType,
          formURL: this.uniqueLink.split("/").slice(-1)[0],
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.selectedDivision,
          formTitle: this.formTitle,
          formDescription: this.formDescription,
          startDate: this.startDate,
          endDate: this.endDate,
          conductionDetails: this.conductionDetails,
          anonymous: this.anonymous,
          isNBACriteriaAttainment: this.cbIsNBACriteriaAttainment,
          nbaAttainmentLevels: nbaAttainmentLevels,
          questions: this.manualQuestionList,
          isPublish: false,
          studentBackdatedResponse: this.studentBackdatedResponse,
          threshold: this.threshold,
        };
        if (this.selectedFormType === "Course Exit Survey") {
          objToPush["subjectId"] = this.selectedSubject.subjectId;
          objToPush["subjectName"] = this.selectedSubject.subjectName;
        }
        if (this.selectedFormType === "Miscellaneous") {
          if (this.selectedOptionLevel === "Course") {
            objToPush["subjectId"] = this.selectedSubject.subjectId;
            objToPush["subjectName"] = this.selectedSubject.subjectName;
            objToPush["optionLevel"] = this.selectedOptionLevel;
          } else {
            objToPush["optionLevel"] = this.selectedOptionLevel;
          }
        }
        if (this.outcomeMapping === "overall") {
          if (this.selectedFormType === "Course Exit Survey") {
            objToPush["assignedCOArray"] = this.assignedCOArray;
            objToPush["subjectId"] = this.selectedSubject.subjectId;
            objToPush["subjectName"] = this.selectedSubject.subjectName;
          } else {
            objToPush["assignedPOArray"] = this.assignedPOArray;
            objToPush["assignedPSOArray"] = this.assignedPSOArray;
          }
        }
        try {
          const res = await this.feedbackRepositoryInstance.updateFeedbackForm(
            objToPush
          );

          showStatus("Form saved in draft", 6000, "success", this);
          this.fbFormLoader = false;
        } catch (err) {
          showStatus(err, 6000, "error", this);
          this.fbFormLoader = false;
        }
      }
    },
    deleteQuestionFromManualTempList(qNo) {
      this.manualQuestionList.splice(qNo, 1);
    },
    async getCOsList() {
      try {
        this.cosList = [];

        const nbaData = await this.nbaRepositoryInstance.getCos({
          lmsInstituteId: this.selectedInstitute,
          semesterId: this.selectedSemester.semId,
          subjectId: this.selectedSubject.subjectId,
        });
        if (Array.isArray(nbaData.data)) {
          nbaData.data.forEach((nba, i) => {
            this.cosList.push({
              Id: nba._id,
              coNumber: nba.CONumber,
              coName: nba.COName,
            });
          });
        }
      } catch (err) {
        console.error(err);
        // alert(err.message)
      }
    },
    async getPOsList() {
      try {
        this.posList = [];

        const nbaData = await this.nbaRepositoryInstance.getPos({
          lmsInstituteId: this.selectedInstitute,
          semesterId: this.selectedSemester.semId,
          department: this.selectedDepartment,
        });
        if (Array.isArray(nbaData.data)) {
          nbaData.data.forEach((nba, i) => {
            this.posList.push({
              Id: nba._id,
              poNumber: nba.poNumber,
              poName: nba.poName,
            });
          });
        }
      } catch (err) {
        console.error(err);
        // alert(err.message)
      }
    },
    removeMappedCo(index) {
      this.assignedCOArray.splice(index, 1);
    },
    removeMappedPso(index) {
      this.assignedPSOArray.splice(index, 1);
    },
    removeMappedPo(index) {
      this.assignedPOArray.splice(index, 1);
    },
    async getPSOsList() {
      try {
        this.psosList = [];

        const nbaData = await this.nbaRepositoryInstance.getPsos({
          lmsInstituteId: this.selectedInstitute,
          semesterId: this.selectedSemester.semId,
          department: this.selectedDepartment,
        });
        if (Array.isArray(nbaData.data)) {
          nbaData.data.forEach((nba, i) => {
            this.psosList.push({
              Id: nba._id,
              psoNumber: nba.psoNumber,
              psoName: nba.psoName,
            });
          });
        }
      } catch (err) {
        console.error(err);
        // alert(err.message)
      }
    },
    removeCo(i, j) {
      this.manualQuestionList[i].selectedCOs.splice(j, 1);
    },
    removePo(i, j) {
      this.manualQuestionList[i].selectedPOs.splice(j, 1);
    },
    removePSo(i, j) {
      this.manualQuestionList[i].selectedPSOs.splice(j, 1);
    },
    handleUploadQuestions() {
      this.uploadQuestionViaManuallyDialog = false;
      this.uploadQuestions = true;
      // if (this.cbIsNBACriteriaAttainment)
      //     this.getCOsList()
      // this.getPOsList()
      // this.getPSOsList()
    },
    handleAddQuestion() {
      this.tempQuestions = [];
      if (this.cbIsNBACriteriaAttainment)
        this.uploadQuestionViaManuallyDialog = true;
      else {
        this.tempQuestions.push({ questionType: "", options: [] });
        this.addMultipleTypeQuestionDialog = true;
      }
    },
    handleEditQuestion(item, i) {
      this.tempQuestions.push(item);
      this.editMode = true;
      this.editModeIndex = i;
      this.addMultipleTypeQuestionDialog = true;
    },
    handleDeleteQuestion(i) {
      this.manualQuestionList.splice(i, 1);
    },
    handleAddOptions() {
      if (this.tempQuestions[0].options.length < 5)
        this.tempQuestions[0].options.push({ option: "" });
      else
        showStatus(
          "Sorry! You reached your max limit to add options",
          6000,
          "error",
          this
        );
    },
    handleAddQuestionsDialogRes() {
      let flag = false;
      if (this.tempQuestions[0].questionType === "Multiple Choice Question") {
        if (
          !this.tempQuestions[0].question ||
          this.tempQuestions[0].options.length == 0
        ) {
          showStatus("Please Fill blank field", 6000, "error", this);
          flag = true;
        }
      } else if (
        this.tempQuestions[0].questionType === "Short Answer" ||
        this.tempQuestions[0].questionType === "Rating"
      ) {
        if (!this.tempQuestions[0].question) {
          showStatus("Please Fill blank field", 6000, "error", this);
          flag = true;
        }
      } else {
        flag = true;
        showStatus(
          "Please select question type and all required fields",
          6000,
          "error",
          this
        );
      }

      if (!flag) {
        if (this.editMode) {
          if (
            this.tempQuestions[0].questionType === "Multiple Choice Question"
          ) {
            this.manualQuestionList[this.editModeIndex].question =
              this.tempQuestions[0].question;
            this.manualQuestionList[this.editModeIndex].questionType =
              this.tempQuestions[0].questionType;
            this.manualQuestionList[this.editModeIndex].options =
              this.tempQuestions[0].options;
          } else {
            this.manualQuestionList[this.editModeIndex].question =
              this.tempQuestions[0].question;
            this.manualQuestionList[this.editModeIndex].questionType =
              this.tempQuestions[0].questionType;
          }
          this.editMode = false;
          this.editModeIndex = "";
        } else {
          if (
            this.tempQuestions[0].questionType === "Multiple Choice Question"
          ) {
            this.manualQuestionList.push({
              question: this.tempQuestions[0].question,
              questionType: this.tempQuestions[0].questionType,
              options: this.tempQuestions[0].options,
            });
          } else {
            this.manualQuestionList.push({
              question: this.tempQuestions[0].question,
              questionType: this.tempQuestions[0].questionType,
            });
          }
        }
        this.uploadQuestionsWithoutNBA = true;
        this.addMultipleTypeQuestionDialog = false;
        this.tempQuestions = [];
      }
    },
    handleNextClickFormOne() {
      if (this.formState === "PUBLISH") {
        if (!this.cbIsNBACriteriaAttainment) {
          this.uploadQuestionsWithoutNBA = true;
          this.uploadQuestions = false;
        }
        this.e1 = 2;
        this.getCOsList();
        this.getPOsList();
        this.getPSOsList();
      } else if (
        this.selectedFormType &&
        this.selectedSemester &&
        this.selectedDepartment &&
        this.selectedCourseYear &&
        this.selectedDivision.length > 0 &&
        this.formTitle &&
        this.conductionDetails
      ) {
        if (this.cbIsNBACriteriaAttainment) {
          if (this.levels.every((level) => level.value.trim() !== "")) {
            this.uploadQuestionsWithoutNBA = false;
            this.e1 = 2;
            this.getCOsList();
            this.getPOsList();
            this.getPSOsList();
          } else {
            showStatus("Fill levels", 6000, "error", this);
          }
        } else {
          this.uploadQuestions = false;
          this.uploadQuestionsWithoutNBA = true;
          this.e1 = 2;
          this.getCOsList();
          this.getPOsList();
          this.getPSOsList();
        }
      } else {
        showStatus("Fill blank fields", 6000, "error", this);
      }
    },
    async getAllStudentDetails() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
      };
      let res = (
        await this.semesterUserRepositoryInstance.getStudentsOfACourseYear(
          objToPush
        )
      ).filter((user) => this.selectedDivision.includes(user.division));
      const uIds = [];
      for (let index = 0; index < res.length; index++) {
        const student = res[index];
        uIds.push(student.uId);
      }
      this.allStudentDetails = await this.userRepositoryInstance.getUsers({
        uIds: uIds,
      });
    },
    async getUserIds(flag) {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
        };
        let res = (
          await this.semesterUserRepositoryInstance.getStudentsOfACourseYear(
            objToPush
          )
        ).filter((user) => this.selectedDivision.includes(user.division));

        let studentIds = [];

        res.map((item, index) => {
          studentIds.push(item.uId);
        });
        this.getPrnNo(studentIds, flag);
      } catch (err) {
        showStatus(err, 6000, "error", this);
      }
    },
    async getPrnNo(arrList, flag) {
      this.prnArrList = [];
      try {
        const res = await this.userRepositoryInstance.getUsers({
          uIds: arrList,
        });
        res.map((item, index) => {
          if (item.collegePRNNo == 0 || item.collegePRNNo == undefined) {
            this.prnArrList.push({ uId: item.uId, prnNo: "-" });
          } else {
            this.prnArrList.push({ uId: item.uId, prnNo: item.collegePRNNo });
          }
        });
        if (flag === "true") {
          this.generateDemoExcel(this.prnArrList);
        }
      } catch (err) {
        showStatus(err, 6000, "error", this);
      }
    },
    async generateDemoExcel(arrList) {
      try {
        const pdfBlob = await this.feedbackRepositoryInstance.generateDemoExcel(
          {
            studentData: arrList,
          }
        );
        const pdfBlob2 = new Blob([pdfBlob.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(pdfBlob2);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "demo_excel_backdated.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        showStatus(err, 6000, "error", this);
      }
    },
    async handlePublishClickFormTwo() {
      let nbaAttainmentLevels = [];
      nbaAttainmentLevels = [...this.levels.map((level) => level.value)];
      let objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        formType: this.selectedFormType,
        formURL: this.uniqueLink.split("/").slice(-1)[0],
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        division: this.selectedDivision,
        // subjectId: this.selectedSubject.subjectId,
        // subjectName: this.selectedSubject.subjectName,
        formTitle: this.formTitle,
        formDescription: this.formDescription,
        startDate: this.startDate,
        endDate: this.endDate,
        conductionDetails: this.conductionDetails,
        anonymous: this.anonymous,
        isNBACriteriaAttainment: this.cbIsNBACriteriaAttainment,
        nbaAttainmentLevels: nbaAttainmentLevels,
        questions: this.manualQuestionList,
        isPublish: true,
        studentBackdatedResponse: this.studentBackdatedResponse,
        outcomeMapping: this.outcomeMapping,
        threshold: this.threshold,
      };
      if (this.selectedFormType === "Course Exit Survey") {
        objToPush["subjectId"] = this.selectedSubject.subjectId;
        objToPush["subjectName"] = this.selectedSubject.subjectName;
      }
      if (this.selectedFormType === "Miscellaneous") {
        if (this.selectedOptionLevel === "Course") {
          objToPush["subjectId"] = this.selectedSubject.subjectId;
          objToPush["subjectName"] = this.selectedSubject.subjectName;
          objToPush["optionLevel"] = this.selectedOptionLevel;
        } else {
          objToPush["optionLevel"] = this.selectedOptionLevel;
        }
      }
      if (this.outcomeMapping === "overall") {
        if (
          this.selectedFormType === "Course Exit Survey" ||
          (this.selectedFormType === "Miscellaneous" &&
            this.selectedOptionLevel === "Course")
        ) {
          objToPush["assignedCOArray"] = this.assignedCOArray;
          objToPush["subjectId"] = this.selectedSubject.subjectId;
          objToPush["subjectName"] = this.selectedSubject.subjectName;
        } else {
          objToPush["assignedPOArray"] = this.assignedPOArray;
          objToPush["assignedPSOArray"] = this.assignedPSOArray;
        }
      }
      if (this.formState === "CREATE") {
        try {
          const res = await this.feedbackRepositoryInstance.createFeedbackForm(
            objToPush
          );
          this.$router.push("/activityFBForm");
          this.formState = "PUBLISH";
          showStatus("Form published successfully", 6000, "success", this);
        } catch (err) {
          showStatus(err, 6000, "error", this);
        }
      } else if (this.formState === "EDIT") {
        this.formState = "PUBLISH";
        let objToPush = {
          feedbackID: this.formId,
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          formType: this.selectedFormType,
          formURL: this.uniqueLink.split("/").slice(-1)[0],
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.selectedDivision,
          // subjectId: this.selectedSubject.subjectId,
          // subjectName: this.selectedSubject.subjectName,
          formTitle: this.formTitle,
          formDescription: this.formDescription,
          startDate: this.startDate,
          endDate: this.endDate,
          conductionDetails: this.conductionDetails,
          anonymous: this.anonymous,
          isNBACriteriaAttainment: this.cbIsNBACriteriaAttainment,
          nbaAttainmentLevels: nbaAttainmentLevels,
          questions: this.manualQuestionList,
          isPublish: true,
          studentBackdatedResponse: this.studentBackdatedResponse,
          outcomeMapping: this.outcomeMapping,
          threshold: this.threshold,
        };
        if (this.selectedFormType === "Course Exit Survey") {
          objToPush["subjectId"] = this.selectedSubject.subjectId;
          objToPush["subjectName"] = this.selectedSubject.subjectName;
        }
        if (this.selectedFormType === "Miscellaneous") {
          if (this.selectedOptionLevel === "Course") {
            objToPush["subjectId"] = this.selectedSubject.subjectId;
            objToPush["subjectName"] = this.selectedSubject.subjectName;
            objToPush["optionLevel"] = this.selectedOptionLevel;
          } else {
            objToPush["optionLevel"] = this.selectedOptionLevel;
          }
        }
        if (this.outcomeMapping === "overall") {
          if (
            this.selectedFormType === "Course Exit Survey" ||
            (this.selectedFormType === "Miscellaneous" &&
              this.selectedOptionLevel === "Course")
          ) {
            objToPush["assignedCOArray"] = this.assignedCOArray;
            objToPush["subjectId"] = this.selectedSubject.subjectId;
            objToPush["subjectName"] = this.selectedSubject.subjectName;
          } else {
            objToPush["assignedPOArray"] = this.assignedPOArray;
            objToPush["assignedPSOArray"] = this.assignedPSOArray;
          }
        }

        if (this.selectedFormType === "Miscellaneous") {
          objToPush["optionLevel"] = this.selectedOptionLevel;
        }
        try {
          const res = await this.feedbackRepositoryInstance.updateFeedbackForm(
            objToPush
          );

          this.$router.push("/activityFBForm");
          showStatus("Form Published successfully", 6000, "success", this);
        } catch (err) {
          showStatus(err, 6000, "error", this);
        }
        //disabled published button
      } else if (this.formState === "PUBLISH") {
        this.e1 = 3;
        this.getAnalysis();
        // if (this.selectedFormType === 'Course Exit Survey') {
        //     this.getExpectedStudentList(this.selectedSemester.semId, this.selectedDepartment, this.selectedCourseYear, this.selectedDivision, this.selectedSubjectID)
        // } else if (this.selectedFormType === 'Miscellaneous') {
        //     if (this.selectedOptionLevel === 'Course') {
        //         this.getExpectedStudentList(this.selectedSemester.semId, this.selectedDepartment, this.selectedCourseYear, this.selectedDivision, this.selectedSubjectID)
        //     } else {
        //         this.getObtainedStudentList()
        //     }
        // } else {
        //     this.getObtainedStudentList()
        // }
      }
    },
    async getAnalysis() {
      this.getAllStudentDetails();
      if (this.selectedFormType === "Course Exit Survey") {
        this.getExpectedStudentList(
          this.selectedSemester.semId,
          this.selectedDepartment,
          this.selectedCourseYear,
          this.selectedDivision,
          this.selectedSubjectID
        );
      } else if (this.selectedFormType === "Miscellaneous") {
        if (this.selectedOptionLevel === "Course") {
          this.getExpectedStudentList(
            this.selectedSemester.semId,
            this.selectedDepartment,
            this.selectedCourseYear,
            this.selectedDivision,
            this.selectedSubjectID
          );
        } else {
          this.getObtainedStudentList();
        }
      } else {
        this.getObtainedStudentList();
      }
    },
    async getFormDetails(id) {
      try {
        const res =
          await this.feedbackRepositoryInstance.getFeedbackDetailsByFeedbackId({
            feedbackId: id,
          });
        this.levels = res[0].nbaAttainmentLevels;
        this.levels = this.levels.map((value) => ({ value }));
        this.newInput = this.levels.length;
        const data = res;
        this.currentFeedbackFormDetails = data[0];
        this.getAllDepartment(data[0].instituteId, data[0].semId);
        this.getAllSubjects(
          data[0].instituteId,
          data[0].semId,
          data[0].department,
          data[0].courseYear
        );
        this.selectedFormType = data[0].formType;
        this.uniqueLink = "https://feedbacks.studiumtech.in/" + data[0].formURL;

        this.semsterList.map((item, index) => {
          if (item.semId === data[0].semId) {
            this.selectedSemester = Object.create(item);
          }
        });
        this.selectedDepartment = data[0].department;
        this.selectedCourseYear = data[0].courseYear;
        this.selectedDivision = data[0].division;
        this.formTitle = data[0].formTitle;
        this.formDescription = data[0].formDescription;
        this.startDate = data[0].startDate;
        this.endDate = data[0].endDate;
        this.conductionDetails = data[0].conductionDetails;
        this.cbIsNBACriteriaAttainment = data[0].isNBACriteriaAttainment;
        this.anonymous = data[0].anonymous ? "true" : "false";
        this.manualQuestionList = data[0].questions;
        this.outcomeMapping = data[0].outcomeMapping;
        this.selectedSubjectID = data[0].subjectId;
        this.selectedSubject = data[0].subjectName;
        this.selectedOptionLevel = data[0].optionLevel;
        if (data[0].outcomeMapping === "overall") {
          if (data[0].formType === "Course Exit Survey") {
            this.assignedCOArray = data[0].assignedCOArray;
          } else {
            this.assignedPOArray = data[0].assignedPOArray;
            this.assignedPSOArray = data[0].assignedPSOArray;
          }
        }
        if (
          this.selectedFormType === "Course Exit Survey" ||
          (this.selectedFormType === "Miscellaneous" &&
            this.selectedOptionLevel === "Course")
        ) {
          if (this.outcomeMapping === "individual") {
            this.showCoColumn = true;
          }
        } else {
          if (this.outcomeMapping === "individual") {
            this.showPoPsoColumn = true;
          }
        }
        if (this.manualQuestionList.length > 0) {
          this.btnAddQuestions = true;
          this.uploadQuestions = true;
        }
        if (data[0].isNBACriteriaAttainment && data[0].isPublish) {
          let tempOpt = [];
          tempOpt = res[0].nbaAttainmentLevels;
          this.manualQuestionList.map((obj, i) => {
            obj["options"] = tempOpt;
          });
        }
        if (this.formState === "PUBLISH" && this.e1 === 3) {
          this.handlePublishClickFormTwo();
        }
      } catch (err) {
        showStatus(err, 6000, "error", this);
      }
    },
    async parseExcel() {
      if (
        this.selectedFormType === "Course Exit Survey" ||
        (this.selectedFormType === "Miscellaneous" &&
          this.selectedOptionLevel === "Course")
      ) {
        this.getCOsList();
      }
      if (
        this.cbIsNBACriteriaAttainment &&
        this.conductionDetails === "Upcoming"
      ) {
        //Parse Excel For Feedback With NBA Attainment and Upcoming
        if (this.questionsExcel) {
          var formData = new FormData();
          formData.append("excel", this.questionsExcel);
          axios
            .post(
              process.env.VUE_APP_SERVER_URL +
                process.env.VUE_APP_API_PORT +
                "/excel/feedback/parse",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              response.data.questionsArray.map((d, i) => {
                if (
                  this.selectedFormType === "Course Exit Survey" ||
                  (this.selectedFormType === "Miscellaneous" &&
                    this.selectedOptionLevel === "Course")
                ) {
                  if (this.outcomeMapping === "individual") {
                    this.showCoColumn = true;
                  }
                  this.manualQuestionList.push({
                    dialog: false,
                    selectedCOs: [],
                    question: d.question,
                  });
                } else if (
                  this.selectedFormType === "Exit Survey" ||
                  this.selectedFormType === "Alumini Survey" ||
                  (this.selectedFormType === "Miscellaneous" &&
                    this.selectedOptionLevel === "Program")
                ) {
                  if (this.outcomeMapping === "individual") {
                    this.showPoPsoColumn = true;
                  }
                  this.manualQuestionList.push({
                    podialog: false,
                    psodialog: false,
                    selectedPOs: [],
                    selectedPSOs: [],
                    question: d.question,
                  });
                }
              });
              this.uploadQuestions = true;
              this.uploadQuestionViaExcelDialog = false;
            });
        }
        this.questionsExcel = "";
      } else if (
        !this.cbIsNBACriteriaAttainment &&
        this.conductionDetails === "Upcoming"
      ) {
        //Parse Excel For Feedback Form Without NBA Attainment with Uppcoming
        if (this.questionsExcel) {
          var formData = new FormData();
          formData.append("excel", this.questionsExcel);
          axios
            .post(
              process.env.VUE_APP_SERVER_URL +
                process.env.VUE_APP_API_PORT +
                "/excel/feedback/parseWithoutNBA",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              response.data.questionsArray.map((d, i) => {
                if (d.questionType === "Multiple Choice Question") {
                  this.manualQuestionList.push({
                    questionType: d.questionType,
                    question: d.question,
                    options: d.options,
                  });
                } else {
                  this.manualQuestionList.push({
                    questionType: d.questionType,
                    question: d.question,
                  });
                }
              });
              this.uploadQuestionViaExcelDialog = false;
            });
        }
        this.questionsExcel = "";
      } else {
        //Parse Excel For Feedback With NBA Attainment and Backdated
        if (this.questionsExcel) {
          var formData = new FormData();
          formData.append("excel", this.questionsExcel);
          if (
            this.selectedFormType === "Course Exit Survey" ||
            (this.selectedFormType === "Miscellaneous" &&
              this.selectedOptionLevel === "Course")
          ) {
            formData.append("stdPrnArrList", JSON.stringify(this.prnArrList));
          }
          axios
            .post(
              process.env.VUE_APP_SERVER_URL +
                process.env.VUE_APP_API_PORT +
                "/excel/feedback/parseBackdated",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (this.cbIsNBACriteriaAttainment) {
                if (
                  this.selectedFormType === "Course Exit Survey" ||
                  (this.selectedFormType === "Miscellaneous" &&
                    this.selectedOptionLevel === "Course")
                ) {
                  if (this.outcomeMapping === "individual") {
                    this.showCoColumn = true;
                  }
                  response.data.questionsArray.map((d, i) => {
                    this.manualQuestionList.push({
                      dialog: false,
                      selectedCOs: [],
                      question: d.question,
                    });
                  });
                } else {
                  if (this.outcomeMapping === "individual") {
                    this.showPoPsoColumn = true;
                  }
                  response.data.questionsArray.map((d, i) => {
                    this.manualQuestionList.push({
                      podialog: false,
                      psodialog: false,
                      selectedPOs: [],
                      selectedPSOs: [],
                      question: d.question,
                    });
                  });
                }
              } else {
                response.data.questionsArray.map((d, i) => {
                  this.manualQuestionList.push({
                    question: d.question,
                  });
                });
              }

              this.studentBackdatedResponse = response.data.stdResponseArray;
              this.uploadQuestions = true;
              this.uploadQuestionViaExcelDialog = false;
            });
        }
        this.questionsExcel = "";
        this.uploadQuestions = true;
        this.uploadQuestionViaExcelDialog = false;
      }
    },
    async getExpectedStudentList(semId, dept, courseYear, division, subjectId) {
      this.expectedStdudentIdsArr = [];
      this.obtainedStudentIdsArr = [];
      this.unobtainedStudentIdsArr = [];

      this.expectedStudentList = [];
      this.obtainedStudentList = [];
      this.unobtainedStudentList = [];

      try {
        const res = (
          await this.semesterUserRepositoryInstance.getStudentsOfACourseYear({
            instituteId: this.selectedInstitute,
            semId: semId,
            department: dept,
            courseYear: courseYear,
          })
        ).filter((user) => this.selectedDivision.includes(user.division));
        this.totalExpectedStudent = res.length;

        res.map((item, index) => {
          this.expectedStdudentIdsArr.push(item.uId);
        });
        this.getExpectedStudentNames(this.expectedStdudentIdsArr);
      } catch (err) {
        showStatus(err, 6000, "error", this);
      }
    },
    async getExpectedStudentNames(arrList) {
      try {
        const res = await this.userRepositoryInstance.getUsers({
          uIds: arrList,
        });

        res.map((item, index) => {
          let fullName =
            item.firstName + " " + item.middleName + " " + item.lastName;
          if (item.collegePRNNo == 0 || item.collegePRNNo == undefined) {
            this.expectedStudentList.push({
              uId: item.uId,
              prnNo: "-",
              fullName: fullName,
            });
          } else {
            this.expectedStudentList.push({
              uId: item.uId,
              prnNo: item.collegePRNNo,
              fullName: fullName,
            });
          }
          // this.expectedStudentList.push({ "uId": item.uId, "prnNo": item.collegePRNNo ? item.collegePRNNo : "-", "fullName": fullName })
        });

        this.getObtainedStudentList();
      } catch (err) {
        showStatus(err, 6000, "error", this);
      }
    },
    async getObtainedStudentList() {
      this.avgMcqQues = 0;
      this.avgShortAnsQues = 0;
      this.avgRatingQues = 0;
      this.noMcqQue = 0;
      this.noShortAnsQue = 0;
      this.noRatingQue = 0;
      this.mcqResponsesOption.series = [];
      this.nbaMcqResponsesOption.series = [];
      this.barOptions.series = [];
      this.stdShAnsResQuestion = [];
      this.stdRatingResCount = [];
      this.individualResponseKey = false;
      try {
        const res =
          await this.feedbackRepositoryInstance.getAllFeedbacksOfObtainedStudents(
            {
              feedbackId: this.formId,
            }
          );
        this.allFeedbackResponses = res;
        this.totalObtainedStudent = res.length;
        if (this.totalExpectedStudent > 0) {
          this.totalUnobtainedStudent =
            this.totalExpectedStudent - this.totalObtainedStudent;
        }
        if (
          this.selectedFormType === "Exit Survey" ||
          this.selectedFormType === "Alumini Survey" ||
          (this.selectedFormType === "Miscellaneous" &&
            this.selectedOptionLevel === "Program")
        ) {
          this.individualResponseKey = true;
          this.tempArr = [];

          res.map((item, i) => {
            let obj = {};
            item.studentResponse.forEach((res, j) => {
              obj[`Question ${j + 1}`] = res.answer;
            });
            obj["annonymous"] = `Response ${i + 1}`;
            this.tempArr.push(obj);
          });
        }
        res.map((item, i) => {
          this.obtainedStudentIdsArr.push(item.userId);
        });

        this.expectedStdudentIdsArr.map((item, j) => {
          if (!this.obtainedStudentIdsArr.includes(item)) {
            this.unobtainedStudentIdsArr.push(item);
          }
        });
        this.expectedStudentList.map((item, index) => {
          if (this.conductionDetails === "Upcoming") {
            if (item.uId) {
              if (this.obtainedStudentIdsArr.includes(item.uId)) {
                this.obtainedStudentList.push(item);
              } else {
                this.unobtainedStudentList.push(item);
              }
            } else {
              this.obtainedStudentList.push(item);
            }
          } else {
            if (item.prnNo) {
              if (this.obtainedStudentIdsArr.includes(item.prnNo)) {
                this.obtainedStudentList.push(item);
              } else {
                this.unobtainedStudentList.push(item);
              }
            } else {
              this.obtainedStudentList.push(item);
            }
          }
        });
        const res1 =
          await this.feedbackRepositoryInstance.getFeedbackDetailsByFeedbackId({
            feedbackId: this.formId,
          });
        let nbaLevels = res1[0].nbaAttainmentLevels;
        this.barOptions.series.push({
          data: [this.totalExpectedStudent, this.totalObtainedStudent],
        });
        this.barLoader = true;
        res.forEach((element) => {
          // Check if the element has a studentResponse array
          if (
            element.studentResponse &&
            Array.isArray(element.studentResponse)
          ) {
            // Loop through each studentResponse object
            element.studentResponse.forEach((response) => {
              // Check if the answer is not already a string
              if (
                response.answer !== null &&
                typeof response.answer !== "string"
              ) {
                // Convert the answer to a string
                response.answer = String(response.answer);
              }
            });
          }
        });
        if (this.cbIsNBACriteriaAttainment) {
          let options = [],
            temp = [];
          if (nbaLevels && Array.isArray(nbaLevels)) {
            options = nbaLevels.map((e) => e);
          }
          this.manualQuestionList.map((q, i) => {
            this.nbaMcqResponsesOption.xaxis.categories.push(
              "Question " + (i + 1)
            );
            let counts = Array(nbaLevels.length).fill(0);
            res.map((stdRes, j) => {
              options.map((opt, k) => {
                // let tempOpt = isNaN(opt) ? opt.trim() : Number(opt)
                if (stdRes.studentResponse[i].answer === opt) {
                  counts[k]++;
                }
              });

              this.obtainedStudentList.map((std, k) => {
                if (this.conductionDetails === "Backdated") {
                  if (std.prnNo === stdRes.userId) {
                    std["Question " + (i + 1)] =
                      stdRes.studentResponse[i].answer;
                    std["annonymous"] = "Response " + (k + 1);
                  }
                } else {
                  if (std.uId) {
                    if (std.uId === stdRes.userId) {
                      std["Question " + (i + 1)] =
                        stdRes.studentResponse[i].answer;
                      std["annonymous"] = "Response " + (k + 1);
                    }
                  } else {
                    std["Question " + (i + 1)] =
                      stdRes.studentResponse[i].answer;
                    std["annonymous"] = "Response " + (k + 1);
                  }
                }
              });
            });
            temp.push(counts);
            // this.nbaMcqResponsesOption.series.push({ "name": options[i], data: counts })
          });
          options.map((opt, i) => {
            let counts = [];
            temp.map((item, j) => {
              counts.push(item[i]);
            });
            this.nbaMcqResponsesOption.series.push({ name: opt, data: counts });
          });
        } else {
          this.totalMcqQuestions = [];
          this.totalRatingQuestions = [];
          this.totalShortAnsQuestions = [];
          let tempQuesOpt = [];
          this.manualQuestionList.map((q, i) => {
            if (q.questionType === "Multiple Choice Question") {
              this.noMcqQue++;
              this.totalMcqQuestions.push(q);
              this.mcqResponsesOption.xaxis.categories.push(
                "Question " + (i + 1)
              );
              let tempOptions = new Array(q.options.length).fill(0);
              res.map((stdRes, j) => {
                q.options.map((opt, k) => {
                  if (opt.option) {
                    if (stdRes.studentResponse[i].answer === opt.option) {
                      tempOptions[k]++;
                    }
                  } else {
                    if (stdRes.studentResponse[i].answer === opt) {
                      tempOptions[k]++;
                    }
                  }
                });
              });
              tempQuesOpt.push(tempOptions);
            } else if (q.questionType === "Short Answer") {
              this.noShortAnsQue++;
              this.totalShortAnsQuestions.push(q);
              this.stdShAnsResQuestion.push({
                key: "Question " + (i + 1),
                label: q.question,
              });
            } else {
              this.noRatingQue++;
              this.totalRatingQuestions.push(q);
              let totalRating = 0;
              let arr = [];
              res.map((stdRes, j) => {
                let tempData = stdRes.studentResponse.filter(
                  (item, index) => i === index
                )[0];
                tempData.uId = stdRes.userId;
                arr.push(tempData);
                totalRating = totalRating + stdRes.studentResponse[i].answer;
              });
              this.stdRatingResCount.push({
                key: "Question " + (i + 1),
                question: q.question,
                value: totalRating / res.length,
                totalResponses: arr,
              });
            }

            res.map((stdRes, j) => {
              this.obtainedStudentList.map((std, k) => {
                if (this.conductionDetails === "Backdated") {
                  if (std.prnNo === stdRes.userId) {
                    std["Question " + (i + 1)] =
                      stdRes.studentResponse[i].answer;
                    std["annonymous"] = "Response " + (k + 1);
                  }
                } else {
                  if (std.uId === stdRes.userId) {
                    std["Question " + (i + 1)] =
                      stdRes.studentResponse[i].answer;
                    std["annonymous"] = "Response " + (k + 1);
                  }
                }
              });
            });
          });

          for (let i = 0; i < 5; i++) {
            let t = [];
            tempQuesOpt.map((opts, j) => {
              if (opts.length > i) {
                t.push(opts[i]);
              }
            });
            this.mcqResponsesOption.series.push({
              name: String.fromCharCode(97 + i),
              data: t,
            });
          }

          this.avgMcqQues = (
            (this.noMcqQue / this.manualQuestionList.length) *
            100
          ).toFixed(2);
          this.avgShortAnsQues = (
            (this.noShortAnsQue / this.manualQuestionList.length) *
            100
          ).toFixed(2);
          this.avgRatingQues = (
            (this.noRatingQue / this.manualQuestionList.length) *
            100
          ).toFixed(2);
        }
      } catch (err) {
        showStatus(err, 6000, "error", this);
      }
    },
    // handleSampleDownloadLink() {
    //     if(this.cbIsNBACriteriaAttainment && this.conductionDetails == 'Upcoming') {

    //     }
    // },
    showRatingResponses(question, index) {
      this.ratingQuestionToShow = question;
      this.ratingQuestionAvg = question.value.toFixed(2);
      this.totalRatingResponseReceived = question.totalResponses.length;
      this.showRatingResponseDialog = true;
    },
    showQuestions(type) {
      this.selectedQuestionType = type;
      this.showQuestionDialog = true;
    },
    handleShowRatingQuestionDialog(obj) {
      this.selectedRatingQuestion = obj;
      this.dialogRatingQuestionAnalysis = true;
    },
    handleFileDialog() {
      this.uploadQuestionViaExcelDialog = true;
      this.getUserIds(false);
    },
  },
};
</script>
<style scoped src="./activityCreateFBForm.css"></style>
