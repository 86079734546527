<template src='./activityCourses.html'></template>
<script>
import inputContainer from '../../Components/inputContainer'
import SemesterRepository from '../../Repository/Semester'
import SubjectsRepository from '../../Repository/Subject'
import CourseRepository from '../../Repository/courses';
import InstituteRepository from '../../Repository/Institute';
import CorporateRepository from '../../Repository/Corporate';
import auth from '../../Services/Firebase/auth'
import uploadToDigitalOcean from '../../Repository/uploadToDigitalOcean';
import showStatus from "../../NetworkManager/showStatus";


var firestorage = null

export default {
  name: 'activityCoursesRevamped',
  props: ["prop_subject", "prop_selectedDepartment", "prop_selectedCourseYear", "prop_selectedSemester"],
  data() {
    return {
      assignedDepartment: [],
      assignedCourseYearForSubAdmin: [],
      semestersList: [],
      isCorporateInstitute: false,
      editSubjectName: false,
      deleteSubjectInfo: {},
      editSubjectInfo: {},
      deleteSubject: false,
      updatedSubjectName: '',
      addLogo: false,
      instituteLogo: [],
      addSubject: false,
      subjectNameCorporate: '',
      departmentsList: [],
      departmentsList: [],
      courseYearList: [],
      subjectsList: [],
      selectedInstitute: '',
      selectedDepartment: '',
      selectedSemester: {},
      selectedCourseYear: '',
      starIcon: 'mdi-star',
      loading: false,
      starRating: 3,
      averageRating: 0,
      isLoading: false,
      newCourseImageFile: []
    }
  },
  async created() {
    this.selectedInstitute = this.$store.getters['liveData/get_admissionInstitute']
    this.assignedCourseYearForSubAdmin = this.$store.getters['liveData/get_accessibleCourseYearForSubAdmin']?.accessibleCourseYearForSubAdmin || []
    this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment'].accessibleDepartment
    this.userData = this.$store.getters['user/get_userData']
    this.CourseRepositoryInstance = new CourseRepository(this)
    this.subjectRepositoryInstance = new SubjectsRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.InstituteRepositoryInstance = new InstituteRepository(this)
    this.CorporateRepositoryInstance = new CorporateRepository(this)
    this.uploadToDigitalOceanInstance = new uploadToDigitalOcean(this)
    this.$store.commit("liveData/set_selectedActivityName", "Welcome to Courses!");
    const firebase = auth.getFirebaseObject()

    firestorage = firebase.storage()

    this.isCorporateInstitute = this.$store.getters['liveData/get_isCorporateInstitute']

    if (this.prop_selectedSemester) {

      this.selectedSemester = this.prop_selectedSemester
    }
    if (this.prop_selectedDepartment) {

      this.selectedDepartment = this.prop_selectedDepartment
    }
    if (this.prop_selectedCourseYear) {

      this.selectedCourseYear = this.prop_selectedCourseYear
    }
    if (this.selectedSemester) {
      await this.fetchDepartments()
    }
    if (this.selectedDepartment) {
      await this.fetchCourseYear()
    }
    if (this.selectedCourseYear) {
      await this.fetchSubjects()
    }
    this.getSemesters();
  },
  methods: {
    editSubjectDialog(sub) {
      this.editSubjectInfo = sub
      this.updatedSubjectName = sub.subjectName
      this.editSubjectName = true
    },
    deleteDialog(sub) {
      console.log('sub', sub);
      this.deleteSubject = true
      this.deleteSubjectInfo = sub
    },
    async deleteSubjectFromAll() {
      let obj = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        subjectId: this.deleteSubjectInfo.subjectId
      }
      console.log('obj', obj);
      try {
        let data = await this.CorporateRepositoryInstance.deleteSubject(obj)
        this.getSemesters()
      } catch (error) {
        console.log('error', error);
      }
      this.deleteSubject = false

    },
    async updateSubjectName() {
      try {
        let obj = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectId: this.editSubjectInfo.subjectId,
          subjectName: this.updatedSubjectName
        }
        console.log('obj', obj);
        let data = await this.CorporateRepositoryInstance.changeSubjectName(obj)
        this.getSemesters()
      } catch (error) {
        console.log('error', error);
      }
      this.editSubjectName = false
    },
    assignStudent() {
      // console.log('this.selectedSemester.semId', this.selectedSemester.semId);
      // console.log('this.selectedDepartment', this.selectedDepartment);
      // console.log('this.selectedCourseYear', this.selectedCourseYear);
      this.$router.push({
        name: 'activityEditStudentsRegistration',
        params: {
          PropsSelectedSemester: this.selectedSemester,
          // prop_SelectedDepartment: this.selectedDepartment,
          // prop_SelectedCourseYear: this.selectedCourseYear,
          // prop_SelectedDivision: this.selectedDivision,
          // tree: this.divisions
          prop_isCorporate: true,
          prop_dataOfDept: this.divisionListOfSem[0]
        }
      })
    },
    async addSubjectForCorporate() {
      let objToPush = {}
      objToPush.instituteId = this.selectedInstitute,
        objToPush.semId = this.selectedSemester.semId,
        objToPush.department = this.selectedDepartment,
        objToPush.courseYear = this.selectedCourseYear,
        objToPush.subjectName = this.subjectNameCorporate;
      objToPush.subjectCode = this.subjectNameCorporate;
      objToPush.subjectType = 0;

      console.log('objToPush', objToPush);
      try {
        let subject = await this.subjectRepositoryInstance.createSubject(objToPush)
        console.log('subject', subject);
        await this.fetchSubjects()
      } catch (error) {
        console.log(error);
      }finally {
        this.subjectNameCorporate = "";
        this.addSubject = false;
      }
    },
    async getSemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semestersList = await this.semesterRepositoryInstance.getSemesterNames(objToPush)
        if (this.semestersList.length === 1) {
          this.selectedSemester = this.semestersList[0]
          this.fetchDepartments()
        }
      } catch (err) {
        console.log(err)
      }
    },

    async fetchDepartments() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        }
        this.divisionListOfSem = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        console.log('this.divisionListOfSem', this.divisionListOfSem);
        this.departmentsList = []
        this.courseYearList = []
        this.divisionList = []
        this.divisionListOfSem.map((div) => {
          if (!this.departmentsList.includes(div.department)) {
            this.departmentsList.push(div.department)
          }
        })
        let subAdminAssignedDepartmentWiseLecture = []
      if(this.assignedDepartment.length > 0) {
        this.departmentsList.map((data) => {
          if(this.assignedDepartment.includes(data)){
            subAdminAssignedDepartmentWiseLecture.push(data)
          }
        })
        this.departmentsList = subAdminAssignedDepartmentWiseLecture
        }
        if (this.departmentsList.length === 1) {
          this.selectedDepartment = this.departmentsList[0]
          this.fetchCourseYear()
        }
      } catch (err) {
        console.log(err)
      }
    },
    async fetchCourseYear() {
      this.courseYearList = []
      this.divisionList = []
      this.divisionListOfSem.map((div) => {
        if (div.department === this.selectedDepartment)
          if (!this.courseYearList.includes(div.courseYear)) {
            this.courseYearList.push(div.courseYear)
          }
      })
      let allowedCourseYearForSelectedDepartment = []
      if (this.assignedCourseYearForSubAdmin.length > 0) {
        this.assignedCourseYearForSubAdmin.map((cy) => {
          let [course, department1] = cy.split('_')
          if (department1 === this.selectedDepartment) {
            allowedCourseYearForSelectedDepartment.push(course)
          }
        })
        this.courseYearList = allowedCourseYearForSelectedDepartment
      }
      if (this.courseYearList.length === 1) {
        this.selectedCourseYear = this.courseYearList[0]
        this.fetchSubjects()
      }
    },
    gotoCourse(subject) {
      this.$router.push({
        name: 'activitySubjectCoursesBasicDetails',
        params: {
          prop_subject: subject,
          prop_selectedSemester: this.selectedSemester
        }
      })
    },
    async fetchSubjects() {
      this.isLoading = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
        }
        const subjectIdArr = [];
        let subjectsTemp = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(objToPush);
        subjectsTemp.forEach(subject => {
          subjectIdArr.push(subject.subjectId);
        })
        let {data} = await this.CourseRepositoryInstance.getMultiCourseRatings({courseIds : subjectIdArr})
        let courseDetails = await this.CourseRepositoryInstance.getAllCoursesInfoOfInstitute(objToPush)
        let courseDetailsKeyValueObj = {};
        for(let i = 0;i < courseDetails.length;i++){
          courseDetailsKeyValueObj[courseDetails[i].courseId] = courseDetails[i];
        }
        subjectsTemp.map((subject) => {
          console.log(data[subject.subjectId])
          // courseDetails.map((course) => {
          //   if (subject.subjectId === course.courseId) {
          //     subject.courseImage = course.courseImage
          //     subject.courseName = course.courseName
          //   }
          // })
          Object.assign(subject,{...subject,...courseDetailsKeyValueObj[subject.subjectId]});
          subject.totalCourseRating = 0
          subject.enrolledStudents = 0
          
          subject.averageRating = 0
          subject.studentsList = []
          if(data && data[subject.subjectId]) {
            subject.ratingGivenStudent = data[subject.subjectId].totalRatings
            subject.averageRating = data[subject.subjectId].averageRating
          } else {
            subject.ratingGivenStudent = 0
            subject.averageRating = 0
          } 
        })
        
        this.subjectsList = subjectsTemp
        console.log(this.subjectsList)
        this.isLoading = false
      } catch (e) {
        console.log('e', e);
        this.isLoading = false
      }
    },
    async uploadFile(fileArr) {
      try {
        if (fileArr.length > 0) {
          const fileName = fileArr.filter((item) => item.name);
          let data = await this.uploadToDigitalOceanInstance.upload(
            fileName,
            this.userData.uId
          );
          return data;
        }
      } catch (error) {
        console.log("error", error);
        let arr = [];
        return arr;
      }
    },
    async updateCourseImage(e,i){
      try{
        const uploadedFile = await this.uploadFile([e])
        const res = await this.CourseRepositoryInstance.updateCourseInfo({
          instituteId: this.subjectsList[i].instituteId,
          semId : this.subjectsList[i].semId,
          department: this.subjectsList[i].department,
          courseYear: this.subjectsList[i].courseYear,
          subjectId: this.subjectsList[i].subjectId,
          courseName: this.subjectsList[i].courseName,
          courseImage: uploadedFile[0].url
        })
        showStatus("Course Image updated successfully", 3000, "success", this);
        await this.fetchSubjects();
      }catch(err){
        showStatus(
            "Unable to update course image . Please try again later",
            3000,
            "error",
            this
          );
      }
    },
    gotoAnalysis(subject) {
      console.log(subject)
      this.$store.commit("liveData/set_selectedSubject", subject);
      this.$router.push({
        name: 'activityCourseAnalysisRevamped',
        params: {
          prop_subject: subject,
          prop_selectedSemester: this.selectedSemester,
          prop_selectedDepartment: this.selectedDepartment,
          prop_selectedCourseYear: this.selectedCourseYear
        }
      })
    },
  },
}
</script>

<style scoped src="./activityCourses.css"></style>