const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const formatDateTimeToDate = dateTime => {
  return dateTime.substr(0, 10)
}

export function getDay(date) {
  const D = new Date(date.split('/')[2], (parseInt(date.split('/')[1]) - 1), date.split('/')[0])
  // console.log(D, date.split('/')[0], (parseInt(date.split('/')[1]) - 1), date.split('/')[2])
  return days[D.getDay()]
}

export function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date).toLocaleTimeString('en-US');
  console.log('newDate', newDate);
  return newDate;
}
export function convertLocalToUTC(date) {
  return new Date(date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds())
}

export function convertDateTime(date) {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  const hours = ('0' + d.getHours()).slice(-2)
  const minutes = ('0' + d.getMinutes()).slice(-2)

  // return [year, month, day].join('-')
  return [day, month, year].join('-') + ' ' + timeStringConverter(hours + '_' + minutes)
}

export function convertDate(date) {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  // return [year, month, day].join('-')
  return [day, month, year].join('-')
}

export function convertTime(date) {
  const d = new Date(date)
  const hours = ('0' + d.getHours()).slice(-2)
  const minutes = ('0' + d.getMinutes()).slice(-2)

  return timeStringConverter([hours, minutes].join('_'))
}

export function formatToSlashSepearated(date) {
  const d = new Date(date).toLocaleDateString().split('/')

  return [d[0], d[1], d[2]].join('/')
}

export function convertFromYYYYMMDDToDate(dateString, timeString) {
  const dateArray = dateString.split('-').map((date) => parseInt(date))
  dateArray[1] -= 1
  // console.log(dateArray)
  return new Date(
    ...dateArray
  )
}

export function convertToISTTime(date) {
  const currentOffset = date.getTimezoneOffset()

  // IST offset UTC +5:30
  const ISTOffset = 330

  const ISTTime = new Date(date.getTime() + (ISTOffset + currentOffset) * 60000)

  // console.log('ISTTime', ISTTime)

  return ISTTime
}

// export function formatToDashSeparated(date) {
//   let dateSplit = date.split("/");
//   let day = dateSplit[0].length === 1 ? `0${dateSplit[0]}` : dateSplit[0];
//   let month = dateSplit[1].length === 1 ? `0${dateSplit[1]}` : dateSplit[1];
//   let year = dateSplit[2];
//   return [year, month, day].join("-");
// }

export function timeStringConverter(time) {
  time = time.split('_').join(':')
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]
  if (time.length > 1) { // If time format correct
    time = time.slice(1) // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM' // Set AM/PM
    time[0] = +time[0] % 12 || 12 // Adjust hours
  }
  return time.join('') // return adjusted time or original string
}
