<template src="./syllabus.html"></template>

<script>
import NbaRepository from "../../../../Repository/NBA";
import SyallbusRepository from "../../../../Repository/Syllabus";
import uploadToDigitalOceanRepository from "../../../../Repository/uploadToDigitalOcean";
import showStatus from "../../../../NetworkManager/showStatus";
import { createExcelThroughJson, generateJsonFromExcel } from "../../../../utils/excel";

export default {
  name: "syllabus",
  components: {
    // basicDetails,
    // examsBasicDetails,
    // examSubjectDetails,
    // examForm,
    // hallTicket,
    // examQuestionDetails,
    // examMarks,
  },
  props: ["subjectId", "semId", "courseYear", "selectedDepartment"],
  activated() {
  },
  async created() {
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.uploadToDigitalOceanInstance = new uploadToDigitalOceanRepository(
      this
    );
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];

    this.syllabusInstance = new SyallbusRepository(this);
    this.userData = this.$store.getters["user/get_userData"];
    await this.getSyllabus();
    await this.fetchCos();
  },
  computed: {},
  data() {
    return {
      uploadSyllabusViaExcel: false,
      userData: "",
      backgroundColor: "#E7E7FFCF",
      nbaData: [],
      courseOutcomeData: [],
      selectedInstitute: "",
      fileDialog: "",
      referenceMaterialDialog: false,
      headers: [
        { text: "Unit No", value: "unitNo", width: "5%", sortable: false },
        {
          text: "Unit Name",
          value: "unitName",
          width: "11.5%",
          sortable: false,
        },
        {
          text: "Topic",
          value: "topic",
          width: "11.5%",
          sortable: false,
          cellClass: "unitClass",
        },
        {
          text: "Sub Topic",
          value: "subtopic",
          width: "11.5%",
          sortable: false,
        },
        {
          text: "Teaching Method",
          value: "teachingMethod",
          width: "13%",
          sortable: false,
        },
        {
          text: "Teaching Tools",
          value: "teachingTools",
          width: "11.5%",
          sortable: false,
        },
        {
          text: "Reference Link",
          value: "referenceLink",
          width: "1.5%",
          sortable: false,
        },
        {
          text: "Reference Material",
          value: "referneceMaterial",
          width: "15.5%",
          sortable: false,
        },
        {
          text: "CO Mapping",
          value: "coMapping",
          width: "11.5%",
          sortable: false,
        },
      ],
      syllabus: [],
      techingMethodItems: [
        "Flipped Classroom",
        "Collaborative learning",
        "Discussion",
        "Adaptive learning",
        "Case method",
        "Experiential learning",
        "Heuristic method",
        "Lectures",
        "Problem-based learning",
        "Open learning",
        "Brainstorming",
        "Demonstration",
        "Assignment",
        "Audio Tutorials",
        "Integrative",
        "Projects",
        "Other",
      ],
      // syllabus: [
      //   {
      //     unitName: "name1",
      //     cos: [],
      //     totalMarks: 0,
      //     topics: [
      //       {
      //         topicName: "topic1",
      //         subTopics: [
      //           {
      //             // subTopicId : v4()
      //             subTopicName: "subTopicName1",
      //           },
      //           {
      //             type: "subtopic",
      //             subTopicName: "subTopicName2",
      //           },
      //         ],
      //       },
      //       {
      //         type: "topic",
      //         topicName: "topic2",
      //         subTopics: [
      //           {
      //             type: "subtopic",
      //             subTopicName: "subTopicName3",
      //           },
      //           {
      //             type: "subtopic",
      //             subTopicName: "subTopicName4",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     type: "unit",
      //     unitName: "name1",
      //     cos: [],
      //     totalMarks: 0,
      //     topics: [
      //       {
      //         type: "topic",
      //         topicName: "topic1",
      //         subTopics: [
      //           {
      //             type: "subtopic",
      //             subTopicName: "subTopicName1",
      //           },
      //           {
      //             type: "subtopic",
      //             subTopicName: "subTopicName2",
      //           },
      //         ],
      //       },
      //       // {
      //       //   type: "topic",
      //       //   topicName: "topic2",
      //       //   subTopics: [
      //       //     {
      //       //       type: "subtopic",
      //       //       subTopicName: "subTopicName3",
      //       //     },
      //       //   ],
      //       // },
      //     ],
      //   },
      //   {
      //     type: "unit",
      //     unitName: "name3",
      //     cos: [],
      //     totalMarks: 0,
      //     topics: [],
      //     selectedFileUrl:""
      //   },
      // ],
      selectedItem: {},
      attachedMaterialFilesToUpload: [],
      selectedFilesUrl: [],
      saveLoader: false,
      isLoading: true,
      file: null
    };
  },
  methods: {
    async handelSyllabusExcel() {
      console.log('this.syllabus', this.syllabus);
      // this.loading = true
      const schema = {
        Sheet1: {
          "Unit Name": {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'unitName'
          },
          "Topic Name": {
            validation: {
              type: 'textLength',
            },
            variableName: 'topicName'
          },
          "SubTopic Name": {
            validation: {
              type: 'textLength',
            },
            variableName: 'subTopicName'
          }
        }
      }
      const res = await generateJsonFromExcel(this.file, schema, false);
      // console.log('res', res);
      const { data } = res;
      const { Sheet1 } = data;
      Sheet1.map((obj) => {
        console.log('obj', obj);
        if (this.syllabus.length > 0) {
          this.syllabus.map((item) => {
            let flagForUnit = false
            console.log('item', item);
            if (Array.isArray(item.units) && item.units.length > 0) {
              item.units.map((unit) => {
                if (unit.unitName === obj.unitName) {
                  flagForUnit = true
                  if (Array.isArray(unit.topics) && unit.topics.length > 0) {
                    let flagForTopic = false
                    unit.topics.map((topic) => {
                      if (topic.topicName === obj.topicName) {
                        flagForTopic = true
                        if (obj.subTopicName !== '') {
                          if (topic.subTopics) {

                            topic.subTopics.push({
                              subTopicName: obj.subTopicName
                            })
                          } else {
                            topic.subTopics = []
                            topic.subTopics.push({
                              subTopicName: obj.subTopicName
                            })
                          }
                        }
                      }

                    })
                    if (flagForTopic === false) {
                      unit.topics.push({
                        topicName: obj.topicName,
                        subTopics: [{
                          subTopicName: obj.subTopicName
                        }]
                      })
                    }
                  } else {
                    unit.topics = []
                    unit.topics.push({
                      topicName: obj.topicName,
                      subTopics: [{
                        subTopicName: obj.subTopicName
                      }]
                    })
                  }
                }
              })
              console.log('obj', obj, item);
              if (flagForUnit === false) {
                item.units.push({
                  unitNumber: item.units.length + 1,
                  unitName: obj.unitName,
                  topic: [{
                    topicName: obj.topicName,
                    subTopics: [{
                      subTopicName: obj.subTopicName
                    }]
                  }]

                })
              }
            } else {
              item.units = []
              item.units.push({
                unitName: obj.unitName,
                topics: [{
                  topicName: obj.topicName,
                  subTopics: [{
                    subTopicName: obj.subTopicName
                  }]
                }]

              })
            }
          })

        } else {
          this.syllabus.push({
            unitNumber: 1,
            unitName: obj.unitName,
            topics: [{
              topicName: obj.topicName,
              subTopics: [{
                subTopicName: obj.subTopicName
              }]
            }]


          })

        }
      })
      let value = await this.syllabusInstance.createSyllabus({
        instituteId: this.selectedInstitute,
        subjectId: this.subjectId,
        semId: this.semId,
        department: this.selectedDepartment,
        courseYear: this.courseYear,
        units: this.syllabus,
      });
      await this.getSyllabus()
      this.uploadSyllabusViaExcel = false
      // this.loading = false
    },
    prepareSyallabus() {
      this.syllabus.forEach((unit) => {
        unit.teachingMethod.forEach((item, index) => {
          if (!this.techingMethodItems.includes(item)) {
            unit.otherTeachingMethod = unit.teachingMethod[index]
            unit.teachingMethod[index] = 'Other'
            unit.showOther = true;
            unit.otherIndex = index
          }
        })
        // s.teachingMethods = this.techingMethodItems
      })
    },
    async uploadFile(fileArr) {
      let urlLinkArr = [];
      try {
        if (fileArr.length > 0) {
          let data = await this.uploadToDigitalOceanInstance.upload(
            fileArr,
            this.userData.uId
          );
          return data;
        }
      } catch (error) {
        console.error("error");
        return urlLinkArr;
      }
    },
    hasAttachedFiles(item) {
      if (
        item.attachedMaterialFilesToUpload &&
        item.attachedMaterialFilesToUpload.length > 0
      ) {
        return true;
      }
      if (item.referenceMaterial?.length > 0) {
        return true;
      }
      return false;
    },
    // addUnit() {
    //   this.syllabus.push({
    //     type: "unit",
    //     unit: {
    //       unitNo: "",
    //       unitName: "",
    //       topics: [],
    //     },
    //   });
    // },

    handleOtherOption(item) {
      item.showOther = false;
      if (item.teachingMethod.includes("Other")) {
        const index = item.teachingMethod.indexOf("Other");
        item.otherIndex = index;
        item.showOther = true;
      }
    },
    addSubTopic(unitIndex, topicIndex, subTopicIndex) {
      this.syllabus[unitIndex].topics[topicIndex].subTopics.push({
        subTopicName: "",
        type: "subtopic",
      });
    },
    addTopic(unitIndex) {
      this.syllabus[unitIndex].topics.push({
        topicName: "",
        subTopics: [],
        type: "topic",
      });
    },
    addUnit(index) {
      this.syllabus.push({
        unitName: "",
        cos: [],
        topics: [],
        unitNumber: index,
      });
    },
    deleteSubTop(unitIndex, topicIndex, subTopicIndex) {
      // this.syllabus[unitIndex].topics[topicIndex].subTopics.pop();
      this.syllabus[unitIndex].topics[topicIndex].subTopics.splice(subTopicIndex, 1)
    },
    deleteTopic(unitIndex, topicIndex) {
      this.syllabus[unitIndex].topics.splice(topicIndex, 1);
    },
    deleteUnit(unitIndex) {
      this.syllabus.splice(unitIndex, 1);
    },
    async fetchCos() {
      try {
        this.nbaData = await this.nbaRepositoryInstance.getCos({
          lmsInstituteId: this.selectedInstitute,
          semesterId: this.semId,
          subjectId: this.subjectId,
        });
      } catch (error) {
        console.error(error)
      }
      if (Array.isArray(this.nbaData.data)) {
        this.nbaData.data.forEach((nba, i) => {
          this.courseOutcomeData.push({
            Id: nba._id,
            coNumber: nba.CONumber,
            coName: nba.COName,
            coText: `CO ${nba.CONumber} - ${nba.COName}`,
          });
          this.sortByNumericValueInAscendingOrder(
            this.courseOutcomeData,
            "coNumber"
          );
        });
      }
    },
    deleteReferenceMaterial(item) {
      item.referenceMaterial = []
    },
    sortByNumericValueInAscendingOrder(array, fieldToSortBy) {
      array.sort((a, b) => a[fieldToSortBy] - b[fieldToSortBy]);
      return array;
    },
    openFileDialog(item) {
      // this.courseProofDialog = true;
      // if (this.courseProofFilesToUpload) {
      //   this.selectedFilesUrl = await Promise.all(
      //     this.courseProofFilesToUpload.map(async (file, index) => {
      //       return await URL.createObjectURL(file);
      //     })
      //   );
      // },
      if (item.attachedMaterialFilesToUpload) {
        this.attachedMaterialFilesToUpload = [];
        this.selectedFilesUrl = [];
        this.attachedMaterialFilesToUpload = item.attachedMaterialFilesToUpload;
        if (this.attachedMaterialFilesToUpload) {
          this.selectedFilesUrl = this.attachedMaterialFilesToUpload.map(
            (file, index) => {
              return URL.createObjectURL(file);
            }
          );
        }
      }
      if (item.referenceMaterial && item.referenceMaterial.length > 0) {
        this.selectedItem = item;
      }
      this.referenceMaterialDialog = true;
      // this.fileDialog = true;
    },
    onContentLoaded() {
      // This is called when the content has loaded successfully
      this.isLoading = false;
    },
    getFileInputLabel(item) {
      if (item.referenceMaterial) {
        let fileCount = item.referenceMaterial.length
        return `${fileCount} file${fileCount > 1 ? 's' : ''} attached`;
      }
      return 'Attach material files';
    },
    async saveSyallbus() {
      this.saveLoader = true;
      const uploadPromises = [];
      this.syllabus.forEach((s) => {
        if (s?.attachedMaterialFilesToUpload?.length > 0) {
          // Add the upload promise to the array
          const uploadPromise = this.uploadFile(
            s.attachedMaterialFilesToUpload
          ).then((res) => {
            s.referenceMaterial = res;
          });
          uploadPromises.push(uploadPromise);
        }
      });
      await Promise.all(uploadPromises);

      this.syllabus.forEach((s, index) => {
        if (s.otherIndex !== undefined) {
          s.teachingMethod[s.otherIndex] = s.otherTeachingMethod
        }
      })
      try {
        let res = await this.syllabusInstance.createSyllabus({
          instituteId: this.selectedInstitute,
          subjectId: this.subjectId,
          semId: this.semId,
          department: this.selectedDepartment,
          courseYear: this.courseYear,
          units: this.syllabus,
        });
        showStatus(
          "Syallbus saved successfully",
          2000,
          "success",
          this
        );
      } catch (error) {
        console.error(error);
        showStatus("Something went wrong", 2000, "error", this);
      } finally {
        this.saveLoader = false;
      }
    },
    async getSyllabus() {
      try {
        let res = await this.syllabusInstance.getSyllabus({
          instituteId: this.selectedInstitute,
          semId: this.semId,
          department: this.selectedDepartment,
          courseYear: this.courseYear,
          subjectId: this.subjectId,
        });
        if (res.status.status === 200) {
          this.syllabus = res.result.units
          this.prepareSyallabus();
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
};
</script>

<style scoped src="./syllabus.css"></style>