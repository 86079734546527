<template src="./activityAddSubjectInstituteLevel.html"></template>
<script>
import inputContainer from "../../Components/inputContainer";
import SubjectInstituteLevelRepository from "../../Repository/SubjectInstituteLevel";
import SubjectRepository from "../../Repository/Subject";
import SemesterUserRepository from "../../Repository/SemesterUser";
import valueAddedActivityRepository from "../../Repository/ValueAddedActivity";
import uploadToDigitalOceanRepository from "../../Repository/uploadToDigitalOcean";
import showStatus from "../../NetworkManager/showStatus";
import NbaRepository from "../../Repository/NBA";
import {
  createExcelThroughJsonForMultipleSheets,
  generateJsonFromExcel,
  generateFromJson,
} from "../../utils/excel";

import axios from "axios";

export default {
  name: "activityAddSubjectInstituteLevel",
  components: {
    inputContainer,
  },
  data() {
    return {
      // uploadExcelForAddSubject: [],
      // showErrorOfExcel: false,
      // errorMessageByExcel: '',
      // allErrorArray: [],
      // uploadExcelSubject: false,
      // framework: ['Professional Ethics', 'Human Values', ' Environment', 'Sustainability', 'Other'],
      // subjectFramework: '',
      // subjectFrameworkByInput: '',
      // subjectIsNew: '',
      // subjectIsForNaac: '',
      // valueAddedActivityIdForDelete: '',
      // deletingValueAddedDialog: false,
      // valueAddedActivityAll: [],
      // searchNormal: '',
      // searchValue: '',

      // items: [{ title: 'Normal Course' }, { title: 'Value added Course' }],
      // loadingForDocument: false,
      // waitLoader: false,
      // instituteSubjectIdForEdit: '',
      // addingSubject: false,
      // editingVariant: false,
      // deletingVariant: false,
      // subjectTypes: null,
      // search: '',
      // headers: [

      //   {
      //     text: 'Subject Name',
      //     value: 'subjectName'
      //   },
      //   {
      //     text: 'Subject Type',
      //     value: 'subjectType'
      //   },
      //   {
      //     text: 'Subject Code',
      //     value: 'subjectCode'
      //   },
      //   {
      //     text: 'Form completed %',
      //     value: 'percentageCompleted'
      //   },

      //   {
      //     text: 'Actions',
      //     value: 'actions'
      //   }
      // ],
      // headersValueAdded: [{
      //   text: 'Activity Code',
      //   value: 'activityCode',
      //   width: '100px'
      // },
      // {
      //   text: 'Activity Title',
      //   value: 'title',
      //   width: '200px'
      // },
      // {
      //   text: 'Credits',
      //   value: 'credits',
      //   width: '150px'
      // },
      // {
      //   text: 'Type of the activity',
      //   value: 'typeOfActivity',
      //   width: '150px'

      // },
      // {
      //   text: 'Total Duration',
      //   value: 'duration',
      //   width: '150px'

      // },
      // {
      //   text: 'Action',
      //   value: 'actions',
      //   width: '200px'
      // },
      // ],
      // subjects: [],
      // subjectTableLoader: false,
      // user: '',
      // userData: '',
      // selectedInstitute: '',
      // menu: false,
      // date1: '',
      // menu1: false,
      // tempSubjectId: '',
      // tempSubjectObjectiveFile: [],
      // tempSubjectSyllabusFile: [],
      // tempOutcomesFile: [],
      // tempInstructionMaterialFile: [],
      // tempPrerequisiteFile: [],
      // tempMethodologyFile: [],
      // subjectTableLoader: false,
      // step1Progrss: false,
      // step2Progrss: false,
      // finialGroupName: '',
      // groupNameDisabled: false,
      // groupNameSelectDisabled: false,
      // subjectGroupNameNewBySelect: '',
      // groupNameArr: [],
      // subjectNameNew: '',
      // subjectCodeNew: '',
      // subjectGroupNameNew: '',
      // subjectComponent: [],
      // subjectDescription: '',
      // totalCredits: null,
      // totalHrsWeek: null,
      // totalMarks: null,
      // subjectObjectiveFile: [],
      // subjectObjectiveFileLink: [],
      // subjectSyllabusFile: [],
      // subjectSyllabusFileLink: [],
      // lectureHrs: 0,
      // tutorialHrs: 0,
      // practicalHrs: 0,
      // selfStudyHrs: 0,
      // totalContactHrs: 0,
      // subjectTypeArr: [],
      // subjectTypeRadioBtn: '',
      // chooseElectiveByStudent: '',
      // arrForBookNameRow: [],
      // outcomesFile: [],
      // outcomesFileLink: [],
      // selectedCoordinaterName: [],
      // instructionMaterialFile: [],
      // instructionMaterialFileLink: [],
      // prerequisiteFile: [],
      // prerequisiteFileLink: [],
      // methodologyFile: [],
      // methodologyFileLink: [],
      // departmentFacultyNamesArr: [],
      // arrSubjectComponent: ['Basic Sciences', 'Engineering Science', 'Humanities & social Sciences', 'Program Core', 'Program Electives', 'Open Electives', 'Project', 'Seminars', 'Field Project', 'Research Project', 'Internship'],
      // dummyInfoArr: ['test1', 'test2', 'test3'],
      // e1: 1,
      // ex7: '',
      // ex8: '',

      tab: "",
      saveLoader: false,
      // allAcademicYears: [],
      // selectedAcademicYear: "",
      selectedIntroductionYear: {},
      // allBatches: []
      addCourseDailog: false,
      academicYears: [],
      menuItems: ["Add manually", "Add via Excel"],
      backgroundColor: "#E7E7FFCF",
      courseObject: {},
      coursesArray: [],
      fetchedCourses: [],
      filteredCourses: [],
      headersForCourses: [
        {
          text: "Course Code",
          value: "subjectCode",
        },
        {
          text: "Course Name",
          value: "subjectName",
        },
        {
          text: "Course Abbreviation",
          value: "courseAbbreviation",
        },
        {
          text: "Year Of introduction",
          value: "yearOfIntroduction",
        },
        {
          text: "Total Credits",
          value: "credits",
        },
        {
          text: "Total Hours per semester",
          value: "hrsPerSemester",
        },
      ],
      yearOfIntroductionItems: [
        "Other",
        "Academic Year 2014-2015",
        "Academic Year 2015-2016",
        "Academic Year 2016-2017",
        "Academic Year 2017-2018",
        "Academic Year 2018-2019",
        "Academic Year 2019-2020",
        "Academic Year 2021-2022",
        "Academic Year 2022-2023",
        "Academic Year 2023-2024",
        "Academic Year 2024-2025",
        "Academic Year 2025-2026",
        "Academic Year 2026-2027",
      ],
      uploadCourseViaExcel: false,
      courseExcelFile: null,
      excelErrors: [],
      schema: {},
    };
  },
  async created() {
    this.subjectInstituteLevelInstance = new SubjectInstituteLevelRepository(
      this
    );
    this.valueAddedActivityInstance = new valueAddedActivityRepository(this);
    this.uploadToDigitalOceanInstance = new uploadToDigitalOceanRepository(
      this
    );
    this.SemesterUserInstance = new SemesterUserRepository(this);
    this.SubjectRepositoryInstance = new SubjectRepository(this);
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.user = this.$store.getters["user/get_user"];
    this.userData = this.$store.getters["user/get_userData"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    // this.$store.commit('liveData/set_selectedActivityName', 'Add Subject/Course')
    // await this.fetchData()
    await this.initialize();
  },
  methods: {
    async initialize() {
      await this.fetchCourses();
    },
    handleAddCourse(item) {
      this.coursesArray = [];
      this.courseObject = {};
      this.courseExcelFile = null
      if (item === "Add manually") {
        this.addCourseDailog = true;
        this.courseObject = {};
        this.courseObject["selectedIntroductionYear"] =
          this.selectedIntroductionYear;
      } else {
        this.uploadCourseViaExcel = true;
      }
    },
    setSchema() {
      this.schema = {
        Sheet1: {
          "Academic Year": {
            validation: {
              type: "list",
              values: this.yearOfIntroductionItems,
              required: true,
            },
            variableName: "academicYear",
          },
          "Course Code": {
            validation: {
              type: "textLength",
              required: true,
            },
            variableName: "courseCode",
          },
          "Course Name ": {
            validation: {
              type: "textLength",
              required: true,
            },
            variableName: "courseName",
          },
          "Total Credits": {
            validation: {
              type: "textLength",
              required: true,
            },
            variableName: "totalCredits",
          },
          "Course abbreviation": {
            validation: {
              type: "textLength",
              // required: true
            },
            variableName: "courseAbbreviation",
          },
          "Total Hrs/sem": {
            validation: {
              type: "textLength",
              // required: true
            },
            variableName: "hrsPerSemester",
          },
        },
      };
    },
    async downloadSampleExcel() {
      this.setSchema();
      this.excelLink = await generateFromJson(this.schema);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = this.excelLink;
      link.target = "_blank"; // Open in a new tab
      // Trigger the download
      link.click();
    },
    async uploadCourseExcel() {
      this.coursesArray = [];
      this.setSchema();
      let res = await generateJsonFromExcel(
        this.courseExcelFile,
        this.schema,
        false
      );
      const { errors, data } = res;
      this.excelErrors = errors;
      if (this.excelErrors.length === 0) {
        data.Sheet1.forEach((courses) => {
          this.refillDataFromExcel(courses);
        });
        this.addCourse();
        this.coursesArray = [];
        this.uploadCourseViaExcel = false;
      }
    },
    refillDataFromExcel(data) {
      data.courseName = this.capitalizeTitle(data.courseName);
      let courseObj = {
        instituteId: this.selectedInstitute,
        subjectCode: data.courseCode,
        subjectName: data.courseName,
        courseAbbreviation: data?.courseAbbreviation,
        hrsPerSemester: data?.hrsPerSemester,
        credits: data.totalCredits,
        selectedIntroductionYear: data.academicYear,
      };
      this.coursesArray.push(courseObj);
    },
    capitalizeTitle(title) {
      const conjunctions = ["and", "or", "but", "nor", "for", "so", "yet", "of", "in"];

      return title
        .split(" ")
        .map((word, index) => {
          if (conjunctions.includes(word.toLowerCase()) && index !== 0) {
            return word.toLowerCase();
          }
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(" ");
    },
    handleCourseDailog(item) {
      this.addCourseDailog = true;
      this.courseObject = { ...item };
    },
    filterAcademicYear() {
      this.filteredCourses = this.fetchedCourses.filter(
        (c) => c.yearOfIntroduction === this.selectedIntroductionYear
      );
      if (this.selectedIntroductionYear == "Other") {
        // yearOfIntroductionItems
        this.filteredCourses = this.fetchedCourses.filter(
          (c) =>
            !c.yearOfIntroduction ||
            !this.yearOfIntroductionItems.includes(c.yearOfIntroduction) ||
            c.yearOfIntroduction === "Other"
        );
      }
    },
    async addCourse() {
      let arrayToSend = [];
      if (this.courseObject.subjectCode)
        this.coursesArray.push(this.courseObject);
      this.coursesArray.forEach(async (course) => {
        let courseObj = {
          instituteId: this.selectedInstitute,
          subjectCode: course?.subjectCode,
          subjectName: course?.subjectName,
          courseAbbreviation: course?.courseAbbreviation,
          hrsPerSemester: course?.hrsPerSemester,
          credits: course?.credits,
          yearOfIntroduction: course.selectedIntroductionYear,
        };
        if (this.courseObject.instituteSubjectId) {
          courseObj.instituteSubjectId = this.courseObject.instituteSubjectId;
        }
        arrayToSend.push(courseObj);
      });
      if (arrayToSend.length > 0) {
        try {
          const response =
            await this.subjectInstituteLevelInstance.bulkCreateSubjectDocument(
              arrayToSend
            );
          if (response.status === 200) {
            showStatus("Course updated successfully", 3000, "success", this);
            await this.fetchCourses();
            this.filterAcademicYear();
          } else {
            showStatus("Something went wrong", 3000, "error", this);
          }
        } catch (error) {
          console.error("error", error);
          showStatus("Something went wrong", 3000, "error", this);
        }
      } else {
        showStatus("Please add course data", 3000, "warning", this);
      }
      this.addCourseDailog = false;
    },
    async fetchCourses() {
      try {
        const res =
          await this.subjectInstituteLevelInstance.getSubjectDocumentOfAInstitute(
            {
              instituteId: this.selectedInstitute,
            }
          );
        this.fetchedCourses = res;
      } catch (e) {
        console.error("Failed to get");
      }
    },
    // async getBatchesOfTheInstitute() {
    //   const data = await this.nbaRepositoryInstance.getBatchesOfInstitute({
    //     lmsInstituteId: this.selectedInstitute,
    //   });
    //   console.log('data', data);
    //   const tempBatches = []
    //   const tempAcademicYears = []
    //   // const result = data.map
    //   data.map((item) => {
    //     tempBatches.push(item.batchName)
    //     item.batch.map((ay) => {
    //       tempAcademicYears.push({academicYearId: ay.academicId})
    //     })
    //   })
    // },
    // gotoDashboard() {
    //   this.$router.push('/activityInstitutesManagment')
    // },
    // cancelUploadDialog() {
    //   this.uploadExcelSubject = false
    // },
    // uploadExcelToAddSubject() {
    //   if (this.uploadExcelForAddSubject) {
    //     var formData = new FormData()
    //     formData.append('excel', this.uploadExcelForAddSubject)
    //     formData.append('instituteId', this.selectedInstitute)
    //     let url = process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT + '/excel/instituteLevelSubject/parseInstituteLevelSubject'
    //     axios
    //       .post(
    //         process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT + '/excel/instituteLevelSubject/parseInstituteLevelSubject',
    //         formData,
    //         {
    //           headers: {
    //             'Content-Type': 'multipart/form-data'
    //           }
    //         }
    //       )
    //       .then(
    //         response => {
    //           this.uploadExcelSubject = false
    //           console.log('response', response);
    //           if (response.status === 200) {
    //             this.showErrorOfExcel = true
    //             this.errorMessageByExcel = response.data.errorMessage
    //             this.allErrorArray = response.data.errorData
    //             // showStatus('Subject updated successfully', 3000, 'success', this)
    //           } else {
    //             this.showErrorOfExcel = true
    //             this.errorMessageByExcel = response.data.errorMessage
    //             this.allErrorArray = []
    //             // showStatus('Something went wrong.Please check excel data', 3000, 'error', this)
    //           }
    //         },
    //         error => {
    //           this.uploadExcelSubject = false
    //           console.log(error)
    //           // showStatus('Something went wrong.Please check excel data', 3000, 'error', this)
    //           this.showErrorOfExcel = true
    //           this.errorMessageByExcel = 'Something went wrong.Please try again'
    //           this.allErrorArray = []
    //         }
    //       )
    //     setTimeout(() => {
    //       this.fetchData()
    //     }, 1000)
    //   }
    // },
    // openDialogAddSubject() {
    //   this.uploadExcelForAddSubject = []
    //   this.uploadExcelSubject = true
    // },
    // deleteValueAdded(item) {
    //   this.valueAddedActivityIdForDelete = item.valueAddedActivityId
    //   this.deletingValueAddedDialog = true
    // },
    // async deleteValueAddedActivity() {
    //   let obj = {
    //     valueAddedActivityId: this.valueAddedActivityIdForDelete,
    //     instituteId: this.selectedInstitute
    //   }
    //   try {
    //     const res = await this.valueAddedActivityInstance.deleteValueAddedActivity(obj)
    //     showStatus('Value added activity deleted successfully', 3000, 'success', this)
    //     await this.fetchData()

    //   } catch (error) {
    //     console.log(error)
    //     showStatus('Value added activity not deleted', 3000, 'error', this)
    //     this.fetchData()

    //   }
    //   this.deletingValueAddedDialog = false

    // },

    // editValueAddedActivity(item) {
    //   this.$router.push({
    //     name: 'activityValueAdded',
    //     params: {
    //       Props_Data: item,
    //       Props_Edit: true,
    //     }
    //   });

    //   this.valueAddedPrerequisite = []
    //   this.valueAddedActivityId = item.valueAddedActivityId
    //   this.dialogValueAddedEdit = true
    //   this.valueAddedTitle = item.title
    //   this.valueAddedLevelActivity = item.levelOfActivity
    //   if (item.description) {

    //     this.valueAddedDescription = item.description
    //   }
    //   if (item.activityCode) {
    //     this.valueAddedActivityCode = item.activityCode
    //   }
    //   if (item.typeOfActivity) {

    //     this.valueAddedTypeOfActivity = item.typeOfActivity
    //   }
    //   if (item.credits) {

    //     this.valueAddedTotalCredits = item.credits
    //   }
    //   if (item.duration) {

    //     this.valueAddedTotalDuration = item.duration
    //   }
    //   if (item.prerequisite) {

    //     this.tempValueAddedPrerequisite = item.prerequisite
    //   }
    //   if (item.mapActivityPO) {

    //     this.valueAddedPO = item.mapActivityPO
    //   }
    //   if (item.valueAddedCO) {

    //     this.valueAddedCO = item.valueAddedCO
    //   }
    //   if (item.mapActivityPSO) {

    //     this.valueAddedPSO = item.mapActivityPSO
    //   }
    //   if (item.mapActivityBlooms) {

    //     this.valueAddedBloomCo = item.mapActivityBlooms
    //   }
    //   if (item.mapActivityBlooms) {

    //     this.valueAddedBloomPO = item.mapActivityBlooms
    //   }
    //   if (item.gap) {

    //     this.valueAddedGap = item.gap
    //   }
    //   if (item.course) {

    //     this.valueAddedLevelCourse = item.course
    //   }
    //   if (item.assignedFaculty) {
    //     this.valueAddedAssignFaculty = item.assignedFaculty[0]
    //   }
    //   this.valueAddedFeedbackValue = item.createFeedbackForm ? 'true' : 'false'
    // },

    // async valueAddedDialog() {
    //   // this.dialogValueAdded = true
    //   this.$router.push({
    //     name: 'activityValueAdded',
    //     params: {
    //       Props_Edit: false,
    //     }
    //   });
    //   this.valueAddedActivityLevelInput = ''
    //   this.valueAddedTitle = ''
    //   this.valueAddedDescription = ''
    //   this.valueAddedLevelActivity = ''
    //   this.valueAddedActivityCode = ''
    //   this.valueAddedTypeOfActivity = ''
    //   this.valueAddedLevelCourse = ''
    //   this.valueAddedAssignFaculty = []
    //   this.valueAddedTotalCredits = null
    //   this.valueAddedTotalDuration = null
    //   this.valueAddedPrerequisite = []
    //   this.valueAddedCO = []
    //   this.valueAddedPO = []
    //   this.valueAddedPSO = []
    //   this.valueAddedBloomPO = []
    //   this.valueAddedBloomCo = []
    //   this.valueAddedGap = ''
    //   this.valueAddedFeedbackValue = ''

    // },
    // async fetchData() {
    //   this.loadingForDocument = true
    //   this.loadingForValue = true
    //   try {
    //     const res = await this.subjectInstituteLevelInstance.getSubjectDocumentOfAInstitute({
    //       instituteId: this.selectedInstitute
    //     })
    //     this.subjects = res
    //     this.groupNameArr = []
    //     this.groupNameArr.push('Other/ Add New')
    //     this.subjects.map(async (sub) => {
    //       if (sub.subjectGroupName !== undefined && sub.subjectGroupName !== '') {
    //         this.groupNameArr.push(sub.subjectGroupName)
    //       }
    //     })
    //     let diplicateRemove = [...new Set(this.groupNameArr)]
    //     this.groupNameArr = diplicateRemove
    //   } catch (error) {
    //     console.log('error', error);
    //   }
    //   try {
    //     let obj = {
    //       instituteId: this.selectedInstitute
    //     }
    //     this.valueAddedActivityAll = []
    //     let response = await this.valueAddedActivityInstance.getAllValueAddedActivityOfInstitute(obj)
    //     this.valueAddedActivityAll = response
    //   } catch (error) {
    //     console.log('error', error);
    //   }

    //   this.loadingForDocument = false
    //   this.loadingForValue = false
    //   this.waitLoader = true
    // },

    // convertSubjectTypeToName(num) {

    //   let type;
    //   switch (num) {

    //     case 0: {
    //       type = 'Theory & Compulsory'
    //       break;
    //     }
    //     case 1: {
    //       type = 'Practical & Compulsory'
    //       break;
    //     }
    //     case 2: {
    //       type = 'Tutorial & Compulsory'
    //       break;
    //     }
    //     case 3: {
    //       type = 'Audit Course & Compulsory'
    //       break;
    //     }
    //     case 4: {
    //       type = 'Theory & Elective'
    //       break;
    //     }
    //     case 5: {
    //       type = 'Practical & Elective'
    //       break;
    //     }
    //     case 6: {
    //       type = 'Tutorial & Elective'
    //       break;
    //     }
    //     case 7: {
    //       type = 'Audit Course & Elective'
    //       break;
    //     }

    //     default:
    //       type = 'Theory'

    //     // code block
    //   }
    //   return type

    // },

    // removeLink(index, array) {
    //   array.splice(index, 1)
    // },

    // cancelEditingDialog() {
    //   this.subjectFramework = '',
    //     this.subjectFrameworkByInput = '',
    //     this.subjectIsNew = '',
    //     this.subjectIsForNaac = '',
    //     this.subjectGroupNameNewBySelect = '',
    //     this.subjectNameNew = '',
    //     this.subjectCodeNew = '',
    //     this.subjectGroupNameNew = '',
    //     // this.subjectGroupNameNewBySelect = ''
    //     this.subjectComponent = [],
    //     this.subjectDescription = '',
    //     this.totalCredits = null,
    //     this.totalHrsWeek = null,
    //     this.totalMarks = null,
    //     this.subjectObjectiveFile = [],
    //     this.subjectObjectiveFileLink = [],
    //     this.subjectSyllabusFile = [],
    //     this.subjectSyllabusFileLink = [],
    //     this.lectureHrs = 0,
    //     this.tutorialHrs = 0,
    //     this.practicalHrs = 0,
    //     this.selfStudyHrs = 0,
    //     this.totalContactHrs = 0,
    //     this.subjectTypeArr = [],
    //     this.subjectTypeRadioBtn = '',
    //     this.chooseElectiveByStudent = '',
    //     this.outcomesFile = [],
    //     this.outcomesFileLink = [],
    //     this.selectedCoordinaterName = [],
    //     this.instructionMaterialFile = [],
    //     this.instructionMaterialFileLink = [],
    //     this.prerequisiteFile = [],
    //     this.prerequisiteFileLink = [],
    //     this.methodologyFile = [],
    //     this.methodologyFileLink = [],

    //     this.editingVariant = false
    // },

    // async uploadFile(fileArr) {
    //   try {
    //     let urlLinkArr = [];
    //     if (fileArr.length > 0) {

    //       let data = await this.uploadToDigitalOceanInstance.upload(fileArr, this.userData.uId)
    //       return data

    //     }
    //   } catch (error) {
    //     console.log('error')
    //     let arr = []
    //     return arr
    //   }

    // },

    // clearGroupNameField() {
    //   this.subjectGroupNameNewBySelect = ''
    // },
    // clearGroupNameSelect() {
    //   this.subjectGroupNameNew = ''
    // },

    // giveSubjectTypeNumber(type, subtype) {

    //   if (type === 'Theory' && subtype === 'compulsory') {
    //     return 0;
    //   }
    //   if (type === 'Practical' && subtype === 'compulsory') {
    //     return 1;
    //   }
    //   if (type === 'Tutorial' && subtype === 'compulsory') {
    //     return 2;
    //   }
    //   if (type === 'AuditCourse' && subtype === 'compulsory') {
    //     return 3;
    //   }
    //   if (type === 'Theory' && subtype === 'elective') {
    //     return 4;
    //   }
    //   if (type === 'Practical' && subtype === 'elective') {
    //     return 5;
    //   }
    //   if (type === 'Tutorial' && subtype === 'elective') {
    //     return 6;
    //   }
    //   if (type === 'AuditCourse' && subtype === 'elective') {
    //     return 7;
    //   }
    // },

    // editSubject(sub) {
    //   console.log('sub', sub);
    //   this.subjectObjectiveFile = [],
    //     this.subjectSyllabusFile = [],
    //     this.outcomesFile = [],
    //     this.instructionMaterialFile = [],
    //     this.prerequisiteFile = [],
    //     this.methodologyFile = []

    //   this.instituteSubjectIdForEdit = ''
    //   this.instituteSubjectIdForEdit = sub.instituteSubjectId

    //   this.subjectNameNew = sub.subjectName
    //   this.subjectCodeNew = sub.subjectCode
    //   this.subjectIsForNaac = sub.isForNaac === true ? 'true' : 'false'

    //   if (sub.isNewForm !== undefined) {

    //     if (sub.isNewForm === true) {
    //       this.subjectIsNew = 'true'
    //     } else {
    //       this.subjectIsNew = 'false'

    //     }
    //   }
    //   if (sub.framework) {
    //     if (!this.framework.includes(sub.framework)) {

    //       this.subjectFrameworkByInput = sub.framework
    //       this.subjectFramework = 'Other'
    //     } else {
    //       this.subjectFrameworkByInput = ''
    //       this.subjectFramework = sub.framework
    //     }

    //   }
    //   if (sub.subjectGroupName) {

    //     this.subjectGroupNameNewBySelect = sub.subjectGroupName
    //   }

    //   if (sub.subjectComponent) {
    //     this.subjectComponent = [...sub.subjectComponent]
    //   }

    //   if (sub.subjectObjectiveFile) {

    //     this.tempSubjectObjectiveFile = sub.subjectObjectiveFile
    //     this.subjectObjectiveFile = []
    //   }

    //   if (sub.subjectDescription) {

    //     this.subjectDescription = sub.subjectDescription
    //   }

    //   if (sub.credits) {

    //     this.totalCredits = sub.credits
    //   }

    //   if (sub.totalMarks) {

    //     this.totalMarks = sub.totalMarks
    //   }

    //   if (sub.totalHrsForWeek) {

    //     this.totalHrsWeek = sub.totalHrsForWeek
    //   }

    //   if (sub.lectureHrs) {

    //     this.lectureHrs = sub.lectureHrs
    //   }

    //   if (sub.tutorialHrs) {

    //     this.tutorialHrs = sub.tutorialHrs
    //   }

    //   if (sub.practicalHrs) {

    //     this.practicalHrs = sub.practicalHrs
    //   }

    //   if (sub.selfStudyHrs) {

    //     this.selfStudyHrs = sub.selfStudyHrs
    //   }

    //   if (sub.totalContactHrs) {

    //     this.totalContactHrs = sub.totalContactHrs
    //   }

    //   let electiveNumber = sub.subjectType

    //   if (electiveNumber === 0 || electiveNumber === 4) {
    //     this.subjectTypeArr.push('Theory')
    //   }
    //   if (electiveNumber === 1 || electiveNumber === 5) {
    //     this.subjectTypeArr.push('Practical')
    //   }
    //   if (electiveNumber === 2 || electiveNumber === 6) {
    //     this.subjectTypeArr.push('Tutorial')
    //   }
    //   if (electiveNumber === 3 || electiveNumber === 7) {
    //     this.subjectTypeArr.push('AuditCourse')
    //   }

    //   if (electiveNumber === 0 || electiveNumber === 1 || electiveNumber === 2 || electiveNumber === 3) {
    //     this.subjectTypeRadioBtn = 'compulsory'
    //   } else {
    //     this.subjectTypeRadioBtn = 'elective'

    //   }

    //   if (sub.refBookDetails) {
    //     this.arrForBookNameRow = sub.refBookDetails
    //   }

    //   if (sub.outcomesFile) {
    //     this.tempOutcomesFile = sub.outcomesFile

    //   }

    //   if (sub.instructionMaterialFile) {
    //     this.tempInstructionMaterialFile = sub.instructionMaterialFile

    //   }

    //   if (sub.prerequisiteFile) {
    //     this.tempPrerequisiteFile = sub.prerequisiteFile

    //   }

    //   if (sub.methodologyFile) {
    //     this.tempMethodologyFile = sub.methodologyFile

    //   }

    //   this.editingVariant = true
    //   this.e1 = 1
    // },

    // async saveEditedSubject() {

    //   let electiveNumber = this.giveSubjectTypeNumber(this.subjectTypeArr[0], this.subjectTypeRadioBtn)

    //   this.step2Progrss = true

    //   const objToPush = {
    //     instituteId: this.selectedInstitute,
    //     instituteSubjectId: this.instituteSubjectIdForEdit
    //   }

    //   objToPush.subjectName = this.subjectNameNew
    //   objToPush.subjectCode = this.subjectCodeNew
    //   objToPush.subjectType = Number(electiveNumber)
    //   objToPush.subjectGroupName = this.finialGroupName
    //   objToPush.isForNaac = this.subjectIsForNaac === 'true' ? true : false

    //   if (this.subjectIsNew !== '') {
    //     objToPush.isNewForm = this.subjectIsNew === 'true' ? true : false

    //   }
    //   if (this.subjectFramework !== '') {
    //     if (this.subjectFramework === 'Other') {

    //       objToPush.framework = this.subjectFrameworkByInput
    //     } else {
    //       objToPush.framework = this.subjectFramework
    //     }

    //   }

    //   objToPush.subjectComponent = this.subjectComponent
    //   objToPush.subjectDescription = this.subjectDescription
    //   objToPush.credits = Number(this.totalCredits)
    //   objToPush.totalMarks = Number(this.totalMarks)
    //   objToPush.totalHrsForWeek = Number(this.totalHrsWeek)

    //   let tempSubjectObjectiveFileLink = [...this.subjectObjectiveFileLink, ...this.tempSubjectObjectiveFile]
    //   objToPush.subjectObjectiveFile = tempSubjectObjectiveFileLink

    //   objToPush.subjectSyllabusFile = this.subjectSyllabusFileLink
    //   objToPush.lectureHrs = Number(this.lectureHrs)
    //   objToPush.tutorialHrs = Number(this.tutorialHrs)
    //   objToPush.practicalHrs = Number(this.practicalHrs)
    //   objToPush.selfStudyHrs = Number(this.selfStudyHrs)
    //   objToPush.totalContactHrs = this.totalContactHrs

    //   if (this.outcomesFile.length > 0) {

    //     this.outcomesFileLink = await this.uploadFile(this.outcomesFile)
    //   }

    //   if (this.instructionMaterialFile.length > 0) {

    //     this.instructionMaterialFileLink = await this.uploadFile(this.instructionMaterialFile)
    //   }

    //   if (this.prerequisiteFile.length > 0) {

    //     this.prerequisiteFileLink = await this.uploadFile(this.prerequisiteFile)
    //   }

    //   if (this.methodologyFile.length > 0) {

    //     this.methodologyFileLink = await this.uploadFile(this.methodologyFile)
    //   }

    //   let tempOutcomesFile = [...this.outcomesFileLink, ...this.tempOutcomesFile]
    //   let tempInstructionMaterialFile = [...this.instructionMaterialFileLink, ...this.tempInstructionMaterialFile]
    //   let tempPrerequisiteFile = [...this.prerequisiteFileLink, ...this.tempPrerequisiteFile]
    //   let tempMethodologyFile = [...this.methodologyFileLink, ...this.tempMethodologyFile]

    //   objToPush.refBookDetails = this.arrForBookNameRow
    //   objToPush.outcomesFile = tempOutcomesFile
    //   objToPush.instructionMaterialFile = tempInstructionMaterialFile
    //   objToPush.prerequisiteFile = tempPrerequisiteFile
    //   objToPush.methodologyFile = tempMethodologyFile

    //   try {
    //     await this.subjectInstituteLevelInstance.updateSubjectDocument(objToPush)

    //     this.editingVariant = false
    //     showStatus('Subject updated successfully', 3000, 'success', this)

    //   } catch (error) {
    //     console.log(error)
    //     showStatus('Subject not updated Please try again', 3000, 'error', this)

    //   }
    //   this.step2Progrss = false

    //   setTimeout(() => {

    //     this.fetchData()
    //   }, 1000)

    // },

    // async createSubjectByNewFormat() {
    //   this.step2Progrss = true

    //   this.subjectTypeArr.map(async (type) => {

    //     const objToPush = {}
    //     let electiveNumber = this.giveSubjectTypeNumber(type, this.subjectTypeRadioBtn)

    //     objToPush.instituteId = this.selectedInstitute
    //     objToPush.subjectName = this.subjectNameNew
    //     objToPush.subjectCode = this.subjectCodeNew
    //     objToPush.subjectType = Number(electiveNumber)
    //     objToPush.isForNaac = this.subjectIsForNaac === 'true' ? true : false

    //     if (this.subjectIsNew !== '') {
    //       objToPush.isNewForm = this.subjectIsNew === 'true' ? true : false
    //     }

    //     if (this.subjectFramework !== '') {
    //       if (this.subjectFramework === 'Other') {
    //         objToPush.framework = this.subjectFrameworkByInput
    //       } else {
    //         objToPush.framework = this.subjectFramework
    //       }
    //     }

    //     objToPush.subjectGroupName = this.finialGroupName
    //     objToPush.subjectGroupId = new Date().valueOf()
    //     objToPush.subjectComponent = this.subjectComponent
    //     objToPush.subjectDescription = this.subjectDescription
    //     objToPush.credits = Number(this.totalCredits)
    //     objToPush.totalMarks = Number(this.totalMarks)
    //     objToPush.totalHrsForWeek = Number(this.totalHrsWeek)

    //     objToPush.subjectObjectiveFile = this.subjectObjectiveFileLink
    //     objToPush.subjectSyllabusFile = this.subjectSyllabusFileLink

    //     objToPush.lectureHrs = Number(this.lectureHrs)
    //     objToPush.tutorialHrs = Number(this.tutorialHrs)
    //     objToPush.practicalHrs = Number(this.practicalHrs)
    //     objToPush.selfStudyHrs = Number(this.selfStudyHrs)
    //     objToPush.totalContactHrs = this.totalContactHrs

    //     if (this.arrForBookNameRow.length !== 0) {
    //       objToPush.refBookDetails = this.arrForBookNameRow
    //     }

    //     if (this.selectedCoordinaterName.length !== 0) {
    //       objToPush.subjectCoordinators = this.selectedCoordinaterName
    //     }

    //     if (this.outcomesFile.length > 0) {
    //       this.outcomesFileLink = await this.uploadFile(this.outcomesFile)

    //       if (this.outcomesFileLink.length !== 0) {
    //         objToPush.outcomesFile = this.outcomesFileLink
    //       } else {
    //         showStatus('OutcomesFile was not uploaded Please try again.', 3000, 'error', this)
    //       }

    //     }

    //     if (this.instructionMaterialFile.length > 0) {
    //       this.instructionMaterialFileLink = await this.uploadFile(this.instructionMaterialFile)
    //       if (this.instructionMaterialFileLink.length !== 0) {
    //         objToPush.instructionMaterialFile = this.instructionMaterialFileLink

    //       } else {
    //         showStatus('InstructionMaterialFile was not uploaded Please try again.', 2000, 'error', this)

    //       }
    //     }

    //     if (this.prerequisiteFile.length > 0) {
    //       this.prerequisiteFileLink = await this.uploadFile(this.prerequisiteFile)

    //       if (this.prerequisiteFileLink.length !== 0) {
    //         objToPush.prerequisiteFile = this.prerequisiteFileLink
    //       } else {
    //         showStatus('PrerequisiteFile was not uploaded Please try again.', 2000, 'error', this)
    //       }
    //     }
    //     if (this.methodologyFile.length > 0) {
    //       this.methodologyFileLink = await this.uploadFile(this.methodologyFile)
    //       if (this.methodologyFileLink.length !== 0) {
    //         objToPush.methodologyFile = this.methodologyFileLink

    //       } else {
    //         showStatus('MethodologyFile was not uploaded Please try again.', 2000, 'error', this)

    //       }
    //     }
    //     try {
    //       const response = await this.subjectInstituteLevelInstance.createSubjectDocument(objToPush)
    //       showStatus('Subject Created Successfully.', 1000, 'success', this)
    //       this.addingSubject = false
    //       await this.fetchData()

    //     } catch (err) {
    //       console.log(err)
    //       showStatus('Subject not added. Please try again', 3000, 'error', this)
    //       this.addingSubject = false
    //       await this.fetchData()

    //     }

    //   })
    //   this.step2Progrss = false

    // },

    // addingSubjectStepperOpen() {
    //   this.e1 = 1
    //   this.arrForBookNameRow = [];
    //   let obj1 = {
    //     refBookName: '',
    //     refLink: '',
    //     addIcon: true
    //   }
    //   this.arrForBookNameRow.push(obj1)

    //   this.subjectFramework = '',
    //     this.subjectFrameworkByInput = '',
    //     this.subjectIsNew = '',
    //     this.subjectIsForNaac = '',
    //     this.subjectNameNew = '',
    //     this.subjectCodeNew = '',
    //     this.subjectComponent = [],
    //     this.subjectGroupNameNew = '',
    //     this.subjectGroupNameNewBySelect = ''
    //   this.subjectDescription = '',
    //     this.totalCredits = null,
    //     this.totalHrsWeek = null,
    //     this.totalMarks = null,
    //     this.subjectObjectiveFile = [],
    //     this.subjectObjectiveFileLink = [],
    //     this.subjectSyllabusFile = [],
    //     this.subjectSyllabusFileLink = [],
    //     this.lectureHrs = 0,
    //     this.tutorialHrs = 0,
    //     this.practicalHrs = 0,
    //     this.selfStudyHrs = 0,
    //     this.totalContactHrs = 0,
    //     this.subjectTypeArr = [],
    //     this.subjectTypeRadioBtn = '',
    //     this.outcomesFile = [],
    //     this.outcomesFileLink = [],
    //     this.selectedCoordinaterName = [],
    //     this.instructionMaterialFile = [],
    //     this.instructionMaterialFileLink = [],
    //     this.prerequisiteFile = [],
    //     this.prerequisiteFileLink = [],
    //     this.methodologyFile = [],
    //     this.methodologyFileLink = [],

    //     this.addingSubject = true
    // },

    // async gotoSecondStepEdit() {
    //   if (this.subjectGroupNameNew === '') {
    //     this.finialGroupName = this.subjectGroupNameNewBySelect
    //   }
    //   if (this.subjectGroupNameNewBySelect === 'Other/ Add New') {
    //     this.finialGroupName = this.subjectGroupNameNew
    //   }

    //   if (this.subjectNameNew === '' || this.subjectCodeNew === '' || this.subjectTypeArr.length === 0 || this.subjectTypeRadioBtn === '' || this.totalMarks === null || this.totalCredits === '') {
    //     alert('Please fill compulsory fields')

    //   } else {
    //     this.step1Progrss = true
    //     if (this.subjectObjectiveFile.length > 0) {

    //       this.subjectObjectiveFileLink = await this.uploadFile(this.subjectObjectiveFile)
    //     }
    //     if (this.subjectSyllabusFile) {
    //       await this.uploadSyllabusExcel(this.subjectSyllabusFile)

    //     }

    //     this.e1 = 2
    //     this.step1Progrss = false
    //   }

    // },

    // async gotoSecondStepNewFormat() {

    //   if (this.subjectGroupNameNew === '') {
    //     this.finialGroupName = this.subjectGroupNameNewBySelect
    //   }
    //   if (this.subjectGroupNameNewBySelect === 'Other/ Add New') {
    //     this.finialGroupName = this.subjectGroupNameNew
    //   }

    //   if (this.subjectNameNew === '' || this.subjectCodeNew === '' || this.subjectTypeArr.length === 0 || this.subjectTypeRadioBtn === '' || this.subjectIsForNaac === '' || this.subjectIsNew === '') {
    //     alert('Please fill compulsory fields')

    //   } else {
    //     this.step1Progrss = true
    //     if (this.subjectObjectiveFile.length > 0) {

    //       this.subjectObjectiveFileLink = await this.uploadFile(this.subjectObjectiveFile)
    //     }
    //     if (this.subjectSyllabusFile) {
    //       await this.uploadSyllabusExcel(this.subjectSyllabusFile)

    //     }

    //     this.e1 = 2
    //     this.step1Progrss = false
    //   }

    // },

    // uploadSyllabusExcel(subjectSyllabusFile) {
    //   if (subjectSyllabusFile) {
    //     var formData = new FormData()

    //     formData.append('excel', subjectSyllabusFile)
    //     let url = process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT + '/excel/subject/subjectSyllabusFile'
    //     axios
    //       .post(
    //         process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT + '/excel/subject/subjectSyllabusFile',
    //         formData,
    //         {
    //           headers: {
    //             'Content-Type': 'multipart/form-data'
    //           }
    //         }
    //       )
    //       .then(
    //         response => {
    //           if (response.status === 200) {
    //             this.subjectSyllabusFileLink = []
    //             this.subjectSyllabusFileLink = response.data.subjectSyllabusData

    //           } else {
    //             // console.log("not parse")
    //           }
    //         },
    //         error => {
    //           console.log(error)
    //         }
    //       )
    //       .then(() => {
    //         this.uploadExcelForSubject = false

    //       })
    //   }

    // },

    // countTotalContactHrs() {

    //   this.totalContactHrs = Number(this.lectureHrs) + Number(this.tutorialHrs) + Number(this.practicalHrs)
    // },

    // addRowBookName() {
    //   let obj = {
    //     refBookName: '',
    //     refLink: '',
    //     addIcon: false
    //   }
    //   this.arrForBookNameRow.push(obj)
    // },

    // deleteRowBookName(i) {
    //   this.arrForBookNameRow.splice(i, 1)
    // },
    // deleteDialogOpen(sub) {
    //   this.tempSubjectDocumentIdForDelete = ''
    //   this.tempSubjectDocumentIdForDelete = sub.instituteSubjectId
    //   this.tempSubjectForDeleting = JSON.parse(JSON.stringify(sub))
    //   this.deletingVariant = true
    // },
    // async deleteVariant() {
    //   await this.subjectInstituteLevelInstance.deleteSubjectDocument(
    //     {
    //       instituteSubjectId: this.tempSubjectDocumentIdForDelete,
    //       instituteId: this.selectedInstitute
    //     })
    //   this.deletingVariant = false
    //   this.fetchData()
    // },

    // percentageCompleted(item) {
    //   let addPercentage = 5.2

    //   let totalCount = 0

    //   if (item.isForNaac) {
    //     totalCount = totalCount + addPercentage
    //   }
    //   if (item.isNewForm) {
    //     totalCount = totalCount + addPercentage
    //   }
    //   if (item.framework) {
    //     totalCount = totalCount + addPercentage
    //   }
    //   if (item.subjectName) {
    //     totalCount = totalCount + addPercentage
    //   }

    //   if (item.subjectCode) {
    //     totalCount = totalCount + addPercentage

    //   }

    //   if (item.subjectType !== undefined) {
    //     totalCount = totalCount + addPercentage

    //   }

    //   if (item.subjectGroupName !== '') {
    //     totalCount = totalCount + addPercentage

    //   }

    //   if (item.subjectComponent.length > 0) {
    //     totalCount = totalCount + addPercentage

    //   }

    //   if (item.subjectDescription !== '') {
    //     totalCount = totalCount + addPercentage

    //   }

    //   if (item.credits !== 0) {
    //     totalCount = totalCount + addPercentage

    //   }

    //   if (item.totalHrsForWeek !== 0) {
    //     totalCount = totalCount + addPercentage

    //   }

    //   if (item.subjectObjectiveFile.length > 0) {
    //     totalCount = totalCount + addPercentage

    //   }

    //   if (item.subjectSyllabusFile.length > 0) {
    //     totalCount = totalCount + addPercentage

    //   }

    //   if (item.totalContactHrs !== 0) {
    //     totalCount = totalCount + addPercentage

    //   }

    //   item.refBookDetails.map((data, i) => {
    //     if (data.refBookName !== '' && i === 0) {
    //       totalCount = totalCount + addPercentage
    //     }
    //   })

    //   if (item.outcomesFile.length > 0) {
    //     totalCount = totalCount + addPercentage

    //   }
    //   if (item.instructionMaterialFile.length > 0) {
    //     totalCount = totalCount + addPercentage

    //   }
    //   if (item.prerequisiteFile.length > 0) {
    //     totalCount = totalCount + addPercentage

    //   }
    //   if (item.methodologyFile.length > 0) {
    //     totalCount = totalCount + addPercentage

    //   }
    //   return Math.floor(totalCount)
    // }
  },
};
</script>
<style scoped src="./activityAddSubjectInstituteLevel.css"></style>
