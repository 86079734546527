<template src="./activitySelectInstituteNSemester.html"></template>
<script>
import GetInstitutesAndSemestersRepository from "../../Repository/GetInstitutesAndSemesters";
import SemesterUserRepository from "../../Repository/SemesterUser";
import showStatus from "../../NetworkManager/showStatus";
import UserRepository from "../../Repository/User";
import ExamSettingsRepository from "../../Repository/ExamSettings";
import UserAuthenticationRepository from "../../Repository/UserAuthentication";
import {
  allowedModule,
  allowedModuleDetails,
} from "../../Services/Utils/allowedModule";
import NetworkManager from "../../NetworkManager";
import InstituteRepository from "../../Repository/Institute";
import InstituteAPIs from "../../ApiNames/Institute";
import auth from "../../Services/Firebase/auth";
import SendEmailAlert from "../../Repository/SendEmailAlert";

export default {
  name: "activitySelectInstituteNSemester",
  data() {
    return {
      isMobileView: false,
      creatingInstitute: false,
      userData: {},
      alignment: "center",
      justify: "center",
      selectedInstitute: "",
      institutes: [],
      user: "",
      tempFirstName: "",
      tempMiddleName: "",
      tempLastName: "",
      logoutDialog: false,
      brandingName: "",
      brandingLogo: "",
      dataLoader: false,
      userRole: "",
      page: 1,
      currentLocation: "",
    };
  },
  created() {
    this.allowedModuleForSubAdmin = allowedModule;
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.UserAuthenticationRepositoryInstance =
      new UserAuthenticationRepository(this);
    this.getInstitutesAndSemestersRepositoryInstance =
      new GetInstitutesAndSemestersRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.instituteRepositoryInstance = new InstituteRepository(this);
    this.examSettingsRepositoryInstance = new ExamSettingsRepository(this);
    this.userData = this.$store.getters["user/get_userData"];
    this.fetchData();
  },
  methods: {
    async fetchBrandingDetails(instituteId) {
      try {
        const data = await NetworkManager.getRequest(
          InstituteAPIs.getInstituteBranding,
          { instituteId: instituteId }
        );
        return data;
      } catch (error) {
        console.error({ error });
      }
    },
    async fetchData() {
      this.dataLoader = true;
      try {
        const objToPush = {
          uId: this.userData.uId,
        };
        let resForAuth =
          await this.UserAuthenticationRepositoryInstance.getUserAuthenticationByUid(
            objToPush
          );
        let arrForAllowedModule = [];
        if (resForAuth[0]?.roles.includes(3)) {
          this.userRole = "Sub Admin";
          this.$store.commit("liveData/set_allowedModule", {
            allowedModule: resForAuth[0].allowedModule,
          });
          if (resForAuth[0].assignedDepartmentForSubAdmin) {
            this.$store.commit("liveData/set_accessibleDepartment", {
              accessibleDepartment: resForAuth[0].assignedDepartmentForSubAdmin,
            });
          } else {
            this.$store.commit("liveData/set_accessibleDepartment", {
              accessibleDepartment: [],
            });
          }
          if (resForAuth[0].assignedCourseForSubAdmin) {
            this.$store.commit("liveData/set_accessibleCourseYearForSubAdmin", {
              accessibleCourseYearForSubAdmin:
                resForAuth[0].assignedCourseForSubAdmin,
            });
          } else {
            this.$store.commit("liveData/set_accessibleCourseYearForSubAdmin", {
              accessibleCourseYearForSubAdmin: [],
            });
          }
          this.$store.commit("liveData/set_isSubAdmin", { isSubAdmin: true });
        }
        if (resForAuth[0].roles.includes(2)) {
          this.userRole = "Admin";
          this.$store.commit("liveData/set_allowedModule", {
            allowedModule: allowedModuleDetails,
          });
          this.$store.commit("liveData/set_accessibleDepartment", {
            accessibleDepartment: [],
          });
          this.$store.commit("liveData/set_accessibleCourseYearForSubAdmin", {
            accessibleCourseYearForSubAdmin: [],
          });
          this.$store.commit("liveData/set_isSubAdmin", { isSubAdmin: false });
        }
        this.user = await this.userRepositoryInstance.getUserProfile(objToPush);
        this.tempFirstName = this.user.firstName;
        this.tempMiddleName = this.user.middleName;
        this.tempLastName = this.user.lastName;
        this.$store.commit("user/set_userData", {
          uId: this.user.uId,
          firstName: this.user.firstName,
          userName: this.user.userName,
        });
        this.institutes =
          await this.getInstitutesAndSemestersRepositoryInstance.getInstitutes(
            objToPush
          );
        let instituteIds = await this.institutes.map((e) => e.instituteId);
        this.$store.commit("liveData/set_relativeInstitutes", instituteIds);
        for (let i = 0; i < this.institutes.length; i++) {
          let data = await this.fetchBrandingDetails(
            this.institutes[i]?.instituteId
          );
          this.institutes[i].instituteLogoLink = data.instituteLogoLink;
        }
        showStatus(
          "Got Institutes and Semesters Successfully!",
          1000,
          "success",
          this
        );
        if (!(this.institutes.length > 1)) {
          this.selectInstitute(this.institutes[0]);
        }
      } catch (err) {
        console.error(err);
      }
      try {
        let examData =
          await this.examSettingsRepositoryInstance.getInstituteExamSetting({
            instituteId: this.institutes[0].instituteId,
          });
        this.$store.commit("liveData/set_cieSeePreference", {
          cieSeePreference: examData.result[0].selectedCieSeePreference,
        });
      } catch (error) {
        console.error(error)
      }
      this.dataLoader = false;
    },
    async selectInstitute(institute) {
      this.sendEmailAlertInstance = new SendEmailAlert(this);
      this.selectedInstitute = institute.instituteId;
      const instituteName = institute.instituteName;
      this.brandingName = institute.instituteBrandingName;
      this.brandingLogo = institute.instituteLogoLink;
      // this.$store.commit("liveData/set_brandingName", {
      //   brandingName: this.brandingName,
      // });
      // this.$store.commit("liveData/set_brandingLogo", {
      //   brandingLogo: this.brandingLogo,
      // });
      // this.$store.commit("liveData/set_instituteAddress", {
      //   instituteAddress: institute.instituteAddress,
      // });
      // this.$store.commit("liveData/set_admissionInstitute", {
      //   admissionInstitute: this.selectedInstitute,
      // });
      this.$store.dispatch('instituteData/set_instituteName', instituteName)
      this.$store.dispatch(
        "instituteData/set_selectedInstitute",
        this.selectedInstitute
      );
      const objToPush = {
        uId: this.userData.uId,
        instituteId: this.selectedInstitute,
      };
      this.institute =
      await this.instituteRepositoryInstance.getInstituteInformation(
        objToPush
        );
      if (this.institute.affiliatedInstitute) {
        this.$store.commit("liveData/set_isInstituteForNaacOrNBA", {
          isInstituteForNaacOrNBA: this.institute.affiliatedInstitute,
        });
      }
      this.$store.commit("liveData/set_brandingName", {
        brandingName: this.institute.instituteBrandingName,
      });
      this.$store.commit("liveData/set_brandingLogo", {
        brandingLogo: this.institute.instituteLogoLink,
      });
      this.$store.commit("liveData/set_instituteAddress", {
        instituteAddress: this.institute.instituteAddress,
      });
      this.$store.commit("liveData/set_admissionInstitute", {
        admissionInstitute: this.institute.instituteId,
      });
      this.$store.commit("liveData/set_instituteStudentLogoLink", {
        instituteStudentLogoLink: this.institute.instituteStudentLogoLink,
      });
      this.$store.commit("liveData/set_instituteStudentBrandingName", {
        instituteStudentBrandingName:
          this.institute.instituteStudentBrandingName,
      });
      this.$store.commit("liveData/set_subjectDescription", {
        subjectDescription: this.institute.subjectDescription,
      });
      this.$store.commit("liveData/set_displayName", {
        displayName: this.institute.displayName,
      });
      this.$store.commit("liveData/set_senderMailPassword", {
        senderMailPassword: this.institute.senderMailPassword,
      });
      this.$store.commit("liveData/set_senderMail", {
        senderMail: this.institute.senderMail,
      });
      this.$store.commit("liveData/set_emailBody", {
        emailBody: this.institute.emailBody,
      });
      this.$store.commit("liveData/set_reminderDays", {
        reminderDays: this.institute.reminderDays,
      });
      this.$store.commit("liveData/set_instituteType", this.institute.tier);
      if (this.institute.attainmentCalculationType) {
        this.$store.commit(
          "liveData/set_attainmentCalculationType",
          this.institute.attainmentCalculationType
        );
      }
      if (this.institute.cieSee) {
        this.$store.commit(
          "liveData/set_instituteCieSee",
          this.institute.cieSee
        );
      }
      if (this.institute.isCorporateInstitute) {
        this.$store.commit(
          "liveData/set_isCorporateInstitute",
          this.institute.isCorporateInstitute
        );
      }

      setTimeout(() => this.$router.push("/activityHome"), 100);
      /********************** Login Email Alert **********************/
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const { latitude, longitude } = position.coords;
        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

        const response = await fetch(url);
        const data = await response.json();

        if (data.display_name) {
          this.currentLocation = data.display_name;
        } else {
          console.error("No address found");
        }

        let userEmail =
          await this.UserAuthenticationRepositoryInstance.getUserAuthenticationByUid(
            {
              uId: this.userData.uId,
            }
          );
        const userEmailArray = userEmail.map(item => item.email);

        let userFullName = await this.userRepositoryInstance.getFullNameOfUser({
          uId: this.userData.uId,
        });

        let loginFirebaseResponse;
        loginFirebaseResponse = await auth.getEmailBodyFromFirebase(0);

        // const loginEmail = await this.sendEmailAlertInstance.loginWithEmailAlert({
        //   // username: this.username.toLowerCase().trim(),
        //   currentDate: new Date().toLocaleDateString(),
        //   currentTime: new Date().toLocaleTimeString(),
        //   currentBrowser: navigator.userAgent,
        //   currentLocation: this.currentLocation,
        //   firebaseData: loginFirebaseResponse,
        //   userEmail: userEmailArray,
        //   userFullName: userFullName,
        //   instituteId: this.selectedInstitute,
        // });
      } catch (error) {
        console.log(error);
      }
    },
    gotoMyProfile() {
      this.$router.push("/activityUser");
    },
    logout() {
      this.logoutDialog = false;
      this.$store.commit("user/reset");
      this.$store.commit("instituteData/reset");
      const user = window.localStorage.getItem("userKey");
      if (user) {
        window.localStorage.removeItem("userKey");
        this.$router.push("/activityWelcome");
      } else {
        auth.logout(this);
      }
    },
    startCreatingInstitute() {
      this.$router.push("/activityCreateInstitute");
    },
  },
};
</script>
<style scoped src="./activitySelectInstituteNSemester.css"></style>
